export default [
    {
        id: 1,
        name: 'BMW F46 216D GRAN TOURER 2015 AC CLIMATE CONTROL PANEL SWITCH 9371459',
        price: 512,
        compareAtPrice: null,
        images: ['images/products/p1/1.jpg', 'images/products/p1/2.jpg', 'images/products/p1/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9371459' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F46' },
            { name: 'Specification', value: '216D GRAN TOURER' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '136938' },
            { name: 'Other Part Number', value: '20020110' },
            { name: 'Type', value: 'Switch' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 2,
        name: 'BMW E60 LCI 523I XL 2007 AUTOMATIC GEAR SELECTOR SHIFTER KNOB 9159751',
        price: 50,
        compareAtPrice: null,
        images: ['images/products/p2/1.jpg', 'images/products/p2/2.jpg', 'images/products/p2/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9159751' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60 LCI' },
            { name: 'Specification', value: '523I XL' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '115118' },
            { name: 'Other Part Number', value: '033810505' },
            { name: 'Type', value: 'Knob' },
            { name: 'Transmission Type', value: 'Automatic' },
        ],
        options: [],
    },
    {
        id: 3,
        name: 'JAGUAR XJ 2011 INTERIOR CENTER DASHBOARD CLOCK AW93-1500-AF 4031600',
        price: 161,
        compareAtPrice: null,
        images: ['images/products/p3/1.jpg', 'images/products/p3/2.jpg', 'images/products/p3/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'AW93-1500-AF' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Other Part Number', value: '4031600' },
            { name: 'SKU', value: '121464' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Placement on Vehicle', value: 'Center' },
            { name: 'Type', value: 'Clock' },
        ],
        options: [],
    },
    {
        id: 4,
        name: 'PORSCHE CARRERA 911 996 1999 INSTRUMENT CLUSTER SPEEDOMETER LHD',
        price: 633,
        compareAtPrice: null,
        images: ['images/products/p4/1.jpg', 'images/products/p4/2.jpg', 'images/products/p4/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'CARRERA 911 996' },
            { name: 'SKU', value: '109676' },
            { name: 'Year', value: '1999' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Speedometer' },
        ],
        options: [],
    },
    {
        id: 5,
        name: 'MERCEDES BENZ W201 2006 BOSCH ENGINE ECU CONTROL UNIT 0105455632 0280800388',
        price: 115,
        compareAtPrice: null,
        images: ['images/products/p5/1.jpg', 'images/products/p5/2.jpg', 'images/products/p5/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '0105455632' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W201' },
            { name: 'Year', value: '2006' },
            { name: 'Bosch Part number', value: '0280800388' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Surface Finish', value: 'Metal' },
            { name: 'Type', value: 'ECU' },
        ],
        options: [],
    },
    {
        id: 6,
        name: 'MERCEDES BENZ W203 C180 DASH CENTER AC LOUVER VENT BLACK A2038302254',
        price: 75,
        compareAtPrice: null,
        images: ['images/products/p6/1.jpg', 'images/products/p6/2.jpg', 'images/products/p6/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2038302254' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W203' },
            { name: 'Specification', value: 'C180' },
            { name: 'SKU', value: '153971' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'AC Louver Vent' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 7,
        name: 'MERCEDES BENZ W221 S350 2007 REAR CENTER CONSOLE AIR VENT SET 2218301054',
        price: 128,
        compareAtPrice: null,
        images: ['images/products/p7/1.jpg', 'images/products/p7/2.jpg', 'images/products/p7/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '2218301054' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W221' },
            { name: 'Specification', value: 'S350' },
            { name: 'Year', value: '2007' },
            { name: 'Other Part Number', value: '2216801639' },
            { name: 'SKU', value: '109023' },
            { name: 'Placement on Vehicle', value: 'Rear Center' },
            { name: 'Type', value: 'Console Air Vent' },
        ],
        options: [],
    },
    {
        id: 8,
        name: 'PORSCHE 911 2007 BOSE AUDIO RADIO AMP AMPLIFIER 99764533422 434011',
        price: 296,
        compareAtPrice: null,
        images: ['images/products/p8/1.jpg', 'images/products/p8/2.jpg', 'images/products/p8/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '99764533422' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911' },
            { name: 'Year', value: '2007' },
            { name: 'Other Part Number', value: '434011' },
            { name: 'SKU', value: '123188' },
            { name: 'Surface Finish', value: 'Metal' },
            { name: 'Country of Manufacture', value: 'United States' },
            { name: 'Type', value: 'Amplifier' },
        ],
        options: [],
    },
    {
        id: 9,
        name: 'PORSCHE 911 1999 EXTERIOR DOOR HANDLE FRONT RIGHT 996537064',
        price: 43,
        compareAtPrice: null,
        images: ['images/products/p9/1.jpg', 'images/products/p9/2.jpg', 'images/products/p9/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '996537064' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911' },
            { name: 'Year', value: '1999' },
            { name: 'Placement on Vehicle', value: 'Front Right' },
            { name: 'Type', value: 'Door Handle' },
        ],
        options: [],
    },
    {
        id: 10,
        name: 'BMW 1 2 3 4 SERIES FRONT RIGHT FOG LAMP DRIVING LIGHT 7248912',
        price: 109,
        compareAtPrice: null,
        images: ['images/products/p10/1.jpg', 'images/products/p10/2.jpg', 'images/products/p10/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7248912' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: '1 2 3 4 SERIES' },
            { name: 'Placement on Vehicle', value: 'Front Right' },
            { name: 'Type', value: 'Fog Lamp' },
        ],
        options: [],
    },
    {
        id: 11,
        name: 'MERCEDES BENZ W222 S320 2017 FRONT BUMPER',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p11/1.jpg', 'images/products/p11/2.jpg', 'images/products/p11/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2228800147' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2228800247' },
            { name: 'Type', value: 'Front Bumper' },
        ],
        options: [],
    },
    {
        id: 12,
        name: 'MERCEDES BENZ W222 S320 2017 REAR BUMPER',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p12/1.jpg', 'images/products/p12/2.jpg', 'images/products/p12/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2228800040' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2228800049' },
            { name: 'Type', value: 'Rear Bumper' },
        ],
        options: [],
    },
    {
        id: 13,
        name: 'MERCEDES BENZ W222 S320 2017 BOOT LID',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p13/1.jpg', 'images/products/p13/2.jpg', 'images/products/p13/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Boot Lid' },
        ],
        options: [],
    },
    {
        id: 14,
        name: 'MERCEDES BENZ W222 S320 2017 LED HEADLAMP LEFT & RIGHT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p14/1.jpg', 'images/products/p14/2.jpg', 'images/products/p14/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2229060802' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2229060702' },
            { name: 'Type', value: 'Headlamp' },
        ],
        options: [],
    },
    {
        id: 15,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 LED TAIL LAMP RIGHT & LEFT',
        price: 680,
        compareAtPrice: null,
        images: ['images/products/p15/1.jpg', 'images/products/p15/2.jpg', 'images/products/p15/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2518202064' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Other Part Number', value: 'A2518201964' },
            { name: 'Type', value: 'Tail Lamp' },
        ],
        options: [],
    },
    {
        id: 16,
        name: 'MERCEDES BENZ W172 SLK200 SIDE MIRRORS RIGHT & LEFT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p16/1.jpg', 'images/products/p16/2.jpg', 'images/products/p16/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Side Mirror' },
        ],
        options: [],
    },
    {
        id: 17,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 FENDERS RIGHT & LEFT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p17/1.jpg', 'images/products/p17/2.jpg', 'images/products/p17/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Fender' },
        ],
        options: [],
    },
    {
        id: 18,
        name: 'MERCEDES BENZ W172 SLK200 BONNET RED',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p18/1.jpg', 'images/products/p18/2.jpg', 'images/products/p18/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Bonnet' },
        ],
        options: [],
    },
    {
        id: 19,
        name: 'MERCEDES BENZ W172 SLK200 SIDE SKIRTS RIGHT & LEFT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p19/1.jpg', 'images/products/p19/2.jpg', 'images/products/p19/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Side Skirt' },
        ],
        options: [],
    },
    {
        id: 20,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 REAR LEFT DOOR',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p20/1.jpg', 'images/products/p20/2.jpg', 'images/products/p20/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Door' },
        ],
        options: [],
    },
    {
        id: 21,
        name: 'PORSCHE MACAN S 3.0L V6 2015 LONG BLOCK MOTOR ENGINE CTM025020 FREE SHIPPING',
        price: 14998,
        compareAtPrice: null,
        images: ['images/products/p21/1.jpg', 'images/products/p21/2.jpg', 'images/products/p21/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'CTM025020' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'MACAN S' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2015' },
            { name: 'Fuel Type', value: 'Gasoline' },
            { name: 'SKU', value: '154162' },
            { name: 'Performance Type', value: 'Turbocharged' },
            { name: 'Type', value: 'Complete Engine' },
        ],
        options: [],
    },
    {
        id: 22,
        name: 'MERCEDES BENZ W222 S400 2017 STAGGERED RIMS WITH TYRES (19)',
        price: 1800,
        compareAtPrice: null,
        images: ['images/products/p22/1.jpg', 'images/products/p22/2.jpg', 'images/products/p22/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'WheelS With Tyre' },
        ],
        options: [],
    },
    {
        id: 23,
        name: 'MERCEDES BENZ W222 S400 GEARBOX 722904 & TORQUE A2312500802',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p23/1.jpg', 'images/products/p23/2.jpg', 'images/products/p23/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2312500802' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Other Part Number', value: '722904' },
            { name: 'Type', value: 'Gearbox Torque' },
        ],
        options: [],
    },
    {
        id: 24,
        name: 'MERCEDES BENZ W222 S400 2016 AIR ABSORBER SUSPENSION FRONT & REAR',
        price: 1391,
        compareAtPrice: null,
        images: ['images/products/p24/1.jpg', 'images/products/p24/2.jpg', 'images/products/p24/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7248912' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Absorber' },
        ],
        options: [],
    },
    {
        id: 25,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 PARK BRAKE ACUTATOR',
        price: 220,
        compareAtPrice: null,
        images: ['images/products/p25/1.jpg', 'images/products/p25/2.jpg', 'images/products/p25/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Park Brake Acutator' },
        ],
        options: [],
    },
    {
        id: 26,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 STEERING RACK LHD',
        price: 2200,
        compareAtPrice: null,
        images: ['images/products/p26/1.jpg', 'images/products/p26/2.jpg', 'images/products/p26/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '6820000221' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Other Part Number', value: '6700003475A' },
            { name: 'Type', value: 'Steering Rack' },
        ],
        options: [],
    },
    {
        id: 27,
        name: 'JAGUAR XJ 3.0L D 6DT 2010 AIRMATIC SUSPENSION COMPRESSOR PUMP AW933B484AF',
        price: 270,
        compareAtPrice: null,
        images: ['images/products/p27/1.jpg', 'images/products/p27/2.jpg', 'images/products/p27/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'AW933B484AF' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '151181' },
            { name: 'Engine', value: '06DT' },
            { name: 'Type', value: 'Airmatic Pump' },
        ],
        options: [],
    },
    {
        id: 28,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 ROOF GLASS',
        price: 680,
        compareAtPrice: null,
        images: ['images/products/p28/1.jpg', 'images/products/p28/2.jpg', 'images/products/p28/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Roof Glass' },
        ],
        options: [],
    },
    {
        id: 29,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 CROSS MEMBER',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p29/1.jpg', 'images/products/p29/2.jpg', 'images/products/p29/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Cross Member' },
        ],
        options: [],
    },
    {
        id: 30,
        name: 'MERCEDES BENZ W222 S400 REAR AXLE WITH DIFFERENTIAL',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p30/1.jpg', 'images/products/p30/2.jpg', 'images/products/p30/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Type', value: 'Rear Axle' },
        ],
        options: [],
    },
    {
        id: 31,
        name: 'AUDI A4 2010 FRONT RIGHT LOWER FORWARD CONTROL ARM 8K0407156B',
        price: 27,
        compareAtPrice: null,
        images: ['images/products/p31/1.jpg', 'images/products/p31/2.jpg', 'images/products/p31/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8K0407156B' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A4' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '122546' },
            { name: 'Type', value: 'Control ARM' },
            { name: 'Placement on Vehicle', value: 'Right Front' },
        ],
        options: [],
    },
    {
        id: 32,
        name: 'AUDI A6 2.0 2007 05-08 BATTERY CONTROL UNIT MODULE 4F0915181A 4F0910181E',
        price: 90,
        compareAtPrice: null,
        images: ['images/products/p32/1.jpg', 'images/products/p32/2.jpg', 'images/products/p32/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '4F0915181A' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A6' },
            { name: 'Specification', value: '2.0' },
            { name: 'Year', value: '2007' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Other Part Number', value: '4F0910181E' },
            { name: 'Country of Manufacture', value: 'France' },
            { name: 'Type', value: 'Module' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 33,
        name: 'VOLKSWAGEN PASSAT 1.8TSI 2011 SPARE KEY FOB',
        price: 115,
        compareAtPrice: null,
        images: ['images/products/p33/1.jpg', 'images/products/p33/2.jpg', 'images/products/p33/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Volkswagen' },
            { name: 'Model', value: 'PASSAT' },
            { name: 'Specification', value: '1.8TSI' },
            { name: 'Stock Number', value: '06488' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Spare Key' },
        ],
        options: [],
    },
    {
        id: 34,
        name: 'BENTLEY CONTINENTAL FLYING SPUR 2008 OVERHEAD ROOF LAMP FRONT 3W0907135F',
        price: 348,
        compareAtPrice: null,
        images: ['images/products/p34/1.jpg', 'images/products/p34/2.jpg', 'images/products/p34/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '3W0907135F' },
            { name: 'Brand', value: 'Bentley' },
            { name: 'Model', value: 'CONTINENTAL FLYING SPUR' },
            { name: 'SKU', value: '145705' },
            { name: 'Year', value: '2008' },
            { name: 'Type', value: 'Roof Lamp' },
            { name: 'Other Part Number', value: '364341' },
            { name: 'Country of Manufacture', value: 'Australia' },
            { name: 'Placement on Vehicle', value: 'Front Overhead' },
        ],
        options: [],
    },
    {
        id: 35,
        name: 'MINI COOPER D F56 2014 INTERIOR AUTO DIM REAR VIEW CENTER MIRROR 9285373',
        price: 173,
        compareAtPrice: null,
        images: ['images/products/p35/1.jpg', 'images/products/p35/2.jpg', 'images/products/p35/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9285373' },
            { name: 'Brand', value: 'Mini Cooper' },
            { name: 'Model', value: 'F56' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '145501' },
            { name: 'Placement on Vehicle', value: 'Front Upper' },
            { name: 'Type', value: 'Center Mirror' },
            { name: 'Features', value: 'Auto Dimming' },
        ],
        options: [],
    },
    {
        id: 36,
        name: 'BMW F12 640i 2011 STEERING ANGLE SENSOR WITH INDICATOR SWITCH 9245623',
        price: 170,
        compareAtPrice: null,
        images: ['images/products/p36/1.jpg', 'images/products/p36/2.jpg', 'images/products/p36/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9245623' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F12' },
            { name: 'Specification', value: '640i' },
            { name: 'Year', value: '2011' },
            { name: 'SKU', value: '140943' },
            { name: 'Type', value: 'Steering Angle Sensor' },
        ],
        options: [],
    },
    {
        id: 37,
        name: 'AUDI A4 2.0TFSI 2016 DASH MMI NAVIGATION TV DISPLAY 8W2919605',
        price: 348,
        compareAtPrice: null,
        images: ['images/products/p37/1.jpg', 'images/products/p37/2.jpg', 'images/products/p37/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8W2919605' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A4' },
            { name: 'Specification', value: '2.0TFSI' },
            { name: 'Year', value: '2016' },
            { name: 'Country of Manufacture', value: 'China' },
            { name: 'SKU', value: '150832' },
            { name: 'Type', value: 'TV Display' },
        ],
        options: [],
    },
    {
        id: 38,
        name: 'AUDI A8 3.0 TFSI 2011 ELECTRONIC ACCELERATOR PEDAL RHD 6PV009505',
        price: 28,
        compareAtPrice: null,
        images: ['images/products/p38/1.jpg', 'images/products/p38/2.jpg', 'images/products/p38/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '6PV009505' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A8' },
            { name: 'Specification', value: '3.0 TFSI' },
            { name: 'Year', value: '2011' },
            { name: 'SKU', value: '141485' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Accelerator Pedal' },
        ],
        options: [],
    },
    {
        id: 39,
        name: 'VOLVO S60 2011 FRONT BUMPER CAMERA 31339995',
        price: 230,
        compareAtPrice: null,
        images: ['images/products/p39/1.jpg', 'images/products/p39/2.jpg', 'images/products/p39/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '31339995' },
            { name: 'Brand', value: 'Volvo' },
            { name: 'Model', value: 'S60' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Bumper Camera' },
        ],
        options: [],
    },
    {
        id: 40,
        name: 'BENTLEY CONTINENTAL FLYING SPUR 2008 SUN VISOR BLACK ONE PAIR',
        price: 528,
        compareAtPrice: null,
        images: ['images/products/p40/1.jpg', 'images/products/p40/2.jpg', 'images/products/p40/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Bentley' },
            { name: 'Model', value: 'CONTINENTAL FLYING SPUR' },
            { name: 'Year', value: '2008' },
            { name: 'SKU', value: '145707' },
            { name: 'Placement on Vehicle', value: 'Left, Right, Front' },
            { name: 'Type', value: 'Sun Visor' },
        ],
        options: [],
    },
    {
        id: 41,
        name: 'BMW E46 M3 3 SERIES CD CHANGER MAGAZINE UNIT 8364931',
        price: 17,
        compareAtPrice: null,
        images: ['images/products/p41/1.jpg', 'images/products/p41/2.jpg', 'images/products/p41/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8364931' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E46' },
            { name: 'Specification', value: '3 SERIES' },
            { name: 'SKU', value: '119597' },
            { name: 'Material', value: 'Plastic' },
            { name: 'Type', value: 'CD Changer' },
        ],
        options: [],
    },
    {
        id: 42,
        name: 'BMW E90 N46 2005 ENGINE THROTTLE BODY HOUSING ASSY 1439580',
        price: 60,
        compareAtPrice: null,
        images: ['images/products/p42/1.jpg', 'images/products/p42/2.jpg', 'images/products/p42/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '1439580' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90' },
            { name: 'Year', value: '2005' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'SKU', value: '112194' },
            { name: 'Type', value: 'Engine Throttle Body' },
        ],
        options: [],
    },
    {
        id: 43,
        name: 'BMW 7 SERIES E65 E66 E67 2006 DISTRIBUTION TRUNK FUSE BOX 6900583',
        price: 57,
        compareAtPrice: null,
        images: ['images/products/p43/1.jpg', 'images/products/p43/2.jpg', 'images/products/p43/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '6900583' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E66' },
            { name: 'Specification', value: '7 SERIES' },
            { name: 'Year', value: '2006' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Fuse Box' },
        ],
        options: [],
    },
    {
        id: 44,
        name: 'BMW E93 REAR THIRD BRAKE STOP LIGHT LED 7162309',
        price: 78,
        compareAtPrice: null,
        images: ['images/products/p44/1.jpg', 'images/products/p44/2.jpg', 'images/products/p44/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7162309' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E93' },
            { name: 'SKU', value: '149339' },
            { name: 'Placement on Vehicle', value: 'Rear' },
            { name: 'Type', value: 'Brake Stop Light' },
        ],
        options: [],
    },
    {
        id: 45,
        name: 'MERCEDES BENZ W212 BONNET GAS SPRING STRUT A2048800029 / A2129800064',
        price: 78,
        compareAtPrice: null,
        images: ['images/products/p45/1.jpg', 'images/products/p45/2.jpg', 'images/products/p45/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2129800064' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W212' },
            { name: 'SKU', value: '133703' },
            { name: 'Other Part Number', value: 'A2048800029' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Gas Spring Strut' },
        ],
        options: [],
    },
    {
        id: 46,
        name: 'BMW BOOT BADGE EMBLEM PAIR 8219237',
        price: 20,
        compareAtPrice: null,
        images: ['images/products/p46/1.jpg', 'images/products/p46/2.jpg', 'images/products/p46/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8219237' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90, E46, F30, F31, F35' },
            { name: 'Placement on Vehicle', value: 'Rear' },
            { name: 'Type', value: 'Badge Emblem' },
        ],
        options: [],
    },
    {
        id: 47,
        name: 'BMW E60 5 SERIES 2006 BLACK CUP HOLDER',
        price: 18,
        compareAtPrice: null,
        images: ['images/products/p47/1.jpg', 'images/products/p47/2.jpg', 'images/products/p47/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60' },
            { name: 'Specification', value: '5 SERIES' },
            { name: 'Year', value: '2006' },
            { name: 'SKU', value: '131761' },
            { name: 'Type', value: 'Cup Holder' },
        ],
        options: [],
    },
    {
        id: 48,
        name: 'MERCEDES BENZ W204 W203 2007 STARTER MOTOR BOSCH 0001107540',
        price: 129,
        compareAtPrice: null,
        images: ['images/products/p48/1.jpg', 'images/products/p48/2.jpg', 'images/products/p48/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Bosch Number', value: '0001107540' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W204' },
            { name: 'Year', value: '2007' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Starter Motor' },
        ],
        options: [],
    },
    {
        id: 49,
        name: 'BMW E90 320I DOOR LOCK FRONT RIGHT 7059968',
        price: 50,
        compareAtPrice: null,
        images: ['images/products/p49/1.jpg', 'images/products/p49/2.jpg', 'images/products/p49/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7059968' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90' },
            { name: 'Specification', value: '320i' },
            { name: 'Placement on Vehicle', value: 'Right Front' },
            { name: 'Type', value: 'Door Lock' },
        ],
        options: [],
    },
    {
        id: 50,
        name: 'AUDI A4 1.8T 2015 FRONT BUMPER RADIATOR GRILLE 8W0853651',
        price: 86,
        compareAtPrice: null,
        images: ['images/products/p50/1.jpg', 'images/products/p50/2.jpg', 'images/products/p50/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8W0853651' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A4' },
            { name: 'Specification', value: '1.8T' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '122577' },
            { name: 'Placement on Vehicle', value: 'Front' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Grille' },
        ],
        options: [],
    },
    {
        id: 51,
        name: 'MERCEDES BENZ W204 C180 2005 SIDE MIRROR GLASS FRONT LEFT A3146453',
        price: 38,
        compareAtPrice: null,
        images: ['images/products/p51/1.jpg', 'images/products/p51/2.jpg', 'images/products/p51/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A3146453' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W204' },
            { name: 'Specification', value: 'C180' },
            { name: 'Year', value: '2005' },
            { name: 'SKU', value: '140324' },
            { name: 'Placement on Vehicle', value: 'Left Front' },
            { name: 'Type', value: 'Mirror Glass' },
        ],
        options: [],
    },
    {
        id: 52,
        name: 'MINI COOPER S R52 2006 POWER STEERING AUTO FAN',
        price: 68,
        compareAtPrice: null,
        images: ['images/products/p52/1.jpg', 'images/products/p52/2.jpg', 'images/products/p52/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mini Cooper' },
            { name: 'Model', value: 'R52' },
            { name: 'Year', value: '2006' },
            { name: 'SKU', value: '113268' },
            { name: 'Type', value: 'Steering Fan' },
        ],
        options: [],
    },
    {
        id: 53,
        name: 'BMW 3 SERIES E36 1997 BOOT LOCK MOTOR/ACTUATOR 8361140',
        price: 20,
        compareAtPrice: null,
        images: ['images/products/p53/1.jpg', 'images/products/p53/2.jpg', 'images/products/p53/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8361140' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E36' },
            { name: 'Specification', value: '316i' },
            { name: 'Year', value: '1997' },
            { name: 'SKU', value: '127292' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Boot Lock Motror' },
        ],
        options: [],
    },
    {
        id: 54,
        name: 'BMW E60 2005 GLOVE BOX FLASH TORCHLIGHT 6962052',
        price: 24,
        compareAtPrice: null,
        images: ['images/products/p54/1.jpg', 'images/products/p54/2.jpg', 'images/products/p54/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '6962052' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60' },
            { name: 'Year', value: '2005' },
            { name: 'SKU', value: '139104' },
            { name: 'Type', value: 'Torchlight' },
        ],
        options: [],
    },
    {
        id: 55,
        name: 'BMW F02 740Li 2010 M PACKAGE LIGHTED DOOR ENTRANCE SILL SET 7181011',
        price: 163,
        compareAtPrice: null,
        images: ['images/products/p55/1.jpg', 'images/products/p55/2.jpg', 'images/products/p55/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7181011' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F02' },
            { name: 'Specification', value: '740Li' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '139105' },
            { name: 'Type', value: 'Sill Set' },
        ],
        options: [],
    },
    {
        id: 56,
        name: 'MERCEDES BENZ C215 CL55 WIPER ARM FRONT A2158202244',
        price: 22,
        compareAtPrice: null,
        images: ['images/products/p56/1.jpg', 'images/products/p56/2.jpg', 'images/products/p56/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2158202244' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'C215' },
            { name: 'SKU', value: '136883' },
            { name: 'Type', value: 'Wiper Arm' },
        ],
        options: [],
    },
    {
        id: 57,
        name: 'MERCEDES BENZ W211 E200 2007 WOODEN CONSOLE GEAR SWITCH TRIM A2115420426 13569820',
        price: 28,
        compareAtPrice: null,
        images: ['images/products/p57/1.jpg', 'images/products/p57/2.jpg', 'images/products/p57/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2115420426' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W211' },
            { name: 'Specification', value: 'E200' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '119448' },
            { name: 'Other Part Number', value: '13569820' },
            { name: 'Material', value: 'Wood' },
            { name: 'Color', value: 'Brown' },
            { name: 'Type', value: 'Wooden Trim' },
        ],
        options: [],
    },
    {
        id: 58,
        name: 'BMW F02 740Li N52N 2010 BRAKE BOOSTER MASTER CYLINDER 6782289',
        price: 118,
        compareAtPrice: null,
        images: ['images/products/p58/1.jpg', 'images/products/p58/2.jpg', 'images/products/p58/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '6782289' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F02' },
            { name: 'Specification', value: '740Li' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '151361' },
            { name: 'Type', value: 'Brake Booster Cylinder' },
        ],
        options: [],
    },
    {
        id: 59,
        name: 'BMW E60 E66 2006 BOSCH IGNITION COIL 6-PCS 7551049 / 7594936',
        price: 103,
        compareAtPrice: null,
        images: ['images/products/p59/1.jpg', 'images/products/p59/2.jpg', 'images/products/p59/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7551049' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60' },
            { name: 'Year', value: '2006' },
            { name: 'SKU', value: '115296' },
            { name: 'Other Part Number', value: '7594936' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Ignition Coil' },
        ],
        options: [],
    },
    {
        id: 60,
        name: 'MERCEDES BENZ W463 G63AMG 2017 BRAKE CALIPERS WITH DISC COMPLETE SET',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p60/1.jpg', 'images/products/p60/2.jpg', 'images/products/p60/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W463' },
            { name: 'Specification', value: 'G63AMG' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Brake Calipers with Disc' },
        ],
        options: [],
    },
    {
        id: 61,
        name: 'VOLKSWAGEN GOLF 7 2014 NAVIGATION DISPLAY RADIO UNIT 5G0919606',
        price: 538,
        compareAtPrice: 751.59,
        images: ['images/products/p61/1.jpg', 'images/products/p61/2.jpg', 'images/products/p61/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '5G0919606' },
            { name: 'Brand', value: 'Volkswagen' },
            { name: 'Model', value: 'GOLF 7' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '150788' },
            { name: 'Country of Manufacture', value: 'China' },
            { name: 'Type', value: 'Radio' },
        ],
        options: [],
    },
    {
        id: 62,
        name: 'BMW F02 740Li 2010 REAR SEAT ENTERTAINMENT DVD AUDIO PLAYER 9220871',
        price: 858,
        compareAtPrice: 1198.63,
        images: ['images/products/p62/1.jpg', 'images/products/p62/2.jpg', 'images/products/p62/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9220871' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F02' },
            { name: 'Specification', value: '740Li' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '142854' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'DVD Audio Player' },
        ],
        options: [],
    },
    {
        id: 63,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 TAIL LAMP RIGHT & LEFT',
        price: 401.25,
        compareAtPrice: 535,
        images: ['images/products/p63/1.jpg', 'images/products/p63/2.jpg', 'images/products/p63/3.jpg', 'images/products/p63/4.jpg', 'images/products/p63/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Tail Lamp Right & Left' },
        ],
        options: [],
    },
    {
        id: 64,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 XENON HID HEADLAMPS RIGHT & LEFT',
        price: 1875,
        compareAtPrice: 2500,
        images: ['images/products/p64/1.jpg', 'images/products/p64/2.jpg', 'images/products/p64/3.jpg', 'images/products/p64/4.jpg', 'images/products/p64/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2518206861 / A2518206761' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Type', value: 'Headlamp Right & Left' },
        ],
        options: [],
    },
    {
        id: 65,
        name: 'MERCEDES BENZ W204 C CLASS 2007 FRONT INTERIOR ROOF LAMP LIGHT A2048202001',
        price: 70,
        compareAtPrice: 97.79,
        images: ['images/products/p65/1.jpg', 'images/products/p65/2.jpg', 'images/products/p65/3.jpg', 'images/products/p65/4.jpg', 'images/products/p65/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2048202001' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W204' },
            { name: 'Specification', value: 'C CLASS' },
            { name: 'Year', value: '2007' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Roof Lamp' },
        ],
        options: [],
    },
    {
        id: 66,
        name: 'BMW E66 730LI N52 2006 ABS DSC COMPRESSOR PUMP 34511166155 / 0265410054',
        price: 30,
        compareAtPrice: 41.91,
        images: ['images/products/p66/1.jpg', 'images/products/p66/2.jpg', 'images/products/p66/3.jpg', 'images/products/p66/4.jpg', 'images/products/p66/5.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '34511166155' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E66' },
            { name: 'Specification', value: '730LI' },
            { name: 'Year', value: '2006' },
            { name: 'Other Part Number', value: '0265410054' },
            { name: 'SKU', value: '114498' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'ABS DSC Compressor Pump' },
        ],
        options: [],
    },
    {
        id: 67,
        name: 'JAGUAR XJ 3.0L 2011 GLOW PLUG RELAY CONTROL MODULE 0281003048 9X2Q12B533BB',
        price: 27,
        compareAtPrice: 37.72,
        images: ['images/products/p67/1.jpg', 'images/products/p67/2.jpg', 'images/products/p67/3.jpg', 'images/products/p67/4.jpg', 'images/products/p67/5.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9X2Q12B533BB' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '0281003048' },
            { name: 'SKU', value: '144586' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Glow Plug' },
        ],
        options: [],
    },
    {
        id: 68,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 STEERING WHEEL WITH AIRBAG',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p68/1.jpg', 'images/products/p68/2.jpg', 'images/products/p68/3.jpg', 'images/products/p68/4.jpg', 'images/products/p68/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Steering Wheel' },
        ],
        options: [],
    },
    {
        id: 69,
        name: 'JAGUAR X TYPE 2.0 SE 2004 HEADLIGHT SWITCH CONTROL UNIT 4X4311654AA',
        price: 30,
        compareAtPrice: 41.91,
        images: ['images/products/p69/1.jpg', 'images/products/p69/2.jpg', 'images/products/p69/3.jpg', 'images/products/p69/4.jpg', 'images/products/p69/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '4X4311654AA' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'X TYPE' },
            { name: 'Specification', value: '2.0 SE' },
            { name: 'Year', value: '2004' },
            { name: 'SKU', value: '105074' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Headlight Switch' },
        ],
        options: [],
    },
    {
        id: 70,
        name: 'BMW F10 5 SERIES 2012 FRESH AIR GRILLE DASHBOARD AC VENT LEFT 9166887',
        price: 36,
        compareAtPrice: 50.29,
        images: ['images/products/p70/1.jpg', 'images/products/p70/2.jpg', 'images/products/p70/3.jpg', 'images/products/p70/4.jpg', 'images/products/p70/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9166887' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F10' },
            { name: 'Specification', value: '520i' },
            { name: 'Year', value: '2012' },
            { name: 'Other Part Number', value: '9166893 / 07615001' },
            { name: 'SKU', value: '162480' },
            { name: 'Country of Manufacture', value: 'Poland' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Dashboard AC Vent Left' },
            { name: 'Placement on Vehicle', value: 'Left' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 71,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 INSTRUMENT CLUSTER SPEEDOMETER 268077',
        price: 1976,
        compareAtPrice: 2758.99,
        images: ['images/products/p71/1.jpg', 'images/products/p71/2.jpg', 'images/products/p71/3.jpg', 'images/products/p71/4.jpg', 'images/products/p71/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '268077' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Display Type', value: 'Analogue & Digital' },
            { name: 'Type', value: 'Speedometer' },
        ],
        options: [],
    },
    {
        id: 72,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 GAS ACCELERATOR PEDAL',
        price: 174,
        compareAtPrice: 242.95,
        images: ['images/products/p72/1.jpg', 'images/products/p72/2.jpg', 'images/products/p72/3.jpg', 'images/products/p72/4.jpg', 'images/products/p72/5.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Accelerator' },
        ],
        options: [],
    },
    {
        id: 73,
        name: 'JAGUAR XF DOOR HANDLE CHROME FRONT RIGHT 8X2322600AE',
        price: 64,
        compareAtPrice: 89.36,
        images: ['images/products/p73/1.jpg', 'images/products/p73/2.jpg', 'images/products/p73/3.jpg', 'images/products/p73/4.jpg', 'images/products/p73/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8X2322600AE' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XF' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Door Handle Front Right' },
            { name: 'Placement on Vehicle', value: 'Front, Right Side' },
        ],
        options: [],
    },
    {
        id: 74,
        name: 'AUDI A8 3.0 TFSI 2011 INTERIOR CENTER REAR VIEW MIRROR 8R0857511B',
        price: 88,
        compareAtPrice: 122.87,
        images: ['images/products/p74/1.jpg', 'images/products/p74/2.jpg', 'images/products/p74/3.jpg', 'images/products/p74/4.jpg', 'images/products/p74/5.jpg', 'images/products/p74/6.jpg', 'images/products/p74/7.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '8R0857511B' },
            { name: 'Brand', value: 'AUDI' },
            { name: 'Model', value: 'A8' },
            { name: 'Specification', value: '3.0 TFSI' },
            { name: 'Year', value: '2011' },
            { name: 'SKU', value: '141484' },
            { name: 'Type', value: 'Center Rear View Mirror' },
        ],
        options: [],
    },
    {
        id: 75,
        name: 'VOLVO V40 D2 2014 GEAR KNOB',
        price: 120,
        compareAtPrice: 167.55,
        images: ['images/products/p75/1.jpg', 'images/products/p75/2.jpg', 'images/products/p75/3.jpg', 'images/products/p75/4.jpg', 'images/products/p75/5.jpg', 'images/products/p75/6.jpg', 'images/products/p75/7.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Volvo' },
            { name: 'Model', value: 'V40' },
            { name: 'Specification', value: 'D2' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '145838' },
            { name: 'Type', value: 'Gear Knob' },
        ],
        options: [],
    },
    {
        id: 76,
        name: 'MINI COOPER D F56 2014 SUN VISOR PAIR RIGHT & LEFT 7366950 / 7382101',
        price: 278,
        compareAtPrice: 388.16,
        images: ['images/products/p76/1.jpg', 'images/products/p76/2.jpg', 'images/products/p76/3.jpg', 'images/products/p76/4.jpg', 'images/products/p76/5.jpg', 'images/products/p76/6.jpg', 'images/products/p76/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7366950 / 7382101' },
            { name: 'Brand', value: 'Mini Cooper' },
            { name: 'Model', value: 'F56' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '145669' },
            { name: 'Type', value: 'Sun Visor' },
            { name: 'Placement on Vehicle', value: 'Left, Right, Front' },
            { name: 'Featuers', value: 'Lights, Mirror' },
        ],
        options: [],
    },
    {
        id: 77,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 CENTER ARMREST TUNNEL CONSOLE 80581500',
        price: 4985,
        compareAtPrice: 6960.31,
        images: ['images/products/p77/1.jpg', 'images/products/p77/2.jpg', 'images/products/p77/3.jpg', 'images/products/p77/4.jpg', 'images/products/p77/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '80581500' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Center Console' },
            { name: 'Placement on Vehicle', value: 'Center' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 78,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 STEERING COLUMN COVER 69766000 82001800',
        price: 229,
        compareAtPrice: 319.74,
        images: ['images/products/p78/1.jpg', 'images/products/p78/2.jpg', 'images/products/p78/3.jpg', 'images/products/p78/4.jpg', 'images/products/p78/5.jpg', 'images/products/p78/6.jpg', 'images/products/p78/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '69766000' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '82001800' },
            { name: 'Type', value: 'Steering Column Cover' },
        ],
        options: [],
    },
    {
        id: 79,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 STEERING ANGLE SENSOR 261073 0265005499',
        price: 432,
        compareAtPrice: 603.18,
        images: ['images/products/p79/1.jpg', 'images/products/p79/2.jpg', 'images/products/p79/3.jpg', 'images/products/p79/4.jpg', 'images/products/p79/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '261073' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '0265005499' },
            { name: 'Country of Manufacture', value: 'Australia' },
            { name: 'Type', value: 'Steering Angle Sensor' },
        ],
        options: [],
    },
    {
        id: 80,
        name: 'BMW F10 523i N52N 2.5AT 2011 FUEL INJECTOR 7542541',
        price: 300,
        compareAtPrice: 418.88,
        images: ['images/products/p80/1.jpg', 'images/products/p80/2.jpg', 'images/products/p80/3.jpg', 'images/products/p80/4.jpg', 'images/products/p80/5.jpg', 'images/products/p80/6.jpg', 'images/products/p80/7.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7542541' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F10' },
            { name: 'Specification', value: '523i' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '7542070' },
            { name: 'SKU', value: '149699' },
            { name: 'Type', value: 'Fuel Injector' },
        ],
        options: [],
    },
    {
        id: 81,
        name: 'BMW X3 F25 XDRIVE28iX N20 2012 DENSO ALTERNATOR 7605061',
        price: 205,
        compareAtPrice: 286.23,
        images: ['images/products/p81/1.jpg', 'images/products/p81/2.jpg', 'images/products/p81/3.jpg', 'images/products/p81/4.jpg', 'images/products/p81/5.jpg', 'images/products/p81/6.jpg', 'images/products/p81/7.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7605061' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'X3' },
            { name: 'Year', value: '2012' },
            { name: 'SKU', value: '151385' },
            { name: 'Country of Manufacture', value: 'Japan' },
            { name: 'Type', value: 'Alternator' },
        ],
        options: [],
    },
    {
        id: 82,
        name: 'MERCEDES BENZ W124 E CLASS STARTER MOTOR BOSCH 0001107403',
        price: 69,
        compareAtPrice: 96.34,
        images: ['images/products/p82/1.jpg', 'images/products/p82/2.jpg', 'images/products/p82/3.jpg', 'images/products/p82/4.jpg', 'images/products/p82/5.jpg', 'images/products/p82/6.jpg', 'images/products/p82/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '0001107403' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W124' },
            { name: 'Specification', value: 'E CLASS' },
            { name: 'Year', value: '1987' },
            { name: 'Country of Manufacture', value: 'Hungary' },
            { name: 'Type', value: 'Starter Motor' },
        ],
        options: [],
    },
    {
        id: 83,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 SEATS',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p83/1.jpg', 'images/products/p83/2.jpg', 'images/products/p83/3.jpg', 'images/products/p83/4.jpg', 'images/products/p83/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Seats' },
        ],
        options: [],
    },
    {
        id: 84,
        name: 'MERCEDES BENZ W169 WIPER MOTOR REAR A1698201340',
        price: 89,
        compareAtPrice: 124.27,
        images: ['images/products/p84/1.jpg', 'images/products/p84/2.jpg', 'images/products/p84/3.jpg', 'images/products/p84/4.jpg', 'images/products/p84/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A1698201340' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W169' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '139616' },
            { name: 'Country of Manufacture', value: 'France' },
            { name: 'Type', value: 'Wiper Motor' },
        ],
        options: [],
    },
    {
        id: 85,
        name: 'BMW E90 REAR REFLECTOR RIGHT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p85/1.jpg', 'images/products/p85/2.jpg', 'images/products/p85/3.jpg', 'images/products/p85/4.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90' },
            { name: 'Year', value: '2005' },
            { name: 'SKU', value: '120257' },
            { name: 'Type', value: 'Reflector' },
            { name: 'Placement on Vehicle', value: 'Rear, Right' },
        ],
        options: [],
    },
    {
        id: 86,
        name: 'MERCEDES BENZ W203 W209 FOG LAMP PAIR 2038201256 2038201156',
        price: 38,
        compareAtPrice: 53.06,
        images: ['images/products/p86/1.jpg', 'images/products/p86/2.jpg', 'images/products/p86/3.jpg', 'images/products/p86/4.jpg', 'images/products/p86/5.jpg', 'images/products/p86/6.jpg', 'images/products/p86/7.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '2038201256 / 2038201156' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W203' },
            { name: 'SKU', value: '114259, 114258' },
            { name: 'Type', value: 'Fog Lamps' },
        ],
        options: [],
    },
    {
        id: 87,
        name: 'MERCEDES BENZ W124 W201 FUEL DISTRIBUTOR 0438101012',
        price: 480,
        compareAtPrice: 670.20,
        images: ['images/products/p87/1.jpg', 'images/products/p87/2.jpg', 'images/products/p87/3.jpg', 'images/products/p87/4.jpg', 'images/products/p87/5.jpg', 'images/products/p87/6.jpg', 'images/products/p87/7.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '0438101012' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W124' },
            { name: 'SKU', value: '111308' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Fuel Distributor' },
        ],
        options: [],
    },
    {
        id: 88,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 AC HEATER CLIMATE CONTROL PANEL 000267691',
        price: 941,
        compareAtPrice: 1313.87,
        images: ['images/products/p88/1.jpg', 'images/products/p88/2.jpg', 'images/products/p88/3.jpg', 'images/products/p88/4.jpg', 'images/products/p88/5.jpg', 'images/products/p88/6.jpg', 'images/products/p88/7.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '000267691' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Mexico' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'AC Control Panel' },
        ],
        options: [],
    },
    {
        id: 89,
        name: 'PORSCHE 911S CARRERA 997 2007 HANDBRAKE ASSEMBLY BLACK LEATHER 99742403162',
        price: 242,
        compareAtPrice: 337.14,
        images: ['images/products/p89/1.jpg', 'images/products/p89/2.jpg', 'images/products/p89/3.jpg', 'images/products/p89/4.jpg', 'images/products/p89/5.jpg', 'images/products/p89/6.jpg', 'images/products/p89/7.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '99742403162' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911S' },
            { name: 'Specification', value: 'CARRERA 997' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '146818' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Handbrake' },
        ],
        options: [],
    },
    {
        id: 90,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 20INCH WHEELS',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p90/1.jpg', 'images/products/p90/2.jpg', 'images/products/p90/3.jpg', 'images/products/p90/4.jpg', 'images/products/p90/5.jpg', 'images/products/p90/6.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '10JX20EH2' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: '20Inch Wheels' },
        ],
        options: [],
    },
    {
        id: 91,
        name: 'PORSCHE MACAN S 3.0 2015 AUDIO RADIO MUSIC AMP AMPLIFIER 95B035223',
        price: 305,
        compareAtPrice: 424.91,
        images: ['images/products/p91/1.jpg', 'images/products/p91/2.jpg', 'images/products/p91/3.jpg', 'images/products/p91/4.jpg', 'images/products/p91/5.jpg', 'images/products/p91/6.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '95B035223' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'MACAN S' },
            { name: 'Specification', value: '3.0' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '164796' },
            { name: 'Country of Manufacture', value: 'Romania' },
            { name: 'Type', value: 'Amplifier' },
            { name: 'Features', value: 'Radio' },
        ],
        options: [],
    },
    {
        id: 92,
        name: 'BMW E66 730LI N62 2004 FUSE BOX SAM INTEGRATED POWER SUPPLY MODULE 7510638',
        price: 29,
        compareAtPrice: 40.40,
        images: ['images/products/p92/1.jpg', 'images/products/p92/2.jpg', 'images/products/p92/3.jpg', 'images/products/p92/4.jpg', 'images/products/p92/5.jpg', 'images/products/p92/6.jpg', 'images/products/p92/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7510638' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E66' },
            { name: 'Specification', value: '735LI' },
            { name: 'Year', value: '2004' },
            { name: 'SKU', value: '122011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Engine', value: 'N62' },
            { name: 'Type', value: 'Module' },
        ],
        options: [],
    },
    {
        id: 93,
        name: 'MERCEDES BENZ W207 E200 2014 FENDERS RIGHT & LEFT',
        price: 585,
        compareAtPrice: 780,
        images: ['images/products/p93/1.jpg', 'images/products/p93/2.jpg', 'images/products/p93/3.jpg', 'images/products/p93/4.jpg', 'images/products/p93/5.jpg', 'images/products/p93/6.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W207' },
            { name: 'Specification', value: 'E200' },
            { name: 'Year', value: '2014' },
            { name: 'Type', value: 'Fenders Right & Left' },
        ],
        options: [],
    },
    {
        id: 94,
        name: 'MERCEDES BENZ W207 E200 2014 SIDE SKIRTS RIGHT & LEFT',
        price: 360,
        compareAtPrice: 480,
        images: ['images/products/p94/1.jpg', 'images/products/p94/2.jpg', 'images/products/p94/3.jpg', 'images/products/p94/4.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W207' },
            { name: 'Specification', value: 'E200' },
            { name: 'Year', value: '2014' },
            { name: 'Type', value: 'Side Skirts Right & Left' },
        ],
        options: [],
    },
    {
        id: 95,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 REAR BUMPER',
        price: 900,
        compareAtPrice: 1200,
        images: ['images/products/p95/1.jpg', 'images/products/p95/2.jpg', 'images/products/p95/3.jpg', 'images/products/p95/4.jpg', 'images/products/p95/5.jpg', 'images/products/p95/6.jpg', 'images/products/p95/7.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Rear Bumper' },
        ],
        options: [],
    },
    {
        id: 96,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 REAR BUMPER SUPPORT',
        price: 262.5,
        compareAtPrice: 350,
        images: ['images/products/p96/1.jpg', 'images/products/p96/2.jpg', 'images/products/p96/3.jpg', 'images/products/p96/4.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Type', value: 'Rear Bumper Support' },
        ],
        options: [],
    },
    {
        id: 97,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 BOOT LID DOOR WITH REAR WINDOW',
        price: 1200,
        compareAtPrice: 1600,
        images: ['images/products/p97/1.jpg', 'images/products/p97/2.jpg', 'images/products/p97/3.jpg', 'images/products/p97/4.jpg', 'images/products/p97/5.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Type', value: 'Boot Lid' },
        ],
        options: [],
    },
];
