/* eslint-disable */
// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';
import shopApi from '../../api/shop';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import queryString from 'query-string';
import config from '../../data/configurations';

export default function ShopPageOrderSuccess() {
    
    const [orderData, setOrderData] = useState([]);
    const queryData = queryString.parse(window.location.search);
    // console.log(queryData);
    useEffect(() => {

        const url = `${config.production.apiUrl}getorder?reference=`+queryData.reference;
        const order = fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());

        order.then(function(result) {
            console.log('response', result);
            setOrderData(result);
            // sendEmail(result);
            
        });

    }, []);

    function sendEmail(result) {
      
            const qData = {
                orderID: result.id,
            };
            const params = { ...qData };
            const url = `${config.production.apiUrl}sentorder?${queryString.stringify(params)}`;

            const sentmail = fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(qData),
            }).then((response) => response.json());
            sentmail.then((res) => {
                console.log('email response', res);
                // if (res) {
                     
                // } else if (res.message) {
                //     alert(res.message);
                // }
            });
    }

    // console.log('orderData', orderData);
    const items = orderData ? orderData.map((item) => {
        // console.log('item.details', item.details);
        const options = (item.details || []).map((option) => (
            
            //(option.options).map((optValue) => (
                <li className="order-list__options-item">
                <span className="order-list__options-label">
                    SKU
                    {': '}
                </span>
                <span className="order-list__options-value">{option.sku}</span>
            </li>
          //  ))
        ));

        return (
           (item.details || []).map((option) => (
            <tr>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to="/shop/products/{option.product_id}" className="product-image__body">
                            <img className="product-image__img" src={option.image} alt="" />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to="/shop/products/{option.product_id}">{option.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">{option.qty}</td>
                <td className="order-list__column-total"><Currency value={option.total_price} /></td>
            </tr>
            ))
        );
        
    }) : '';
    // console.log('orderData1', orderData);
    const additionalLines = 'A';
// const additionalLines = orderData ? orderData.map((line) => (
//         (line.additionalLines).map((line) => (
//         <tr>
//             <th className="order-list__column-label" colSpan="3">{line.label}</th>
//             <td className="order-list__column-total"><Currency value={line.total} /></td>
//         </tr>
//         ))
        
    
// )) : '';
    return (
        orderData? (orderData).map((order) => (
        <div>
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" style={{fill : 'green'}}/>
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order has been received</div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-danger" style={{border: '0px solid #546069',padding: '15px 12px 27px 12px',fontWeight:'600'}}>Go To Homepage</Link>
                        </div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title" style={{fontWeight: '700',fontSize: '14px'}}>Order number:</span>
                                <span className="order-success__meta-value">{`#${order.transaction}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title" style={{fontWeight: '700',fontSize: '14px'}}>Created at:</span>
                                <span className="order-success__meta-value">{order.created_at}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title" style={{fontWeight: '700',fontSize: '14px'}}>Total:</span>
                                <span className="order-success__meta-value"><Currency value={order.total} /></span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title" style={{fontWeight: '700',fontSize: '14px'}}>Payment method:</span>
                                <span className="order-success__meta-value">{order.payment_method}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="row mt-3 no-gutters mx-n2">
                        {/* <div className="col-sm-6 col-12 px-2">
                            <div className="card address-card" style={{width: '82%',left: '12.2%',border: '2px solid #aaa'}}>
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted" style={{fontWeight: '700',right: '0'}}>
                                        Shipping Address
                                    </div>
                                    <div className="address-card__name">
                                        {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
                                    </div>
                                    <div className="address-card__row">
                                        {order.shippingAddress.country}
                                        <br />
                                        {`${order.shippingAddress.postcode}, ${order.shippingAddress.city}`}
                                        <br />
                                        {order.shippingAddress.address}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title" style={{fontWeight: '700'}}>Phone Number</div>
                                        <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title" style={{fontWeight: '700'}}>Email Address</div>
                                        <div className="address-card__row-content">{order.shippingAddress.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-sm-12 col-12  mb-3">
                            <div className="card address-card" style={{width: '82%',left: '12.2%',border: '2px solid #aaa'}}>
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted" style={{fontWeight: '700',right: '0'}}>
                                        Shipping/ Billing Address
                                    </div>
                                    <div className="address-card__name">
                                        {`${order.first_name} ${order.last_name}`}
                                    </div>
                                    <div className="address-card__row">
                                        {order.address1}, {order.address2}
                                        <br />
                                        {`${order.postcode}, ${order.address3}`}
                                        <br />
                                        {order.country}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title" style={{fontWeight: '700'}}>Phone Number</div>
                                        <div className="address-card__row-content">{order.phone}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title" style={{fontWeight: '700'}}>Email Address</div>
                                        <div className="address-card__row-content">{order.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" style={{width: '91%', left: '6%'}}>
                        <div className="order-list">
                            <table style={{border: '2px solid gray'}}>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2" style={{fontWeight: '700',fontSize: '14px'}}>Product</th>
                                        <th className="order-list__column-quantity" style={{fontWeight: '700',fontSize: '14px'}}>Qty</th>
                                        <th className="order-list__column-total" style={{fontWeight: '700',fontSize: '14px'}}>Total</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                    {items}
                                </tbody>
                                {additionalLines.length > 0 && (
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">Subtotal</th>
                                            <td className="order-list__column-total"><Currency value={order.subtotal} /></td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">Shipping</th>
                                            <td className="order-list__column-total"><Currency value={order.shipping} /></td>
                                        </tr>
                                        {/* {additionalLines} */}
                                    </tbody>
                                )}
                                <tfoot className="order-list__footer">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3"><b>Total</b></th>
                                        <td className="order-list__column-total"><Currency value={order.total} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
        )) : ' TEST'
    );
}
