// react
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import qs from 'query-string';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';

export default function AccountPageOrderDetails() {
    const [orderDetails, setOrderDetails] = useState([]);
    const data = {
        userId: 1,
        orderId: 2021
    }
    function getOrderDetails() {
        fetch(`http://localhost:5000/orderDetails.json?${qs.stringify(data)}`, {
            method: "GET",
            // mode: 'no-cors',
            headers: { "Content-type": "application/json" },
        }).then((response) => response.json())
            .then((data) => {
                setOrderDetails(data)
            });
    }

    useEffect(() => {
        getOrderDetails();
    }, []);
    return (
        <div>
            {orderDetails.map((order) => (
                <React.Fragment>
                    <Helmet>
                        <title>{`Order Details — ${theme.name}`}</title>
                    </Helmet>
                    <div className="card">
                        <div className="order-header">
                            <div className="order-header__actions">
                                <Link to="/shop/user-tracking" className="btn btn-xs btn-secondary">Back to list</Link>
                            </div>
                            <h5 className="order-header__title">Order #{order.id}</h5>
                            <div className="order-header__subtitle">
                                Was placed on
                                {' '}
                                <mark className="order-header__date">{order.date}</mark>
                                {' '}
                                and is currently
                                {' '}
                                <mark className="order-header__status">On hold</mark>
                                .
                            </div>
                        </div>
                        <div className="card-divider" />
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {(order.items).map((order1) => (
                                        <tbody className="card-table__body card-table__body--merge-rows">
                                            <td>{order1.name} * {order1.quantity}</td>
                                            <td>{order1.price}</td>
                                        </tbody>
                                    ))}
                                    <tbody className="card-table__body card-table__body--merge-rows">
                                        <tr>
                                            <th>Subtotal</th>
                                            <td>{order.subtotal}</td>
                                        </tr>
                                        <tr>
                                            <th>Store Credit</th>
                                            <td>{order.storecredit}</td>
                                        </tr>
                                        <tr>
                                            <th>Shipping</th>
                                            <td>{order.shipping}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <td>{order.total}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-sm-6 col-12 px-2">
                            <div className="card address-card address-card--featured">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                                    <div className="address-card__name">{order.shippingAddress.firstName} {order.shippingAddress.lastName}</div>
                                    <div className="address-card__row">
                                        {order.shippingAddress.address}
                                        <br />
                                        {order.shippingAddress.postcode}, {order.shippingAddress.city}
                                        <br />
                                        {order.shippingAddress.country}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">Phone Number</div>
                                        <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">Email Address</div>
                                        <div className="address-card__row-content">{order.shippingAddress.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                            <div className="card address-card address-card--featured">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">Billing Address</div>
                                    <div className="address-card__name">{order.billingAddress.firstName} {order.billingAddress.lastName}</div>
                                    <div className="address-card__row">
                                        {order.billingAddress.address}
                                        <br />
                                        {order.billingAddress.postcode}, {order.billingAddress.city}
                                        <br />
                                        {order.billingAddress.country}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">Phone Number</div>
                                        <div className="address-card__row-content">{order.billingAddress.phone}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">Email Address</div>
                                        <div className="address-card__row-content">{order.billingAddress.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
}
