// react
import React from 'react';

// application
import { WhatsAppWidget } from 'react-whatsapp-widget';
import { ReactComponent as CompanyIcon } from '../../svg/crown.svg';

import 'react-whatsapp-widget/dist/index.css';
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
    // const informationLinks = [
    //     { title: 'About Us', url: '' },
    //     { title: 'Delivery Information', url: '' },
    //     { title: 'Privacy Policy', url: '' },
    //     { title: 'Brands', url: '' },
    //     { title: 'Contact Us', url: '' },
    //     { title: 'Returns', url: '' },
    //     { title: 'Site Map', url: '' },
    // ];

    const accountLinks = [
        // { title: 'Store Location', url: '' },
        { title: 'Order History', url: '' },
        { title: 'Wish List', url: '' },
        { title: 'Newsletter', url: '' },
        { title: 'Specials', url: '' },
        // { title: 'Gift Certificates', url: '' },
        // { title: 'Affiliate', url: '' },
    ];

    return (
        <div className="site-footer">
            <div className="w-75 mx-auto">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            {/* <FooterLinks title="Information" items={informationLinks} /> */}

                            {/* ====================== */}

                            <div className="site-footer__widget footer-contacts">
                                <h5 className="footer-contacts__title">KEK SENG AUTO PARTS TRADING SDN BHD</h5>

                                <div className="footer-contacts__text" />

                                <ul className="footer-contacts__contacts">
                                    <li>
                                        <i className="footer-contacts__icon fas fa-globe-americas" />
                                        {theme.contacts.malaysia.address}
                                    </li>
                                    <li>
                                        <i className="footer-contacts__icon far fa-envelope" />
                                        {theme.contacts.malaysia.email}
                                    </li>
                                    <li>
                                        <i className="footer-contacts__icon fas fa-mobile-alt" />
                                        {`${theme.contacts.malaysia.phone}`}
                                    </li>
                                    <li>
                                        <i className="footer-contacts__icon far fa-clock" />
                                        Mon-Sat 9:30am - 6:00pm
                                    </li>
                                </ul>
                            </div>

                            {/* ====================== */}

                        </div>
                        <div className="col-12 col-md-6 col-lg-2">
                            <FooterLinks title="MY ACCOUNT" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by
                        {' '}
                        <a href="https://propel-auto.com/" rel="noopener noreferrer" target="_blank">Propel Auto Parts</a>
                        {' '}
                        — Design by
                        {' '}
                        <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a>
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
                {/* <FloatingWhatsApp
                    phoneNumber="123456789"
                    accountName="Foo"
                    allowEsc
                    allowClickAway
                    notification
                    notificationSound
                /> */}
                <WhatsAppWidget
                    companyName={theme.name}
                    CompanyIcon={CompanyIcon}
                    phoneNumber="6585198833"
                    // open
                    replyTimeText="Typically replies within an hour"
                    message="Hi! How can we help you?"
                    sendButtonText="Chat Now"
                    // inputPlaceHolder
                />
                {/* <WhatsAppWidget
                    companyName={theme.name}
                    CompanyIcon={CompanyIcon}
                    phoneNumber="919500489748"
                    replyTimeText="Typically replies within an hour"
                    message="Hi 2"
                    sendButtonText="Chat Now"
                    // inputPlaceHolder
                /> */}
            </div>
            <ToTop />
        </div>
    );
}
