export default {
    name: 'Propel Autoparts',
    local: {
        siteUrl: 'http://localhost:5000/',
        apiUrl: 'http://localhost:81/propel/backend/api/',
        apikey: 'fedcdc6a36c21c5f3459cb416c6a31c0',
        apiconnection: 'demoapi',
        mediaUrl: 'http://localhost:81/propel/backend/',
        accountNumber: '610625909',
        userName: 'apA1aC2kO6bE5i',
        passWord: 'C^0vX@6iN@6qU@2l',
        ShippingAPI: 'https://express.api.dhl.com/mydhlapi/test/rates',
        originCountryCode: 'SG',
        originPostalCode: '738364',
        originCityName: 'Singapore',
        hitPayKey: '0a7c69145c3190fe242e77dd11cd79e435d74aab89124f347c03b79e1714ae3a',
        hitPaySalt: '3U4AVbkOTF8wuusJnVy42uqTvD1flCMzaJhxMA9kBKtS98atXDAND9rRiZxFIrz9',
        hitPayUrl: 'https://api.sandbox.hit-pay.com/v1/payment-requests',
        hitPayDomain: 'sandbox.hit-pay.com',
        hitPayWindow: 'https://securecheckout.sandbox.hit-pay.com/payment-request/@propel-auto-parts-pte-ltd',
    },
    stage: {
        siteUrl: 'https://store.propel-auto.com/',
        apiUrl: 'https://store.propel-auto.com/admin/api/',
        apikey: 'fedcdc6a36c21c5f3459cb416c6a31c0',
        apiconnection: 'demoapi',
        mediaUrl: 'https://store.propel-auto.com/admin/',
        accountNumber: '610625909',
        userName: 'apA1aC2kO6bE5i',
        passWord: 'C^0vX@6iN@6qU@2l',
        ShippingAPI: 'https://express.api.dhl.com/mydhlapi/test/rates',
        originCountryCode: 'SG',
        originPostalCode: '738364',
        originCityName: 'Singapore',
        hitPayKey: '0a7c69145c3190fe242e77dd11cd79e435d74aab89124f347c03b79e1714ae3a',
        hitPaySalt: '3U4AVbkOTF8wuusJnVy42uqTvD1flCMzaJhxMA9kBKtS98atXDAND9rRiZxFIrz9',
        hitPayUrl: 'https://api.sandbox.hit-pay.com/v1/payment-requests',
        hitPayDomain: 'sandbox.hit-pay.com',
        hitPayWindow: 'https://securecheckout.sandbox.hit-pay.com/payment-request/@propel-auto-parts-pte-ltd',
    },
    production: {
        siteUrl: 'https://store.propel-auto.com/',
        apiUrl: 'https://store.propel-auto.com/admin/api/',
        apikey: 'fedcdc6a36c21c5f3459cb416c6a31c0',
        apiconnection: 'demoapi',
        mediaUrl: 'https://store.propel-auto.com/admin/',
        accountNumber: '610625909',
        userName: 'apA1aC2kO6bE5i',
        passWord: 'C^0vX@6iN@6qU@2l',
        ShippingAPI: 'https://express.api.dhl.com/mydhlapi/test/rates',
        originCountryCode: 'SG',
        originPostalCode: '738364',
        originCityName: 'Singapore',
        // hitPayKey: '0a7c69145c3190fe242e77dd11cd79e435d74aab89124f347c03b79e1714ae3a',
        // hitPaySalt: '3U4AVbkOTF8wuusJnVy42uqTvD1flCMzaJhxMA9kBKtS98atXDAND9rRiZxFIrz9',
        // hitPayUrl: 'https://api.sandbox.hit-pay.com/v1/payment-requests',
        hitPayKey: '0f32be63a0d4aa0c165e912c8c123b87418fee1133e2686a6dfb423ecdde2642',
        hitPaySalt: 's6rnIvbsfuvdEOkHPuUMlNGr6NRMvk3mPbbY2Sh6kp5XUdVHcSjRalH2HRVGi27F',
        hitPayUrl: 'https://api.hit-pay.com/v1/payment-requests',
        hitPayDomain: 'sandbox.hit-pay.com',
        hitPayWindow: 'https://securecheckout.sandbox.hit-pay.com/payment-request/@propel-auto-parts-pte-ltd',
    },
};
