// react
/* eslint-disable */
import React, { useEffect, useState }from 'react';
import qs from 'query-string';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';

export default function AccountPageAddresses() {
    const [address, setAddress] = useState([]);
    function getAddress() {
        const data = {
            userId: 1,
            orderId: 2021
        }
        fetch(`http://localhost:5000/addressDetails.json?${qs.stringify(data)}`, {
            method: "GET",
            mode: 'no-cors',
            headers: { "Content-type": "application/json" },
        }).then((response) => response.json())
            .then((data) => {
                setAddress(data);
            });
    }

    useEffect(() => {
        getAddress();
    }, []);
    const addresses = address.map((addr) => (
        <React.Fragment key={addr.id}>
            <div className="addresses-list__item card address-card">
                {addr.default && <div className="address-card__badge">Default</div>}

                <div className="address-card__body">
                    <div className="address-card__name">{`${addr.firstName} ${addr.lastName}`}</div>
                    <div className="address-card__row">
                        {addr.country}
                        <br />
                        {addr.postcode}
                        ,
                        {addr.city}
                        <br />
                        {addr.address} 
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{addr.phone}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{addr.email}</div>
                    </div>
                    <div className="address-card__footer">
                        <Link to={{pathname:"/account/addresses/5", emailAddr: `?email=${addr.email}`,firstAddr:`?first=${addr.firstName}`,lastAddr:`?last=${addr.lastName}`                       
                        ,phoneAddr:`?phone=${addr.phone}`,cityAddr:`?city=${addr.city}`,countryAddr:`?country=${addr.country}`,stateAddr:`?state=${addr.state}`,postcodeAddr:`?postcode=${addr.postcode}`
                        ,companyaddr:`?company=${addr.company}`,streetaddr:`?street=${addr.street}`,apartmentaddr:`?apartment=${addr.apartment}`,address:`?address=${addr.address}`}}>Edit</Link>
                        &nbsp;&nbsp;
                        <Link to="/">Remove</Link>
                    </div>
                </div>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ));

    return (
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${theme.name}`}</title>
            </Helmet>

            <Link to="/account/new" className="addresses-list__item addresses-list__item--new">
                <div className="addresses-list__plus" />
                <div className="btn btn-secondary btn-sm">Add New</div>
            </Link>
            <div className="addresses-list__divider" />
            {addresses}
        </div>
    );
}
