/* eslint-disable no-unused-vars */
import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import { categoriesListData, prepareCategory } from './categories';
import config from '../../data/configurations';

const getId = makeIdGenerator();
// console.log('databese pdt');
const attributesDef = [
    {
        name: 'Color',
        slug: 'color',
        values: [
            { name: 'White', slug: 'white' },
            { name: 'Silver', slug: 'silver' },
            { name: 'Light Gray', slug: 'light-gray' },
            { name: 'Gray', slug: 'gray' },
            { name: 'Dark Gray', slug: 'dark-gray' },
            { name: 'Coal', slug: 'coal' },
            { name: 'Black', slug: 'black' },
            { name: 'Red', slug: 'red' },
            { name: 'Orange', slug: 'orange' },
            { name: 'Yellow', slug: 'yellow' },
            { name: 'Pear Green', slug: 'pear-green' },
            { name: 'Green', slug: 'green' },
            { name: 'Emerald', slug: 'emerald' },
            { name: 'Shamrock', slug: 'shamrock' },
            { name: 'Shakespeare', slug: 'shakespeare' },
            { name: 'Blue', slug: 'blue' },
            { name: 'Dark Blue', slug: 'dark-blue' },
            { name: 'Violet', slug: 'violet' },
            { name: 'Purple', slug: 'purple' },
            { name: 'Cerise', slug: 'cerise' },
        ],
    },
    {
        name: 'Brand',
        slug: 'brand',
        values: [
            { name: 'Mercedes Benz', slug: 'mercedes-benz' },
            { name: 'BMW', slug: 'bmw' },
            { name: 'Audi', slug: 'audi' },
            { name: 'Volvo', slug: 'volvo' },
            { name: 'Jaguar', slug: 'jaguar' },
            { name: 'VW', slug: 'vw' },

        ],
    },

];

const productsDef = [
    {
        id: 1,
        name: 'BMW F46 216D GRAN TOURER 2015 AC CLIMATE CONTROL PANEL SWITCH 9371459',
        price: 512,
        compareAproductsDeftPrice: null,
        images: ['images/products/p1/1.jpg', 'images/products/p1/2.jpg', 'images/products/p1/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9371459' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F46' },
            { name: 'Specification', value: '216D GRAN TOURER' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '136938' },
            { name: 'Other Part Number', value: '20020110' },
            { name: 'Type', value: 'Switch' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
        slug: 'bmw-f46-216d-gran-tourer-2015-ac-climate-control-panel-switch-9371459',
        brand: 'bmw',
        categories: ['interior'],
        sku: '136938',
    },
    {
        id: 2,
        name: 'BMW E60 LCI 523I XL 2007 AUTOMATIC GEAR SELECTOR SHIFTER KNOB 9159751',
        price: 50,
        compareAtPrice: null,
        images: ['images/products/p2/1.jpg', 'images/products/p2/2.jpg', 'images/products/p2/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9159751' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60 LCI' },
            { name: 'Specification', value: '523I XL' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '115118' },
            { name: 'Other Part Number', value: '033810505' },
            { name: 'Type', value: 'Knob' },
            { name: 'Transmission Type', value: 'Automatic' },
        ],
        options: [],
        slug: 'bmw-e60-lci-523i-xl-2007-automatic-gear-selector-shifter-knob-9159751',
        brand: 'bmw',
        categories: ['interior'],
        sku: '115118',
    },
    {
        id: 3,
        name: 'JAGUAR XJ 2011 INTERIOR CENTER DASHBOARD CLOCK AW93-1500-AF 4031600',
        price: 161,
        compareAtPrice: null,
        images: ['images/products/p3/1.jpg', 'images/products/p3/2.jpg', 'images/products/p3/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'AW93-1500-AF' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Other Part Number', value: '4031600' },
            { name: 'SKU', value: '121464' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Placement on Vehicle', value: 'Center' },
            { name: 'Type', value: 'Clock' },
        ],
        options: [],
        slug: 'jaguar-xj-2011-interior-center-dashboard-clock-aw93-1500-af-4031600',
        brand: 'jaguar',
        categories: ['interior'],
        sku: '121464',
    },
    {
        id: 4,
        name: 'PORSCHE CARRERA 911 996 1999 INSTRUMENT CLUSTER SPEEDOMETER LHD',
        price: 633,
        compareAtPrice: null,
        images: ['images/products/p4/1.jpg', 'images/products/p4/2.jpg', 'images/products/p4/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'CARRERA 911 996' },
            { name: 'SKU', value: '109676' },
            { name: 'Year', value: '1999' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Speedometer' },
        ],
        options: [],
        slug: 'porsche-carrera-911-996-1999-instrument-cluster-speedometer-lhd',
        brand: 'porsche',
        categories: ['interior'],
        sku: '109676',
    },
    {
        id: 5,
        name: 'MERCEDES BENZ W201 2006 BOSCH ENGINE ECU CONTROL UNIT 0105455632 0280800388',
        price: 115,
        compareAtPrice: null,
        images: ['images/products/p5/1.jpg', 'images/products/p5/2.jpg', 'images/products/p5/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '0105455632' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W201' },
            { name: 'Year', value: '2006' },
            { name: 'Bosch Part number', value: '0280800388' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Surface Finish', value: 'Metal' },
            { name: 'Type', value: 'ECU' },
        ],
        options: [],
        slug: 'mercedes-benz-w201-2006-bosch-engine-ecu-control-unit-0105455632-0280800388',
        brand: 'mercedes-benz',
        categories: ['module'],
        sku: '',
    },
    {
        id: 6,
        name: 'MERCEDES BENZ W203 C180 DASH CENTER AC LOUVER VENT BLACK A2038302254',
        price: 75,
        compareAtPrice: null,
        images: ['images/products/p6/1.jpg', 'images/products/p6/2.jpg', 'images/products/p6/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2038302254' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W203' },
            { name: 'Specification', value: 'C180' },
            { name: 'SKU', value: '153971' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'AC Louver Vent' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
        slug: 'mercedes-benz-w203-c180-dash-center-ac-louver-vent-black-a2038302254',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '153971',
    },
    {
        id: 7,
        name: 'MERCEDES BENZ W221 S350 2007 REAR CENTER CONSOLE AIR VENT SET 2218301054',
        price: 128,
        compareAtPrice: null,
        images: ['images/products/p7/1.jpg', 'images/products/p7/2.jpg', 'images/products/p7/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: '1',
        attributes: [
            { name: 'Part Number', value: '2218301054' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W221' },
            { name: 'Specification', value: 'S350' },
            { name: 'Year', value: '2007' },
            { name: 'Other Part Number', value: '2216801639' },
            { name: 'SKU', value: '109023' },
            { name: 'Placement on Vehicle', value: 'Rear Center' },
            { name: 'Type', value: 'Console Air Vent' },
        ],
        options: [],
        slug: 'mercedes-benz-w221-s350-2007-rear-center-console-air-vent-set-221830105',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '109023',
    },
    {
        id: 8,
        name: 'PORSCHE 911 2007 BOSE AUDIO RADIO AMP AMPLIFIER 99764533422 434011',
        price: 296,
        compareAtPrice: null,
        images: ['images/products/p8/1.jpg', 'images/products/p8/2.jpg', 'images/products/p8/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '99764533422' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911' },
            { name: 'Year', value: '2007' },
            { name: 'Other Part Number', value: '434011' },
            { name: 'SKU', value: '123188' },
            { name: 'Surface Finish', value: 'Metal' },
            { name: 'Country of Manufacture', value: 'United States' },
            { name: 'Type', value: 'Amplifier' },
        ],
        options: [],
        slug: 'porsche-911-2007-bose-audio-radio-amp-amplifier-99764533422-434011',
        brand: 'porsche',
        categories: ['audio'],
        sku: '123188',
    },
    {
        id: 9,
        name: 'PORSCHE 911 1999 EXTERIOR DOOR HANDLE FRONT RIGHT 996537064',
        price: 43,
        compareAtPrice: null,
        images: ['images/products/p9/1.jpg', 'images/products/p9/2.jpg', 'images/products/p9/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '996537064' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911' },
            { name: 'Year', value: '1999' },
            { name: 'Placement on Vehicle', value: 'Front Right' },
            { name: 'Type', value: 'Door Handle' },
        ],
        options: [],
        slug: 'porsche-911-1999-exterior-door-handle-front-right-996537064',
        brand: 'porsche',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 10,
        name: 'BMW 1 2 3 4 SERIES FRONT RIGHT FOG LAMP DRIVING LIGHT 7248912',
        price: 109,
        compareAtPrice: null,
        images: ['images/products/p10/1.jpg', 'images/products/p10/2.jpg', 'images/products/p10/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7248912' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: '1 2 3 4 SERIES' },
            { name: 'Placement on Vehicle', value: 'Front Right' },
            { name: 'Type', value: 'Fog Lamp' },
        ],
        options: [],
        slug: 'bmw-1-2-3-4-series-front-right-fog-lamp-driving-light-7248912',
        brand: 'bmw',
        categories: ['lights'],
        sku: '',
    },
    {
        id: 11,
        name: 'MERCEDES BENZ W222 S320 2017 FRONT BUMPER',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p11/1.jpg', 'images/products/p11/2.jpg', 'images/products/p11/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2228800147' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2228800247' },
            { name: 'Type', value: 'Front Bumper' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s320-2017-front-bumper',
        brand: 'mercedes-benz',
        categories: ['bodykits', 'exterior'],
        sku: '',
    },
    {
        id: 12,
        name: 'MERCEDES BENZ W222 S320 2017 REAR BUMPER',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p12/1.jpg', 'images/products/p12/2.jpg', 'images/products/p12/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2228800040' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2228800049' },
            { name: 'Type', value: 'Rear Bumper' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s320-2017-rear-bumper',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 13,
        name: 'MERCEDES BENZ W222 S320 2017 BOOT LID',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p13/1.jpg', 'images/products/p13/2.jpg', 'images/products/p13/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Boot Lid' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s320-2017-boot-lid',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 14,
        name: 'MERCEDES BENZ W222 S320 2017 LED HEADLAMP LEFT & RIGHT',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p14/1.jpg', 'images/products/p14/2.jpg', 'images/products/p14/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2229060802' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2229060702' },
            { name: 'Type', value: 'Headlamp' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s320-2017-led-headlamp-left-&-right',
        brand: 'mercedes-benz',
        categories: ['lights'],
        sku: '',
    },
    {
        id: 15,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 LED TAIL LAMP RIGHT & LEFT',
        price: 680,
        compareAtPrice: null,
        images: ['images/products/p15/1.jpg', 'images/products/p15/2.jpg', 'images/products/p15/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2518202064' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Other Part Number', value: 'A2518201964' },
            { name: 'Type', value: 'Tail Lamp' },
        ],
        options: [],
        slug: 'mercedes-benz-w251-r400-4matic-2013-led-tail-lamp-right-&-left',
        brand: 'mercedes-benz',
        categories: ['lights'],
        sku: '',
    },
    {
        id: 16,
        name: 'MERCEDES BENZ W172 SLK200 SIDE MIRRORS RIGHT & LEFT',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p16/1.jpg', 'images/products/p16/2.jpg', 'images/products/p16/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Side Mirror' },
        ],
        options: [],
        slug: 'mercedes-benz-w172-slk200-side-mirrors-right-&-left',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 17,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 FENDERS RIGHT & LEFT',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p17/1.jpg', 'images/products/p17/2.jpg', 'images/products/p17/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Fender' },
        ],
        options: [],
        slug: 'mercedes-benz-w292-gle400-4matic-2017-fenders-right-&-left',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 18,
        name: 'MERCEDES BENZ W172 SLK200 BONNET RED',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p18/1.jpg', 'images/products/p18/2.jpg', 'images/products/p18/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Bonnet' },
        ],
        options: [],
        slug: 'mercedes-benz-w172-slk200-bonnet-red',
        brand: 'mercedes-benz',
        categories: ['bodykits', 'bonnet'],
        sku: '',
    },
    {
        id: 19,
        name: 'MERCEDES BENZ W172 SLK200 SIDE SKIRTS RIGHT & LEFT',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p19/1.jpg', 'images/products/p19/2.jpg', 'images/products/p19/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Side Skirt' },
        ],
        options: [],
        slug: 'mercedes-benz-w172-slk200-side-skirts-right-&-left',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 20,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 REAR LEFT DOOR',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p20/1.jpg', 'images/products/p20/2.jpg', 'images/products/p20/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Door' },
        ],
        options: [],
        slug: 'mercedes-benz-w292-gle400-4matic-2017-rear-left-door',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 21,
        name: 'PORSCHE MACAN S 3.0L V6 2015 LONG BLOCK MOTOR ENGINE CTM025020 FREE SHIPPING',
        price: 14998,
        compareAtPrice: null,
        images: ['images/products/p21/1.jpg', 'images/products/p21/2.jpg', 'images/products/p21/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'CTM025020' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'MACAN S' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2015' },
            { name: 'Fuel Type', value: 'Gasoline' },
            { name: 'SKU', value: '154162' },
            { name: 'Performance Type', value: 'Turbocharged' },
            { name: 'Type', value: 'Complete Engine' },
        ],
        options: [],
        slug: 'porsche-macan-s-3.0l-v6-2015-long-block-motor-engine-ctm025020-free-shipping',
        brand: 'porsche',
        categories: ['engines'],
        sku: '154162',
    },
    {
        id: 22,
        name: 'MERCEDES BENZ W222 S400 2017 STAGGERED RIMS WITH TYRES (19)',
        price: 1800,
        compareAtPrice: null,
        images: ['images/products/p22/1.jpg', 'images/products/p22/2.jpg', 'images/products/p22/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Rims With Tyre' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s400-2017-staggered-rims-with-tyres-(19)',
        brand: 'mercedes-benz',
        categories: ['wheels'],
        sku: '',
    },
    {
        id: 23,
        name: 'MERCEDES BENZ W222 S400 GEARBOX 722904 & TORQUE A2312500802',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p23/1.jpg', 'images/products/p23/2.jpg', 'images/products/p23/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2312500802' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Other Part Number', value: '722904' },
            { name: 'Type', value: 'Gearbox Torque' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s400-gearbox-722904-&-torque-a2312500802',
        brand: 'mercedes-benz',
        categories: ['engines'],
        sku: '',
    },
    {
        id: 24,
        name: 'MERCEDES BENZ W222 S400 2016 AIR ABSORBER SUSPENSION FRONT & REAR',
        price: 1391,
        compareAtPrice: null,
        images: ['images/products/p24/1.jpg', 'images/products/p24/2.jpg', 'images/products/p24/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7248912' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Absorber' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s400-2016-air-absorber-suspension-front-&-rear',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 25,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 PARK BRAKE ACUTATOR',
        price: 220,
        compareAtPrice: null,
        images: ['images/products/p25/1.jpg', 'images/products/p25/2.jpg', 'images/products/p25/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Park Brake Acutator' },
        ],
        options: [],
        slug: 'mercedes-benz-w156-gla45amg-2016-park-brake-acutator',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 26,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 STEERING RACK LHD',
        price: 2200,
        compareAtPrice: null,
        images: ['images/products/p26/1.jpg', 'images/products/p26/2.jpg', 'images/products/p26/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '6820000221' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Other Part Number', value: '6700003475A' },
            { name: 'Type', value: 'Steering Rack' },
        ],
        options: [],
        slug: 'mercedes-benz-w156-gla45amg-2016-steering-rack-lhd',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 27,
        name: 'JAGUAR XJ 3.0L D 6DT 2010 AIRMATIC SUSPENSION COMPRESSOR PUMP AW933B484AF',
        price: 270,
        compareAtPrice: null,
        images: ['images/products/p27/1.jpg', 'images/products/p27/2.jpg', 'images/products/p27/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'AW933B484AF' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '151181' },
            { name: 'Engine', value: '06DT' },
            { name: 'Type', value: 'Airmatic Pump' },
        ],
        options: [],
        slug: 'jaguar-xj-3.0l-d-6dt-2010-airmatic-suspension-compressor-pump-aw933b484af',
        brand: 'jaguar',
        categories: ['interior'],
        sku: '151181',
    },
    {
        id: 28,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 ROOF GLASS',
        price: 680,
        compareAtPrice: null,
        images: ['images/products/p28/1.jpg', 'images/products/p28/2.jpg', 'images/products/p28/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Roof Glass' },
        ],
        options: [],
        slug: 'mercedes-benz-w156-gla45amg-2016-roof-glass',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 29,
        name: 'MERCEDES BENZ W156 GLA45AMG 2016 CROSS MEMBER',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p29/1.jpg', 'images/products/p29/2.jpg', 'images/products/p29/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W156' },
            { name: 'Specification', value: 'GLA45AMG' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Cross Member' },
        ],
        options: [],
        slug: 'mercedes-benz-w156-gla45amg-2016-cross-member',
        brand: 'mercedes-benz',
        categories: ['others'],
        sku: '',
    },
    {
        id: 30,
        name: 'MERCEDES BENZ W222 S400 REAR AXLE WITH DIFFERENTIAL',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p30/1.jpg', 'images/products/p30/2.jpg', 'images/products/p30/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Type', value: 'Rear Axle' },
        ],
        options: [],
        slug: 'mercedes-benz-w222-s400-rear-axle-with-differential',
        brand: 'mercedes-benz',
        categories: ['others'],
        sku: '',
    },
    {
        id: 31,
        name: 'AUDI A4 2010 FRONT RIGHT LOWER FORWARD CONTROL ARM 8K0407156B',
        price: 27,
        compareAtPrice: null,
        images: ['images/products/p31/1.jpg', 'images/products/p31/2.jpg', 'images/products/p31/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8K0407156B' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A4' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '122546' },
            { name: 'Type', value: 'Control ARM' },
            { name: 'Placement on Vehicle', value: 'Right Front' },
        ],
        options: [],
        slug: 'audi-a4-2010-front-right-lower-forward-control-arm-8k0407156b',
        brand: 'audi',
        categories: ['others'],
        sku: '122546',
    },
    {
        id: 32,
        name: 'AUDI A6 2.0 2007 05-08 BATTERY CONTROL UNIT MODULE 4F0915181A 4F0910181E',
        price: 90,
        compareAtPrice: null,
        images: ['images/products/p32/1.jpg', 'images/products/p32/2.jpg', 'images/products/p32/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '4F0915181A' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A6' },
            { name: 'Specification', value: '2.0' },
            { name: 'Year', value: '2007' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Other Part Number', value: '4F0910181E' },
            { name: 'Country of Manufacture', value: 'France' },
            { name: 'Type', value: 'Module' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
        slug: 'audi-a6-2.0-2007-05-08-battery-control-unit-module-4f0915181a-4f0910181e',
        brand: 'audi',
        categories: ['interior', 'modules'],
        sku: '',
    },
    {
        id: 33,
        name: 'VOLKSWAGEN PASSAT 1.8TSI 2011 SPARE KEY FOB',
        price: 115,
        compareAtPrice: null,
        images: ['images/products/p33/1.jpg', 'images/products/p33/2.jpg', 'images/products/p33/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Volkswagen' },
            { name: 'Model', value: 'PASSAT' },
            { name: 'Specification', value: '1.8TSI' },
            { name: 'Stock Number', value: '06488' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Spare Key' },
        ],
        options: [],
        slug: 'volkswagen-passat-1.8tsi-2011-spare-key-fob',
        brand: 'volkswagen',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 34,
        name: 'BENTLEY CONTINENTAL FLYING SPUR 2008 OVERHEAD ROOF LAMP FRONT 3W0907135F',
        price: 348,
        compareAtPrice: null,
        images: ['images/products/p34/1.jpg', 'images/products/p34/2.jpg', 'images/products/p34/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '3W0907135F' },
            { name: 'Brand', value: 'Bentley' },
            { name: 'Model', value: 'CONTINENTAL FLYING SPUR' },
            { name: 'SKU', value: '145705' },
            { name: 'Year', value: '2008' },
            { name: 'Type', value: 'Roof Lamp' },
            { name: 'Other Part Number', value: '364341' },
            { name: 'Country of Manufacture', value: 'Australia' },
            { name: 'Placement on Vehicle', value: 'Front Overhead' },
        ],
        options: [],
        slug: 'bentley-continental-flying-spur-2008-overhead-roof-lamp-front-3w0907135f',
        brand: 'bentley',
        categories: ['electrical'],
        sku: '145705',
    },
    {
        id: 35,
        name: 'MINI COOPER D F56 2014 INTERIOR AUTO DIM REAR VIEW CENTER MIRROR 9285373',
        price: 173,
        compareAtPrice: null,
        images: ['images/products/p35/1.jpg', 'images/products/p35/2.jpg', 'images/products/p35/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9285373' },
            { name: 'Brand', value: 'Mini Cooper' },
            { name: 'Model', value: 'F56' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '145501' },
            { name: 'Placement on Vehicle', value: 'Front Upper' },
            { name: 'Type', value: 'Center Mirror' },
            { name: 'Features', value: 'Auto Dimming' },
        ],
        options: [],
        slug: 'mini-cooper-d-f56-2014-interior-auto-dim-rear-view-center-mirror-9285373',
        brand: 'mini',
        categories: ['interior'],
        sku: '145501',
    },
    {
        id: 36,
        name: 'BMW F12 640i 2011 STEERING ANGLE SENSOR WITH INDICATOR SWITCH 9245623',
        price: 170,
        compareAtPrice: null,
        images: ['images/products/p36/1.jpg', 'images/products/p36/2.jpg', 'images/products/p36/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9245623' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F12' },
            { name: 'Specification', value: '640i' },
            { name: 'Year', value: '2011' },
            { name: 'SKU', value: '140943' },
            { name: 'Type', value: 'Steering Angle Sensor' },
        ],
        options: [],
        slug: 'bmw-f12-640i-2011-steering-angle-sensor-with-indicator-switch-9245623',
        brand: 'bmw',
        categories: ['interior'],
        sku: '140943',
    },
    {
        id: 37,
        name: 'AUDI A4 2.0TFSI 2016 DASH MMI NAVIGATION TV DISPLAY 8W2919605',
        price: 348,
        compareAtPrice: null,
        images: ['images/products/p37/1.jpg', 'images/products/p37/2.jpg', 'images/products/p37/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8W2919605' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A4' },
            { name: 'Specification', value: '2.0TFSI' },
            { name: 'Year', value: '2016' },
            { name: 'Country of Manufacture', value: 'China' },
            { name: 'SKU', value: '150832' },
            { name: 'Type', value: 'TV Display' },
        ],
        options: [],
        slug: 'audi-a4-2.0tfsi-2016-dash-mmi-navigation-tv-display-8w2919605',
        brand: 'audi',
        categories: ['interior'],
        sku: '150832',
    },
    {
        id: 38,
        name: 'AUDI A8 3.0 TFSI 2011 ELECTRONIC ACCELERATOR PEDAL RHD 6PV009505',
        price: 28,
        compareAtPrice: null,
        images: ['images/products/p38/1.jpg', 'images/products/p38/2.jpg', 'images/products/p38/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '6PV009505' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A8' },
            { name: 'Specification', value: '3.0 TFSI' },
            { name: 'Year', value: '2011' },
            { name: 'SKU', value: '141485' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Accelerator Pedal' },
        ],
        options: [],
        slug: 'audi-a8-3.0-tfsi-2011-electronic-accelerator-pedal-rhd-6pv009505',
        brand: 'audi',
        categories: ['interior'],
        sku: '141485',
    },
    {
        id: 39,
        name: 'VOLVO S60 2011 FRONT BUMPER CAMERA 31339995',
        price: 230,
        compareAtPrice: null,
        images: ['images/products/p39/1.jpg', 'images/products/p39/2.jpg', 'images/products/p39/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '31339995' },
            { name: 'Brand', value: 'Volvo' },
            { name: 'Model', value: 'S60' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Bumper Camera' },
        ],
        options: [],
        slug: 'volvo-s60-2011-front-bumper-camera-31339995',
        brand: 'volvo',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 40,
        name: 'BENTLEY CONTINENTAL FLYING SPUR 2008 SUN VISOR BLACK ONE PAIR',
        price: 528,
        compareAtPrice: null,
        images: ['images/products/p40/1.jpg', 'images/products/p40/2.jpg', 'images/products/p40/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Bentley' },
            { name: 'Model', value: 'CONTINENTAL FLYING SPUR' },
            { name: 'Year', value: '2008' },
            { name: 'SKU', value: '145707' },
            { name: 'Placement on Vehicle', value: 'Left, Right, Front' },
            { name: 'Type', value: 'Sun Visor' },
        ],
        options: [],
        slug: 'bentley-continental-flying-spur-2008-sun-visor-black-one-pair',
        brand: 'bentley',
        categories: ['interior'],
        sku: '145707',
    },
    {
        id: 41,
        name: 'BMW E46 M3 3 SERIES CD CHANGER MAGAZINE UNIT 8364931',
        price: 17,
        compareAtPrice: null,
        images: ['images/products/p41/1.jpg', 'images/products/p41/2.jpg', 'images/products/p41/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8364931' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E46' },
            { name: 'Specification', value: '3 SERIES' },
            { name: 'SKU', value: '119597' },
            { name: 'Material', value: 'Plastic' },
            { name: 'Type', value: 'CD Changer' },
        ],
        options: [],
        slug: 'bmw-e46-m3-3-series-cd-changer-magazine-unit-8364931',
        brand: 'bmw',
        categories: ['interior'],
        sku: '119597',
    },
    {
        id: 42,
        name: 'BMW E90 N46 2005 ENGINE THROTTLE BODY HOUSING ASSY 1439580',
        price: 60,
        compareAtPrice: null,
        images: ['images/products/p42/1.jpg', 'images/products/p42/2.jpg', 'images/products/p42/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '1439580' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90' },
            { name: 'Year', value: '2005' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'SKU', value: '112194' },
            { name: 'Type', value: 'Engine Throttle Body' },
        ],
        options: [],
        slug: 'bmw-e90-n46-2005-engine-throttle-body-housing-assy-1439580',
        brand: 'bmw',
        categories: ['interior'],
        sku: '112194',
    },
    {
        id: 43,
        name: 'BMW 7 SERIES E65 E66 E67 2006 DISTRIBUTION TRUNK FUSE BOX 6900583',
        price: 57,
        compareAtPrice: null,
        images: ['images/products/p43/1.jpg', 'images/products/p43/2.jpg', 'images/products/p43/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '6900583' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E66' },
            { name: 'Specification', value: '7 SERIES' },
            { name: 'Year', value: '2006' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Fuse Box' },
        ],
        options: [],
        slug: 'bmw-7-series-e65-e66-e67-2006-distribution-trunk-fuse-box-6900583',
        brand: 'bmw',
        categories: ['others'],
        sku: '',
    },
    {
        id: 44,
        name: 'BMW E93 REAR THIRD BRAKE STOP LIGHT LED 7162309',
        price: 78,
        compareAtPrice: null,
        images: ['images/products/p44/1.jpg', 'images/products/p44/2.jpg', 'images/products/p44/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7162309' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E93' },
            { name: 'SKU', value: '149339' },
            { name: 'Placement on Vehicle', value: 'Rear' },
            { name: 'Type', value: 'Brake Stop Light' },
        ],
        options: [],
        slug: 'bmw-e93-rear-third-brake-stop-light-led-7162309',
        brand: 'bmw',
        categories: ['electrical'],
        sku: '149339',
    },
    {
        id: 45,
        name: 'MERCEDES BENZ W212 BONNET GAS SPRING STRUT A2048800029 / A2129800064',
        price: 78,
        compareAtPrice: null,
        images: ['images/products/p45/1.jpg', 'images/products/p45/2.jpg', 'images/products/p45/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2129800064' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W212' },
            { name: 'SKU', value: '133703' },
            { name: 'Other Part Number', value: 'A2048800029' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Gas Spring Strut' },
        ],
        options: [],
        slug: 'mercedes-benz-w212-bonnet-gas-spring-strut-a2048800029-/-a2129800064',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '133703',
    },
    {
        id: 46,
        name: 'BMW BOOT BADGE EMBLEM PAIR 8219237',
        price: 20,
        compareAtPrice: null,
        images: ['images/products/p46/1.jpg', 'images/products/p46/2.jpg', 'images/products/p46/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8219237' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90, E46, F30, F31, F35' },
            { name: 'Placement on Vehicle', value: 'Rear' },
            { name: 'Type', value: 'Badge Emblem' },
        ],
        options: [],
        slug: 'bmw-boot-badge-emblem-pair-8219237',
        brand: 'bmw',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 47,
        name: 'BMW E60 5 SERIES 2006 BLACK CUP HOLDER',
        price: 18,
        compareAtPrice: null,
        images: ['images/products/p47/1.jpg', 'images/products/p47/2.jpg', 'images/products/p47/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60' },
            { name: 'Specification', value: '5 SERIES' },
            { name: 'Year', value: '2006' },
            { name: 'SKU', value: '131761' },
            { name: 'Type', value: 'Cup Holder' },
        ],
        options: [],
        slug: 'bmw-e60-5-series-2006-black-cup-holder',
        brand: 'bmw',
        categories: ['interior'],
        sku: '131761',
    },
    {
        id: 48,
        name: 'MERCEDES BENZ W204 W203 2007 STARTER MOTOR BOSCH 0001107540',
        price: 129,
        compareAtPrice: null,
        images: ['images/products/p48/1.jpg', 'images/products/p48/2.jpg', 'images/products/p48/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Bosch Number', value: '0001107540' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W204' },
            { name: 'Year', value: '2007' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Starter Motor' },
        ],
        options: [],
        slug: 'mercedes-benz-w204-w203-2007-starter-motor-bosch-0001107540',
        brand: 'mercedes-benz',
        categories: ['others'],
        sku: '',
    },
    {
        id: 49,
        name: 'BMW E90 320I DOOR LOCK FRONT RIGHT 7059968',
        price: 50,
        compareAtPrice: null,
        images: ['images/products/p49/1.jpg', 'images/products/p49/2.jpg', 'images/products/p49/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7059968' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90' },
            { name: 'Specification', value: '320i' },
            { name: 'Placement on Vehicle', value: 'Right Front' },
            { name: 'Type', value: 'Door Lock' },
        ],
        options: [],
        slug: 'bmw-e90-320i-door-lock-front-right-7059968',
        brand: 'bmw',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 50,
        name: 'AUDI A4 1.8T 2015 FRONT BUMPER RADIATOR GRILLE 8W0853651',
        price: 86,
        compareAtPrice: null,
        images: ['images/products/p50/1.jpg', 'images/products/p50/2.jpg', 'images/products/p50/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8W0853651' },
            { name: 'Brand', value: 'Audi' },
            { name: 'Model', value: 'A4' },
            { name: 'Specification', value: '1.8T' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '122577' },
            { name: 'Placement on Vehicle', value: 'Front' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Grille' },
        ],
        options: [],
        slug: 'audi-a4-1.8t-2015-front-bumper-radiator-grille-8w0853651',
        brand: 'audi',
        categories: ['bodykits'],
        sku: '122577',
    },
    {
        id: 51,
        name: 'MERCEDES BENZ W204 C180 2005 SIDE MIRROR GLASS FRONT LEFT A3146453',
        price: 38,
        compareAtPrice: null,
        images: ['images/products/p51/1.jpg', 'images/products/p51/2.jpg', 'images/products/p51/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A3146453' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W204' },
            { name: 'Specification', value: 'C180' },
            { name: 'Year', value: '2005' },
            { name: 'SKU', value: '140324' },
            { name: 'Placement on Vehicle', value: 'Left Front' },
            { name: 'Type', value: 'Mirror Glass' },
        ],
        options: [],
        slug: 'mercedes-benz-w204-c180-2005-side-mirror-glass-front-left-a3146453',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '140324',
    },
    {
        id: 52,
        name: 'MINI COOPER S R52 2006 POWER STEERING AUTO FAN',
        price: 68,
        compareAtPrice: null,
        images: ['images/products/p52/1.jpg', 'images/products/p52/2.jpg', 'images/products/p52/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mini Cooper' },
            { name: 'Model', value: 'R52' },
            { name: 'Year', value: '2006' },
            { name: 'SKU', value: '113268' },
            { name: 'Type', value: 'Steering Fan' },
        ],
        options: [],
        slug: 'mini-cooper-s-r52-2006-power-steering-auto-fan',
        brand: 'mini',
        categories: ['interior'],
        sku: '113268',
    },
    {
        id: 53,
        name: 'BMW 3 SERIES E36 1997 BOOT LOCK MOTOR/ACTUATOR 8361140',
        price: 20,
        compareAtPrice: null,
        images: ['images/products/p53/1.jpg', 'images/products/p53/2.jpg', 'images/products/p53/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8361140' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E36' },
            { name: 'Specification', value: '316i' },
            { name: 'Year', value: '1997' },
            { name: 'SKU', value: '127292' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Boot Lock Motror' },
        ],
        options: [],
        slug: 'bmw-3-series-e36-1997-boot-lock-motor/actuator-8361140',
        brand: 'bmw',
        categories: ['bodykits'],
        sku: '127292',
    },
    {
        id: 54,
        name: 'BMW E60 2005 GLOVE BOX FLASH TORCHLIGHT 6962052',
        price: 24,
        compareAtPrice: null,
        images: ['images/products/p54/1.jpg', 'images/products/p54/2.jpg', 'images/products/p54/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '6962052' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60' },
            { name: 'Year', value: '2005' },
            { name: 'SKU', value: '139104' },
            { name: 'Type', value: 'Torchlight' },
        ],
        options: [],
        slug: 'bmw-e60-2005-glove-box-flash-torchlight-6962052',
        brand: 'bmw',
        categories: ['others'],
        sku: '139104',
    },
    {
        id: 55,
        name: 'BMW F02 740Li 2010 M PACKAGE LIGHTED DOOR ENTRANCE SILL SET 7181011',
        price: 163,
        compareAtPrice: null,
        images: ['images/products/p55/1.jpg', 'images/products/p55/2.jpg', 'images/products/p55/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7181011' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F02' },
            { name: 'Specification', value: '740Li' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '139105' },
            { name: 'Type', value: 'Sill Set' },
        ],
        options: [],
        slug: 'bmw-f02-740li-2010-m-package-lighted-door-entrance-sill-set-7181011',
        brand: 'bmw',
        categories: ['bodykits'],
        sku: '139105',
    },
    {
        id: 56,
        name: 'MERCEDES BENZ C215 CL55 WIPER ARM FRONT A2158202244',
        price: 22,
        compareAtPrice: null,
        images: ['images/products/p56/1.jpg', 'images/products/p56/2.jpg', 'images/products/p56/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2158202244' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'C215' },
            { name: 'SKU', value: '136883' },
            { name: 'Type', value: 'Wiper Arm' },
        ],
        options: [],
        slug: 'mercedes-benz-c215-cl55-wiper-arm-front-a2158202244',
        brand: 'mercedes-benz',
        categories: ['exterior'],
        sku: '136883',
    },
    {
        id: 57,
        name: 'MERCEDES BENZ W211 E200 2007 WOODEN CONSOLE GEAR SWITCH TRIM A2115420426 13569820',
        price: 28,
        compareAtPrice: null,
        images: ['images/products/p57/1.jpg', 'images/products/p57/2.jpg', 'images/products/p57/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2115420426' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W211' },
            { name: 'Specification', value: 'E200' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '119448' },
            { name: 'Other Part Number', value: '13569820' },
            { name: 'Material', value: 'Wood' },
            { name: 'Color', value: 'Brown' },
            { name: 'Type', value: 'Wooden Trim' },
        ],
        options: [],
        slug: 'mercedes-benz-w211-e200-2007-wooden-console-gear-switch-trim-a2115420426-13569820',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '119448',
    },
    {
        id: 58,
        name: 'BMW F02 740Li N52N 2010 BRAKE BOOSTER MASTER CYLINDER 6782289',
        price: 118,
        compareAtPrice: null,
        images: ['images/products/p58/1.jpg', 'images/products/p58/2.jpg', 'images/products/p58/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '6782289' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F02' },
            { name: 'Specification', value: '740Li' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '151361' },
            { name: 'Type', value: 'Brake Booster Cylinder' },
        ],
        options: [],
        slug: 'bmw-f02-740li-n52n-2010-brake-booster-master-cylinder-6782289',
        brand: 'bmw',
        categories: ['others'],
        sku: '151361',
    },
    {
        id: 59,
        name: 'BMW E60 E66 2006 BOSCH IGNITION COIL 6-PCS 7551049 / 7594936',
        price: 103,
        compareAtPrice: null,
        images: ['images/products/p59/1.jpg', 'images/products/p59/2.jpg', 'images/products/p59/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7551049' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60' },
            { name: 'Year', value: '2006' },
            { name: 'SKU', value: '115296' },
            { name: 'Other Part Number', value: '7594936' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Ignition Coil' },
        ],
        options: [],
        slug: 'bmw-e60-e66-2006-bosch-ignition-coil-6-pcs-7551049-/-7594936',
        brand: 'bmw',
        categories: ['others'],
        sku: '115296',
    },
    {
        id: 60,
        name: 'MERCEDES BENZ W463 G63AMG 2017 BRAKE CALIPERS WITH DISC COMPLETE SET',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p60/1.jpg', 'images/products/p60/2.jpg', 'images/products/p60/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W463' },
            { name: 'Specification', value: 'G63AMG' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Brake Calipers with Disc' },
        ],
        options: [],
        slug: 'mercedes-benz-w463-g63amg-2017-brake-calipers-with-disc-complete-set',
        brand: 'mercedes-benz',
        categories: ['brakes'],
        sku: '',
    },
    {
        id: 61,
        name: 'VOLKSWAGEN GOLF 7 2014 NAVIGATION DISPLAY RADIO UNIT 5G0919606',
        price: 538,
        compareAtPrice: 751.59,
        images: ['images/products/p61/1.jpg', 'images/products/p61/2.jpg', 'images/products/p61/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '5G0919606' },
            { name: 'Brand', value: 'Volkswagen' },
            { name: 'Model', value: 'GOLF 7' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '150788' },
            { name: 'Country of Manufacture', value: 'China' },
            { name: 'Type', value: 'Radio' },
        ],
        options: [],
        slug: 'volkswagen-golf-7-2014-navigation-display-radio-unit-5g0919606',
        brand: 'volkswagen',
        categories: ['interior'],
        sku: '150788',
    },
    {
        id: 62,
        name: 'BMW F02 740Li 2010 REAR SEAT ENTERTAINMENT DVD AUDIO PLAYER 9220871',
        price: 858,
        compareAtPrice: 1198.63,
        images: ['images/products/p62/1.jpg', 'images/products/p62/2.jpg', 'images/products/p62/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9220871' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F02' },
            { name: 'Specification', value: '740Li' },
            { name: 'Year', value: '2010' },
            { name: 'SKU', value: '142854' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'DVD Audio Player' },
        ],
        options: [],
        slug: 'bmw-f02-740li-2010-rear-seat-entertainment-dvd-audio-player-9220871',
        brand: 'bmw',
        categories: ['interior'],
        sku: '142854',
    },
    {
        id: 63,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 TAIL LAMP RIGHT & LEFT',
        price: 401.25,
        compareAtPrice: 535,
        images: ['images/products/p63/1.jpg', 'images/products/p63/2.jpg', 'images/products/p63/3.jpg', 'images/products/p63/4.jpg', 'images/products/p63/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Tail Lamp Right & Left' },
        ],
        options: [],
        slug: 'mercedes-benz-w292-gle400-4matic-2017-tail-lamp-right-&-left',
        brand: 'mercedes-benz',
        categories: ['electrical'],
        sku: '',
    },
    {
        id: 64,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 XENON HID HEADLAMPS RIGHT & LEFT',
        price: 1875,
        compareAtPrice: 2500,
        images: ['images/products/p64/1.jpg', 'images/products/p64/2.jpg', 'images/products/p64/3.jpg', 'images/products/p64/4.jpg', 'images/products/p64/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2518206861 / A2518206761' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Type', value: 'Headlamp Right & Left' },
        ],
        options: [],
        slug: 'mercedes-benz-w251-r400-4matic-2013-xenon-hid-headlamps-right-&-left',
        brand: 'mercedes-benz',
        categories: ['electrical', 'lights'],
        sku: '',
    },
    {
        id: 65,
        name: 'MERCEDES BENZ W204 C CLASS 2007 FRONT INTERIOR ROOF LAMP LIGHT A2048202001',
        price: 70,
        compareAtPrice: 97.79,
        images: ['images/products/p65/1.jpg', 'images/products/p65/2.jpg', 'images/products/p65/3.jpg', 'images/products/p65/4.jpg', 'images/products/p65/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A2048202001' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W204' },
            { name: 'Specification', value: 'C CLASS' },
            { name: 'Year', value: '2007' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Roof Lamp' },
        ],
        options: [],
        slug: 'mercedes-benz-w204-c-class-2007-front-interior-roof-lamp-light-a2048202001',
        brand: 'mercedes-benz',
        categories: ['electrical'],
        sku: '',
    },
    {
        id: 66,
        name: 'BMW E66 730LI N52 2006 ABS DSC COMPRESSOR PUMP 34511166155 / 0265410054',
        price: 30,
        compareAtPrice: 41.91,
        images: ['images/products/p66/1.jpg', 'images/products/p66/2.jpg', 'images/products/p66/3.jpg', 'images/products/p66/4.jpg', 'images/products/p66/5.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '34511166155' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E66' },
            { name: 'Specification', value: '730LI' },
            { name: 'Year', value: '2006' },
            { name: 'Other Part Number', value: '0265410054' },
            { name: 'SKU', value: '114498' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'ABS DSC Compressor Pump' },
        ],
        options: [],
        slug: 'bmw-e66-730li-n52-2006-abs-dsc-compressor-pump-34511166155-/-0265410054',
        brand: 'bmw',
        categories: ['others'],
        sku: '114498',
    },
    {
        id: 67,
        name: 'JAGUAR XJ 3.0L 2011 GLOW PLUG RELAY CONTROL MODULE 0281003048 9X2Q12B533BB',
        price: 27,
        compareAtPrice: 37.72,
        images: ['images/products/p67/1.jpg', 'images/products/p67/2.jpg', 'images/products/p67/3.jpg', 'images/products/p67/4.jpg', 'images/products/p67/5.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9X2Q12B533BB' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '0281003048' },
            { name: 'SKU', value: '144586' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Glow Plug' },
        ],
        options: [],
        slug: 'jaguar-xj-3.0l-2011-glow-plug-relay-control-module-0281003048-9x2q12b533bb',
        brand: 'jaguar',
        categories: ['others', 'modules'],
        sku: '144586',
    },
    {
        id: 68,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 STEERING WHEEL WITH AIRBAG',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p68/1.jpg', 'images/products/p68/2.jpg', 'images/products/p68/3.jpg', 'images/products/p68/4.jpg', 'images/products/p68/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Steering Wheel' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-steering-wheel-with-airbag',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 69,
        name: 'JAGUAR X TYPE 2.0 SE 2004 HEADLIGHT SWITCH CONTROL UNIT 4X4311654AA',
        price: 30,
        compareAtPrice: 41.91,
        images: ['images/products/p69/1.jpg', 'images/products/p69/2.jpg', 'images/products/p69/3.jpg', 'images/products/p69/4.jpg', 'images/products/p69/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '4X4311654AA' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'X TYPE' },
            { name: 'Specification', value: '2.0 SE' },
            { name: 'Year', value: '2004' },
            { name: 'SKU', value: '105074' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Headlight Switch' },
        ],
        options: [],
        slug: 'jaguar-x-type-2.0-se-2004-headlight-switch-control-unit-4x4311654aa',
        brand: 'jaguar',
        categories: ['interior'],
        sku: '105074',
    },
    {
        id: 70,
        name: 'BMW F10 5 SERIES 2012 FRESH AIR GRILLE DASHBOARD AC VENT LEFT 9166887',
        price: 36,
        compareAtPrice: 50.29,
        images: ['images/products/p70/1.jpg', 'images/products/p70/2.jpg', 'images/products/p70/3.jpg', 'images/products/p70/4.jpg', 'images/products/p70/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '9166887' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F10' },
            { name: 'Specification', value: '520i' },
            { name: 'Year', value: '2012' },
            { name: 'Other Part Number', value: '9166893 / 07615001' },
            { name: 'SKU', value: '162480' },
            { name: 'Country of Manufacture', value: 'Poland' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Dashboard AC Vent Left' },
            { name: 'Placement on Vehicle', value: 'Left' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
        slug: 'bmw-f10-5-series-2012-fresh-air-grille-dashboard-ac-vent-left-9166887',
        brand: 'bmw',
        categories: ['interior'],
        sku: '162480',
    },
    {
        id: 71,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 INSTRUMENT CLUSTER SPEEDOMETER 268077',
        price: 1976,
        compareAtPrice: 2758.99,
        images: ['images/products/p71/1.jpg', 'images/products/p71/2.jpg', 'images/products/p71/3.jpg', 'images/products/p71/4.jpg', 'images/products/p71/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '268077' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Display Type', value: 'Analogue & Digital' },
            { name: 'Type', value: 'Speedometer' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-instrument-cluster-speedometer-268077',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 72,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 GAS ACCELERATOR PEDAL',
        price: 174,
        compareAtPrice: 242.95,
        images: ['images/products/p72/1.jpg', 'images/products/p72/2.jpg', 'images/products/p72/3.jpg', 'images/products/p72/4.jpg', 'images/products/p72/5.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Accelerator' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-gas-accelerator-pedal',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 73,
        name: 'JAGUAR XF DOOR HANDLE CHROME FRONT RIGHT 8X2322600AE',
        price: 64,
        compareAtPrice: 89.36,
        images: ['images/products/p73/1.jpg', 'images/products/p73/2.jpg', 'images/products/p73/3.jpg', 'images/products/p73/4.jpg', 'images/products/p73/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8X2322600AE' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XF' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Door Handle Front Right' },
            { name: 'Placement on Vehicle', value: 'Front, Right Side' },
        ],
        options: [],
        slug: 'jaguar-xf-door-handle-chrome-front-right-8x2322600ae',
        brand: 'jaguar',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 74,
        name: 'AUDI A8 3.0 TFSI 2011 INTERIOR CENTER REAR VIEW MIRROR 8R0857511B',
        price: 88,
        compareAtPrice: 122.87,
        images: ['images/products/p74/1.jpg', 'images/products/p74/2.jpg', 'images/products/p74/3.jpg', 'images/products/p74/4.jpg', 'images/products/p74/5.jpg', 'images/products/p74/6.jpg', 'images/products/p74/7.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '8R0857511B' },
            { name: 'Brand', value: 'AUDI' },
            { name: 'Model', value: 'A8' },
            { name: 'Specification', value: '3.0 TFSI' },
            { name: 'Year', value: '2011' },
            { name: 'SKU', value: '141484' },
            { name: 'Type', value: 'Center Rear View Mirror' },
        ],
        options: [],
        slug: 'audi-a8-3.0-tfsi-2011-interior-center-rear-view-mirror-8r0857511b',
        brand: 'audi',
        categories: ['interior'],
        sku: '141484',
    },
    {
        id: 75,
        name: 'VOLVO V40 D2 2014 GEAR KNOB',
        price: 120,
        compareAtPrice: 167.55,
        images: ['images/products/p75/1.jpg', 'images/products/p75/2.jpg', 'images/products/p75/3.jpg', 'images/products/p75/4.jpg', 'images/products/p75/5.jpg', 'images/products/p75/6.jpg', 'images/products/p75/7.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Volvo' },
            { name: 'Model', value: 'V40' },
            { name: 'Specification', value: 'D2' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '145838' },
            { name: 'Type', value: 'Gear Knob' },
        ],
        options: [],
        slug: 'volvo-v40-d2-2014-gear-knob',
        brand: 'volvo',
        categories: ['interior'],
        sku: '145838',
    },
    {
        id: 76,
        name: 'MINI COOPER D F56 2014 SUN VISOR PAIR RIGHT & LEFT 7366950 / 7382101',
        price: 278,
        compareAtPrice: 388.16,
        images: ['images/products/p76/1.jpg', 'images/products/p76/2.jpg', 'images/products/p76/3.jpg', 'images/products/p76/4.jpg', 'images/products/p76/5.jpg', 'images/products/p76/6.jpg', 'images/products/p76/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7366950 / 7382101' },
            { name: 'Brand', value: 'Mini Cooper' },
            { name: 'Model', value: 'F56' },
            { name: 'Year', value: '2014' },
            { name: 'SKU', value: '145669' },
            { name: 'Type', value: 'Sun Visor' },
            { name: 'Placement on Vehicle', value: 'Left, Right, Front' },
            { name: 'Featuers', value: 'Lights, Mirror' },
        ],
        options: [],
        slug: 'mini-cooper-d-f56-2014-sun-visor-pair-right-&-left-7366950-/-7382101',
        brand: 'mini',
        categories: ['interior'],
        sku: '145669',
    },
    {
        id: 77,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 CENTER ARMREST TUNNEL CONSOLE 80581500',
        price: 4985,
        compareAtPrice: 6960.31,
        images: ['images/products/p77/1.jpg', 'images/products/p77/2.jpg', 'images/products/p77/3.jpg', 'images/products/p77/4.jpg', 'images/products/p77/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '80581500' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Center Console' },
            { name: 'Placement on Vehicle', value: 'Center' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-center-armrest-tunnel-console-80581500',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 78,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 STEERING COLUMN COVER 69766000 82001800',
        price: 229,
        compareAtPrice: 319.74,
        images: ['images/products/p78/1.jpg', 'images/products/p78/2.jpg', 'images/products/p78/3.jpg', 'images/products/p78/4.jpg', 'images/products/p78/5.jpg', 'images/products/p78/6.jpg', 'images/products/p78/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '69766000' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '82001800' },
            { name: 'Type', value: 'Steering Column Cover' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-steering-column-cover-69766000-82001800',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 79,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 STEERING ANGLE SENSOR 261073 0265005499',
        price: 432,
        compareAtPrice: 603.18,
        images: ['images/products/p79/1.jpg', 'images/products/p79/2.jpg', 'images/products/p79/3.jpg', 'images/products/p79/4.jpg', 'images/products/p79/5.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '261073' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '0265005499' },
            { name: 'Country of Manufacture', value: 'Australia' },
            { name: 'Type', value: 'Steering Angle Sensor' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-steering-angle-sensor-261073-0265005499',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 80,
        name: 'BMW F10 523i N52N 2.5AT 2011 FUEL INJECTOR 7542541',
        price: 300,
        compareAtPrice: 418.88,
        images: ['images/products/p80/1.jpg', 'images/products/p80/2.jpg', 'images/products/p80/3.jpg', 'images/products/p80/4.jpg', 'images/products/p80/5.jpg', 'images/products/p80/6.jpg', 'images/products/p80/7.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7542541' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F10' },
            { name: 'Specification', value: '523i' },
            { name: 'Year', value: '2011' },
            { name: 'Other Part Number', value: '7542070' },
            { name: 'SKU', value: '149699' },
            { name: 'Type', value: 'Fuel Injector' },
        ],
        options: [],
        slug: 'bmw-f10-523i-n52n-2.5at-2011-fuel-injector-7542541',
        brand: 'bmw',
        categories: ['others'],
        sku: '149699',
    },
    {
        id: 81,
        name: 'BMW X3 F25 XDRIVE28iX N20 2012 DENSO ALTERNATOR 7605061',
        price: 205,
        compareAtPrice: 286.23,
        images: ['images/products/p81/1.jpg', 'images/products/p81/2.jpg', 'images/products/p81/3.jpg', 'images/products/p81/4.jpg', 'images/products/p81/5.jpg', 'images/products/p81/6.jpg', 'images/products/p81/7.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7605061' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'X3' },
            { name: 'Year', value: '2012' },
            { name: 'SKU', value: '151385' },
            { name: 'Country of Manufacture', value: 'Japan' },
            { name: 'Type', value: 'Alternator' },
        ],
        options: [],
        slug: 'bmw-x3-f25-xdrive28ix-n20-2012-denso-alternator-7605061',
        brand: 'bmw',
        categories: ['others'],
        sku: '151385',
    },
    {
        id: 82,
        name: 'MERCEDES BENZ W124 E CLASS STARTER MOTOR BOSCH 0001107403',
        price: 69,
        compareAtPrice: 96.34,
        images: ['images/products/p82/1.jpg', 'images/products/p82/2.jpg', 'images/products/p82/3.jpg', 'images/products/p82/4.jpg', 'images/products/p82/5.jpg', 'images/products/p82/6.jpg', 'images/products/p82/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '0001107403' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W124' },
            { name: 'Specification', value: 'E CLASS' },
            { name: 'Year', value: '1987' },
            { name: 'Country of Manufacture', value: 'Hungary' },
            { name: 'Type', value: 'Starter Motor' },
        ],
        options: [],
        slug: 'mercedes-benz-w124-e-class-starter-motor-bosch-0001107403',
        brand: 'mercedes-benz',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 83,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 SEATS',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p83/1.jpg', 'images/products/p83/2.jpg', 'images/products/p83/3.jpg', 'images/products/p83/4.jpg', 'images/products/p83/5.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: 'Seats' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-seats',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 84,
        name: 'MERCEDES BENZ W169 WIPER MOTOR REAR A1698201340',
        price: 89,
        compareAtPrice: 124.27,
        images: ['images/products/p84/1.jpg', 'images/products/p84/2.jpg', 'images/products/p84/3.jpg', 'images/products/p84/4.jpg', 'images/products/p84/5.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: 'A1698201340' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W169' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '139616' },
            { name: 'Country of Manufacture', value: 'France' },
            { name: 'Type', value: 'Wiper Motor' },
        ],
        options: [],
        slug: 'mercedes-benz-w169-wiper-motor-rear-a1698201340',
        brand: 'mercedes-benz',
        categories: ['others'],
        sku: '139616',
    },
    {
        id: 85,
        name: 'BMW E90 REAR REFLECTOR RIGHT',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p85/1.jpg', 'images/products/p85/2.jpg', 'images/products/p85/3.jpg', 'images/products/p85/4.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E90' },
            { name: 'Year', value: '2005' },
            { name: 'SKU', value: '120257' },
            { name: 'Type', value: 'Reflector' },
            { name: 'Placement on Vehicle', value: 'Rear, Right' },
        ],
        options: [],
        slug: 'bmw-e90-rear-reflector-right',
        brand: 'bmw',
        categories: ['others'],
        sku: '120257',
    },
    {
        id: 86,
        name: 'MERCEDES BENZ W203 W209 FOG LAMP PAIR 2038201256 2038201156',
        price: 38,
        compareAtPrice: 53.06,
        images: ['images/products/p86/1.jpg', 'images/products/p86/2.jpg', 'images/products/p86/3.jpg', 'images/products/p86/4.jpg', 'images/products/p86/5.jpg', 'images/products/p86/6.jpg', 'images/products/p86/7.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '2038201256 / 2038201156' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W203' },
            { name: 'SKU', value: '114259, 114258' },
            { name: 'Type', value: 'Fog Lamps' },
        ],
        options: [],
        slug: 'mercedes-benz-w203-w209-fog-lamp-pair-2038201256-2038201156',
        brand: 'mercedes-benz',
        categories: ['electrical'],
        sku: '114259, 114258',
    },
    {
        id: 87,
        name: 'MERCEDES BENZ W124 W201 FUEL DISTRIBUTOR 0438101012',
        price: 480,
        compareAtPrice: 670.20,
        images: ['images/products/p87/1.jpg', 'images/products/p87/2.jpg', 'images/products/p87/3.jpg', 'images/products/p87/4.jpg', 'images/products/p87/5.jpg', 'images/products/p87/6.jpg', 'images/products/p87/7.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '0438101012' },
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W124' },
            { name: 'SKU', value: '111308' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Fuel Distributor' },
        ],
        options: [],
        slug: 'mercedes-benz-w124-w201-fuel-distributor-0438101012',
        brand: 'mercedes-benz',
        categories: ['others'],
        sku: '111308',
    },
    {
        id: 88,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 AC HEATER CLIMATE CONTROL PANEL 000267691',
        price: 941,
        compareAtPrice: 1313.87,
        images: ['images/products/p88/1.jpg', 'images/products/p88/2.jpg', 'images/products/p88/3.jpg', 'images/products/p88/4.jpg', 'images/products/p88/5.jpg', 'images/products/p88/6.jpg', 'images/products/p88/7.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '000267691' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Mexico' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'AC Control Panel' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-ac-heater-climate-control-panel-000267691',
        brand: 'ferrari',
        categories: ['interior'],
        sku: '',
    },
    {
        id: 89,
        name: 'PORSCHE 911S CARRERA 997 2007 HANDBRAKE ASSEMBLY BLACK LEATHER 99742403162',
        price: 242,
        compareAtPrice: 337.14,
        images: ['images/products/p89/1.jpg', 'images/products/p89/2.jpg', 'images/products/p89/3.jpg', 'images/products/p89/4.jpg', 'images/products/p89/5.jpg', 'images/products/p89/6.jpg', 'images/products/p89/7.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '99742403162' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911S' },
            { name: 'Specification', value: 'CARRERA 997' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '146818' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Type', value: 'Handbrake' },
        ],
        options: [],
        slug: 'porsche-911s-carrera-997-2007-handbrake-assembly-black-leather-99742403162',
        brand: 'porsche',
        categories: ['interior'],
        sku: '146818',
    },
    {
        id: 90,
        name: 'FERRARI CALIFORNIA 4.3L AT 2011 20INCH WHEELS',
        price: null,
        compareAtPrice: null,
        images: ['images/products/p90/1.jpg', 'images/products/p90/2.jpg', 'images/products/p90/3.jpg', 'images/products/p90/4.jpg', 'images/products/p90/5.jpg', 'images/products/p90/6.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '10JX20EH2' },
            { name: 'Brand', value: 'Ferrari' },
            { name: 'Model', value: 'California' },
            { name: 'Specification', value: '4.3L AT' },
            { name: 'Year', value: '2011' },
            { name: 'Type', value: '20Inch Wheels' },
        ],
        options: [],
        slug: 'ferrari-california-4.3l-at-2011-20inch-wheels',
        brand: 'ferrari',
        categories: ['wheels'],
        sku: '',
    },
    {
        id: 91,
        name: 'PORSCHE MACAN S 3.0 2015 AUDIO RADIO MUSIC AMP AMPLIFIER 95B035223',
        price: 305,
        compareAtPrice: 424.91,
        images: ['images/products/p91/1.jpg', 'images/products/p91/2.jpg', 'images/products/p91/3.jpg', 'images/products/p91/4.jpg', 'images/products/p91/5.jpg', 'images/products/p91/6.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '95B035223' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'MACAN S' },
            { name: 'Specification', value: '3.0' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '164796' },
            { name: 'Country of Manufacture', value: 'Romania' },
            { name: 'Type', value: 'Amplifier' },
            { name: 'Features', value: 'Radio' },
        ],
        options: [],
        slug: 'porsche-macan-s-3.0-2015-audio-radio-music-amp-amplifier-95b035223',
        brand: 'porsche',
        categories: ['interior'],
        sku: '164796',
    },
    {
        id: 92,
        name: 'BMW E66 730LI N62 2004 FUSE BOX SAM INTEGRATED POWER SUPPLY MODULE 7510638',
        price: 29,
        compareAtPrice: 40.40,
        images: ['images/products/p92/1.jpg', 'images/products/p92/2.jpg', 'images/products/p92/3.jpg', 'images/products/p92/4.jpg', 'images/products/p92/5.jpg', 'images/products/p92/6.jpg', 'images/products/p92/7.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Part Number', value: '7510638' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E66' },
            { name: 'Specification', value: '735LI' },
            { name: 'Year', value: '2004' },
            { name: 'SKU', value: '122011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Engine', value: 'N62' },
            { name: 'Type', value: 'Module' },
        ],
        options: [],
        slug: 'bmw-e66-730li-n62-2004-fuse-box-sam-integrated-power-supply-module-7510638',
        brand: 'bmw',
        categories: ['others', 'modules'],
        sku: '122011',
    },
    {
        id: 93,
        name: 'MERCEDES BENZ W207 E200 2014 FENDERS RIGHT & LEFT',
        price: 585,
        compareAtPrice: 780,
        images: ['images/products/p93/1.jpg', 'images/products/p93/2.jpg', 'images/products/p93/3.jpg', 'images/products/p93/4.jpg', 'images/products/p93/5.jpg', 'images/products/p93/6.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W207' },
            { name: 'Specification', value: 'E200' },
            { name: 'Year', value: '2014' },
            { name: 'Type', value: 'Fenders Right & Left' },
        ],
        options: [],
        slug: 'mercedes-benz-w207-e200-2014-fenders-right-&-left',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 94,
        name: 'MERCEDES BENZ W207 E200 2014 SIDE SKIRTS RIGHT & LEFT',
        price: 360,
        compareAtPrice: 480,
        images: ['images/products/p94/1.jpg', 'images/products/p94/2.jpg', 'images/products/p94/3.jpg', 'images/products/p94/4.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W207' },
            { name: 'Specification', value: 'E200' },
            { name: 'Year', value: '2014' },
            { name: 'Type', value: 'Side Skirts Right & Left' },
        ],
        options: [],
        slug: 'mercedes-benz-w207-e200-2014-side-skirts-right-&-left',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 95,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 REAR BUMPER',
        price: 900,
        compareAtPrice: 1200,
        images: ['images/products/p95/1.jpg', 'images/products/p95/2.jpg', 'images/products/p95/3.jpg', 'images/products/p95/4.jpg', 'images/products/p95/5.jpg', 'images/products/p95/6.jpg', 'images/products/p95/7.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Rear Bumper' },
        ],
        options: [],
        slug: 'mercedes-benz-w292-gle400-4matic-2017-rear-bumper',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 96,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 REAR BUMPER SUPPORT',
        price: 262.5,
        compareAtPrice: 350,
        images: ['images/products/p96/1.jpg', 'images/products/p96/2.jpg', 'images/products/p96/3.jpg', 'images/products/p96/4.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Type', value: 'Rear Bumper Support' },
        ],
        options: [],
        slug: 'mercedes-benz-w251-r400-4matic-2013-rear-bumper-support',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '',
    },
    {
        id: 97,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 BOOT LID DOOR WITH REAR WINDOW',
        price: 1200,
        compareAtPrice: 1600,
        images: ['images/products/p97/1.jpg', 'images/products/p97/2.jpg', 'images/products/p97/3.jpg', 'images/products/p97/4.jpg', 'images/products/p97/5.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        attributes: [
            { name: 'Brand', value: 'Mercedes Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Type', value: 'Boot Lid' },
        ],
        options: [],
        slug: 'mercedes-benz-w251-r400-4matic-2013-boot-lid-door-with-rear-window',
        brand: 'mercedes-benz',
        categories: ['bodykits'],
        sku: '888888',
    },
    {
        id: '133665', name: 'BMW F10 523i 2010 STEERING RACK MOTOR ONLY', price: '294.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-f10-523i-2010-steering-rack-motor-only', brand: 'bmw', categories: ['others'], sku: '133665', attributes: [{ name: 'Sku', value: '133665' }, { name: 'Stock No', value: '06478' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '523i' }, { name: 'Year', value: '2010' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N52N' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '164117', name: 'MERCEDES BENZ W205 C180 2015 PARKING BRAKE SWITCH', price: '26.2500', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w205-c180-2015-parking-brake-switch', brand: 'mercedes-benz', categories: ['others'], sku: '164117', attributes: [{ name: 'Sku', value: '164117' }, { name: 'Stock No', value: '07712' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C180' }, { name: 'Year', value: '2015' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M274 910' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '115583', name: 'JAGUAR XF 30 2008 A - PILLAR COVERINGS', price: '724.6000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'jaguar-xf-30-2008-a-pillar-coverings', brand: 'jaguar', categories: ['others'], sku: '115583', attributes: [{ name: 'Sku', value: '115583' }, { name: 'Stock No', value: '06028' }, { name: 'Model', value: 'JAGUAR' }, { name: 'Spec', value: '3.0' }, { name: 'Year', value: '2008' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: '7FC' }], options: [], Sku: '115583', OriginalPrice: '0.0000', NewPrice: '50.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '115710', name: 'BMW E82 1 120i 2011 A - PILLAR COVERINGS', price: '130.8600', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e82-1-120i-2011-a-pillar-coverings', brand: 'bmw', categories: ['others'], sku: '115710', attributes: [{ name: 'Sku', value: '115710' }, { name: 'Stock No', value: '06162' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '120i' }, { name: 'Year', value: '2011' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N46N' }], options: [], Sku: '115710', OriginalPrice: '0.0000', NewPrice: '30.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '152704', name: 'BMW E90 3 325i 2000 A PILLAR COVER RL', price: '5.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e90-3-325i-2000-a-pillar-cover-rl', brand: 'bmw', categories: ['others'], sku: '152704', attributes: [{ name: 'Sku', value: '152704' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '325i' }, { name: 'Year', value: '2000' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N52' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '171025', name: 'MERCEDES BENZ W205 C180 2016 AC CONTROL MODULE', price: '62.5000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w205-c180-2016-ac-control-module', brand: 'mercedes-benz', categories: ['others'], sku: '171025', attributes: [{ name: 'Sku', value: '171025' }, { name: 'Stock No', value: '07899' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C180' }, { name: 'Year', value: '2016' }, { name: 'Part Number', value: 'A2059006923' }, { name: 'Engine Code', value: 'M274 910' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '135944', name: 'AUDI A4 20 SE 2000 ABS CONTROL UNIT', price: '5.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'audi-a4-20-se-2000-abs-control-unit', brand: 'audi', categories: ['others'], sku: '135944', attributes: [{ name: 'Sku', value: '135944' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'AUDI' }, { name: 'Spec', value: '2.0-SE' }, { name: 'Year', value: '2000' }, { name: 'Part Number', value: '4D0907379D' }, { name: 'Engine Code', value: 'ALT' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '110944', name: 'MERCEDES BENZ W168 A160 2002 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'mercedes-benz-w168-a160-2002-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110944', attributes: [{ name: 'Sku', value: '110944' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A160' }, { name: 'Year', value: '2002' }, { name: 'Part Number', value: '0034317412' }, { name: 'Engine Code', value: 'M166 960' }], options: [], Sku: '110944', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '103809', name: 'VOLVO S80 25T 2009 ABS PUMP', price: '150.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s80-25t-2009-abs-pump', brand: 'volvo', categories: ['others'], sku: '103809', attributes: [{ name: 'Sku', value: '103809' }, { name: 'Stock No', value: '05592' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5T' }, { name: 'Year', value: '2009' }, { name: 'Part Number', value: '30681619' }, { name: 'Engine Code', value: 'B5256' }], options: [], InvoiceDate: '14/10/2015', Sku: '103809', Qty: '1.000', Price: '500.0000', OriginalPrice: '0.0000', NewPrice: '780.0000',
    }, {
        id: '110940', name: 'MERCEDES BENZ W210 E200 2001 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'mercedes-benz-w210-e200-2001-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110940', attributes: [{ name: 'Sku', value: '110940' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'E200' }, { name: 'Year', value: '2001' }, { name: 'Part Number', value: '0044314512' }, { name: 'Engine Code', value: 'M111 942' }], options: [], Sku: '110940', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '103032', name: 'VOLVO XC 90 25 T 2005 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-xc-90-25-t-2005-abs-pump', brand: 'volvo', categories: ['others'], sku: '103032', attributes: [{ name: 'Sku', value: '103032' }, { name: 'Stock No', value: '05564' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5-T' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '30643982' }, { name: 'Engine Code', value: 'B5254' }], options: [], Sku: '103032', OriginalPrice: '0.0000', NewPrice: '480.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '113529', name: 'BMW E90 3 323i 2006 ABS PUMP', price: '29.4000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e90-3-323i-2006-abs-pump', brand: 'bmw', categories: ['others'], sku: '113529', attributes: [{ name: 'Sku', value: '113529' }, { name: 'Stock No', value: '06080' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '323i' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N52' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '102321', name: 'PORSCHE BOXSTER S 987 2005 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'porsche-boxster-s-987-2005-abs-pump', brand: 'porsche', categories: ['others'], sku: '102321', attributes: [{ name: 'Sku', value: '102321' }, { name: 'Stock No', value: '05286' }, { name: 'Model', value: 'PORSCHE' }, { name: 'Spec', value: '987' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '98735575504' }, { name: 'Engine Code', value: '3.2' }], options: [], Sku: '102321', OriginalPrice: '0.0000', NewPrice: '850.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110981', name: 'BMW E60 5 530i 2004 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e60-5-530i-2004-abs-pump', brand: 'bmw', categories: ['others'], sku: '110981', attributes: [{ name: 'Sku', value: '110981' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '530i' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '6767235' }, { name: 'Engine Code', value: 'N52' }], options: [], Sku: '110981', OriginalPrice: '0.0000', NewPrice: '280.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '103330', name: 'BMW E46 3 320ci 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e46-3-320ci-2004-abs-pump', brand: 'bmw', categories: ['others'], sku: '103330', attributes: [{ name: 'Sku', value: '103330' }, { name: 'Stock No', value: '05571' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '320ci' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M54' }], options: [], Sku: '103330', OriginalPrice: '0.0000', NewPrice: '320.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '108909', name: 'BMW Z4 E85 Z4 25i 2004 ABS PUMP', price: '42.5500', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-z4-e85-z4-25i-2004-abs-pump', brand: 'bmw', categories: ['others'], sku: '108909', attributes: [{ name: 'Sku', value: '108909' }, { name: 'Stock No', value: '05491' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: 'Z4-2.5i' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M54' }], options: [], Sku: '108909', OriginalPrice: '350.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '111694', name: 'BMW E90 3 320i 2005 ABS PUMP', price: '25.6800', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e90-3-320i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '111694', attributes: [{ name: 'Sku', value: '111694' }, { name: 'Stock No', value: '05968' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '320i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N46' }], options: [], Sku: '111694', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110926', name: 'MERCEDES BENZ W202 C36 AMG 1995 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w202-c36-amg-1995-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110926', attributes: [{ name: 'Sku', value: '110926' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C36-AMG' }, { name: 'Year', value: '1995' }, { name: 'Part Number', value: '0034310312' }, { name: 'Engine Code', value: 'M104 941' }], options: [], Sku: '110926', OriginalPrice: '0.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114120', name: 'MERCEDES BENZ W203 C200 KOMP 2006 ABS PUMP', price: '10.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w203-c200-komp-2006-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '114120', attributes: [{ name: 'Sku', value: '114120' }, { name: 'Stock No', value: '06117' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C200-KOMP' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: 'A0365454132' }, { name: 'Engine Code', value: 'M271 940' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '106283', name: 'BMW X1 E84 X1 18i 2010 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-x1-e84-x1-18i-2010-abs-pump', brand: 'bmw', categories: ['others'], sku: '106283', attributes: [{ name: 'Sku', value: '106283' }, { name: 'Stock No', value: '05173' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: 'X1-18i' }, { name: 'Year', value: '2010' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N46N' }], options: [], Sku: '106283', OriginalPrice: '0.0000', NewPrice: '800.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '105591', name: 'BMW E60 5 523i 2005 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e60-5-523i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '105591', attributes: [{ name: 'Sku', value: '105591' }, { name: 'Stock No', value: '05644' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '523i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '34516758743' }, { name: 'Engine Code', value: 'N52' }], options: [], Sku: '105591', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '111082', name: 'BMW E46 3 M3 2003 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e46-3-m3-2003-abs-pump', brand: 'bmw', categories: ['others'], sku: '111082', attributes: [{ name: 'Sku', value: '111082' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: 'M3' }, { name: 'Year', value: '2003' }, { name: 'Part Number', value: '2282249' }, { name: 'Engine Code', value: 'S54' }], options: [], Sku: '111082', OriginalPrice: '0.0000', NewPrice: '800.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112589', name: 'MERCEDES BENZ W245 B200 2005 ABS PUMP', price: '34.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w245-b200-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '112589', attributes: [{ name: 'Sku', value: '112589' }, { name: 'Stock No', value: '05917' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'B200' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '0054319512' }, { name: 'Engine Code', value: 'M266 960' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '103783', name: 'MERCEDES BENZ W203 C180 2004 ABS PUMP', price: '50.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'mercedes-benz-w203-c180-2004-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '103783', attributes: [{ name: 'Sku', value: '103783' }, { name: 'Stock No', value: '05587' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C180' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: 'A0345457732' }, { name: 'Engine Code', value: '271 946' }], options: [], Sku: '103783', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112981', name: 'MINI R52 COOPER S 2006 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mini-r52-cooper-s-2006-abs-pump', brand: 'mini', categories: ['others'], sku: '112981', attributes: [{ name: 'Sku', value: '112981' }, { name: 'Stock No', value: '06058' }, { name: 'Model', value: 'MINI' }, { name: 'Spec', value: 'COOPER-S' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '6765288' }, { name: 'Engine Code', value: 'W11' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '110942', name: 'MERCEDES BENZ W210 E200 1997 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w210-e200-1997-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110942', attributes: [{ name: 'Sku', value: '110942' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'E200' }, { name: 'Year', value: '1997' }, { name: 'Part Number', value: '0034319712' }, { name: 'Engine Code', value: 'M111 942' }], options: [], Sku: '110942', OriginalPrice: '250.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '111080', name: 'BMW E46 3 318i 2001 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'bmw-e46-3-318i-2001-abs-pump', brand: 'bmw', categories: ['others'], sku: '111080', attributes: [{ name: 'Sku', value: '111080' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '318i' }, { name: 'Year', value: '2001' }, { name: 'Part Number', value: '6762868' }, { name: 'Engine Code', value: 'N42' }], options: [], Sku: '111080', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114703', name: 'MERCEDES BENZ W169 A170 2006 ABS PUMP', price: '16.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w169-a170-2006-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '114703', attributes: [{ name: 'Sku', value: '114703' }, { name: 'Stock No', value: '06131' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A170' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '0054319512' }, { name: 'Engine Code', value: 'M266 940' }], options: [], Sku: '114703', OriginalPrice: '0.0000', NewPrice: '250.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '103901', name: 'VOLKSWAGEN GOLF 7 14 TSI 2014 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volkswagen-golf-7-14-tsi-2014-abs-pump', brand: 'volkswagen', categories: ['others'], sku: '103901', attributes: [{ name: 'Sku', value: '103901' }, { name: 'Stock No', value: '05595' }, { name: 'Model', value: 'VOLKSWAGEN' }, { name: 'Spec', value: '1.4-TSI' }, { name: 'Year', value: '2014' }, { name: 'Part Number', value: '5Q0907379L' }, { name: 'Engine Code', value: 'CXS' }], options: [], Sku: '103901', OriginalPrice: '0.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112598', name: 'VOLVO S70V70 20 T 2005 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s70v70-20-t-2005-abs-pump', brand: 'volvo', categories: ['others'], sku: '112598', attributes: [{ name: 'Sku', value: '112598' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.0-T' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '9467581' }, { name: 'Engine Code', value: 'B5204 T2' }], options: [], Sku: '112598', OriginalPrice: '0.0000', NewPrice: '600.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '106288', name: 'PORSCHE 911 997 2006 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'porsche-911-997-2006-abs-pump', brand: 'porsche', categories: ['others'], sku: '106288', attributes: [{ name: 'Sku', value: '106288' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'PORSCHE' }, { name: 'Spec', value: '997' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '0265234088' }, { name: 'Engine Code', value: 'C2' }], options: [], Sku: '106288', OriginalPrice: '0.0000', NewPrice: '950.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '113705', name: 'PORSCHE BOXSTER 987 2008 ABS PUMP', price: '106.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'porsche-boxster-987-2008-abs-pump', brand: 'porsche', categories: ['others'], sku: '113705', attributes: [{ name: 'Sku', value: '113705' }, { name: 'Stock No', value: '06086' }, { name: 'Model', value: 'PORSCHE' }, { name: 'Spec', value: '987' }, { name: 'Year', value: '2008' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: '2.7' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '114253', name: 'MERCEDES BENZ W210 E200 1999 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w210-e200-1999-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '114253', attributes: [{ name: 'Sku', value: '114253' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'E200' }, { name: 'Year', value: '1999' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M111 942' }], options: [], Sku: '114253', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '107831', name: 'JAGUAR XJ 50L 2011 ABS PUMP', price: '156.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'jaguar-xj-50l-2011-abs-pump', brand: 'jaguar', categories: ['others'], sku: '107831', attributes: [{ name: 'Sku', value: '107831' }, { name: 'Stock No', value: '05773' }, { name: 'Model', value: 'JAGUAR' }, { name: 'Spec', value: '5.0L' }, { name: 'Year', value: '2011' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: '392508PN' }], options: [], Sku: '107831', OriginalPrice: '1200.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '102877', name: 'MERCEDES BENZ W171 SLK 350 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w171-slk-350-2004-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '102877', attributes: [{ name: 'Sku', value: '102877' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'SLK-350' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '0064310312' }, { name: 'Engine Code', value: 'M272' }], options: [], Sku: '102877', OriginalPrice: '0.0000', NewPrice: '680.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112604', name: 'MERCEDES BENZ W168 A160 2002 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w168-a160-2002-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '112604', attributes: [{ name: 'Sku', value: '112604' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A160' }, { name: 'Year', value: '2002' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M166 960' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '103819', name: 'BMW X3 E83 X3 25i 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-x3-e83-x3-25i-2004-abs-pump', brand: 'bmw', categories: ['others'], sku: '103819', attributes: [{ name: 'Sku', value: '103819' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: 'X3-2.5i' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '3421416' }, { name: 'Engine Code', value: 'M54' }], options: [], Sku: '103819', OriginalPrice: '0.0000', NewPrice: '480.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '102601', name: 'VOLVO V50T 25 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-v50t-25-2004-abs-pump', brand: 'volvo', categories: ['others'], sku: '102601', attributes: [{ name: 'Sku', value: '102601' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '30647855A' }, { name: 'Engine Code', value: 'B525 4T' }], options: [], Sku: '102601', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '103590', name: 'MINI R52 COOPER S 2005 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mini-r52-cooper-s-2005-abs-pump', brand: 'mini', categories: ['others'], sku: '103590', attributes: [{ name: 'Sku', value: '103590' }, { name: 'Stock No', value: '05581' }, { name: 'Model', value: 'MINI' }, { name: 'Spec', value: 'COOPER-S' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'W11' }], options: [], Sku: '103590', OriginalPrice: '0.0000', NewPrice: '500.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114254', name: 'VOLVO S60 20T 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s60-20t-2004-abs-pump', brand: 'volvo', categories: ['others'], sku: '114254', attributes: [{ name: 'Sku', value: '114254' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.0T' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '30643979' }, { name: 'Engine Code', value: 'B4204' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '108701', name: 'MERCEDES BENZ C209 CLK 280 2005 ABS PUMP', price: '152.4000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-c209-clk-280-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '108701', attributes: [{ name: 'Sku', value: '108701' }, { name: 'Stock No', value: '05485' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'CLK-280' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M272 940' }], options: [], Sku: '108701', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '108265', name: 'MERCEDES BENZ W171 SLK 200 2005 ABS PUMP', price: '10.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w171-slk-200-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '108265', attributes: [{ name: 'Sku', value: '108265' }, { name: 'Stock No', value: '05802' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'SLK-200' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: 'A0054317512' }, { name: 'Engine Code', value: '271944' }], options: [], Sku: '108265', OriginalPrice: '0.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110435', name: 'VOLKSWAGEN PASSAT 18 TSI 2012 ABS PUMP', price: '62.9200', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volkswagen-passat-18-tsi-2012-abs-pump', brand: 'volkswagen', categories: ['others'], sku: '110435', attributes: [{ name: 'Sku', value: '110435' }, { name: 'Stock No', value: '05883' }, { name: 'Model', value: 'VOLKSWAGEN' }, { name: 'Spec', value: '1.8-TSI' }, { name: 'Year', value: '2012' }, { name: 'Part Number', value: '3AA614109BK' }, { name: 'Engine Code', value: 'CDA' }], options: [], Sku: '110435', OriginalPrice: '0.0000', NewPrice: '780.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '113186', name: 'AUDI A6 24 2006 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'audi-a6-24-2006-abs-pump', brand: 'audi', categories: ['others'], sku: '113186', attributes: [{ name: 'Sku', value: '113186' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'AUDI' }, { name: 'Spec', value: '2.4' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '4F0910517033' }, { name: 'Engine Code', value: 'AGA' }], options: [], Sku: '113186', OriginalPrice: '0.0000', NewPrice: '280.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '104702', name: 'BMW E90 3 320i 2005 ABS PUMP', price: '50.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e90-3-320i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '104702', attributes: [{ name: 'Sku', value: '104702' }, { name: 'Stock No', value: '05621' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '320i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N46' }], options: [], Sku: '104702', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114247', name: 'VOLVO C70 T5 2006 ABS PUMP', price: '26.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-c70-t5-2006-abs-pump', brand: 'volvo', categories: ['others'], sku: '114247', attributes: [{ name: 'Sku', value: '114247' }, { name: 'Stock No', value: '05898' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: 'T5' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'B5254T' }], options: [], Sku: '114247', OriginalPrice: '0.0000', NewPrice: '550.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110290', name: 'JAGUAR XJ 6 30 2006 ABS PUMP', price: '8.9600', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'jaguar-xj-6-30-2006-abs-pump', brand: 'jaguar', categories: ['others'], sku: '110290', attributes: [{ name: 'Sku', value: '110290' }, { name: 'Stock No', value: '05886' }, { name: 'Model', value: 'JAGUAR' }, { name: 'Spec', value: '3.0' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: '6VC' }], options: [], Sku: '110290', OriginalPrice: '0.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110934', name: 'MERCEDES BENZ W171 SLK 200 2004 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w171-slk-200-2004-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110934', attributes: [{ name: 'Sku', value: '110934' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'SLK-200' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '0054313712' }, { name: 'Engine Code', value: '271944' }], options: [], Sku: '110934', OriginalPrice: '0.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112600', name: 'MERCEDES BENZ W169 A200 2005 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w169-a200-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '112600', attributes: [{ name: 'Sku', value: '112600' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A200' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '0044319212' }, { name: 'Engine Code', value: 'M266 960' }], options: [], Sku: '112600', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '105075', name: 'JAGUAR X TYPE 20 SE 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'jaguar-x-type-20-se-2004-abs-pump', brand: 'jaguar', categories: ['others'], sku: '105075', attributes: [{ name: 'Sku', value: '105075' }, { name: 'Stock No', value: '05626' }, { name: 'Model', value: 'JAGUAR' }, { name: 'Spec', value: '2.0-SE' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '0265224946' }, { name: 'Engine Code', value: 'YC' }], options: [], Sku: '105075', OriginalPrice: '0.0000', NewPrice: '250.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112713', name: 'JAGUAR XF 30 2008 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'jaguar-xf-30-2008-abs-pump', brand: 'jaguar', categories: ['others'], sku: '112713', attributes: [{ name: 'Sku', value: '112713' }, { name: 'Stock No', value: '06028' }, { name: 'Model', value: 'JAGUAR' }, { name: 'Spec', value: '3.0' }, { name: 'Year', value: '2008' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: '7FC' }], options: [], Sku: '112713', OriginalPrice: '1200.0000', NewPrice: '200.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '111709', name: 'BMW E60 5 523i 2006 ABS PUMP', price: '40.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e60-5-523i-2006-abs-pump', brand: 'bmw', categories: ['others'], sku: '111709', attributes: [{ name: 'Sku', value: '111709' }, { name: 'Stock No', value: '05959' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '523i' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '6774848' }, { name: 'Engine Code', value: 'N52' }], options: [], Sku: '111709', OriginalPrice: '0.0000', NewPrice: '280.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112875', name: 'PORSCHE BOXSTER S 987 2005 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'porsche-boxster-s-987-2005-abs-pump', brand: 'porsche', categories: ['others'], sku: '112875', attributes: [{ name: 'Sku', value: '112875' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'PORSCHE' }, { name: 'Spec', value: '987' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '987.355.755.05' }, { name: 'Engine Code', value: '3.2' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '112588', name: 'MERCEDES BENZ W245 B200 2005 ABS PUMP', price: '25.7000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w245-b200-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '112588', attributes: [{ name: 'Sku', value: '112588' }, { name: 'Stock No', value: '05675' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'B200' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '0044319212' }, { name: 'Engine Code', value: 'M266 960' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '110929', name: 'MERCEDES BENZ W169 A150 2005 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'mercedes-benz-w169-a150-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110929', attributes: [{ name: 'Sku', value: '110929' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A150' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '0044319212' }, { name: 'Engine Code', value: 'M266 920' }], options: [], InvoiceDate: '25/01/2016', Sku: '110929', Qty: '1.000', Price: '350.0000', OriginalPrice: '0.0000', NewPrice: '450.0000',
    }, {
        id: '112585', name: 'BMW E60 5 523i 2005 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e60-5-523i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '112585', attributes: [{ name: 'Sku', value: '112585' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '523i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '6774652' }, { name: 'Engine Code', value: 'N52' }], options: [], Sku: '112585', OriginalPrice: '0.0000', NewPrice: '280.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '102589', name: 'BMW E46 3 318i 2004 ABS PUMP', price: '50.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'bmw-e46-3-318i-2004-abs-pump', brand: 'bmw', categories: ['others'], sku: '102589', attributes: [{ name: 'Sku', value: '102589' }, { name: 'Stock No', value: '05550' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '318i' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '6765452' }, { name: 'Engine Code', value: 'N46' }], options: [], Sku: '102589', OriginalPrice: '450.0000', NewPrice: '320.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114252', name: 'MERCEDES BENZ W210 E280 2000 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w210-e280-2000-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '114252', attributes: [{ name: 'Sku', value: '114252' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'E280' }, { name: 'Year', value: '2000' }, { name: 'Part Number', value: '0034319012' }, { name: 'Engine Code', value: 'M104 945' }], options: [], Sku: '114252', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '109913', name: 'VOLVO S80 T5 20 AT 2010 ABS PUMP', price: '100.5200', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s80-t5-20-at-2010-abs-pump', brand: 'volvo', categories: ['others'], sku: '109913', attributes: [{ name: 'Sku', value: '109913' }, { name: 'Stock No', value: '05875' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.0-AT' }, { name: 'Year', value: '2010' }, { name: 'Part Number', value: 'P31329139' }, { name: 'Engine Code', value: 'B420 4T' }], options: [], Sku: '109913', OriginalPrice: '0.0000', NewPrice: '800.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '102874', name: 'VOLVO S80 25T 2006 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'volvo-s80-25t-2006-abs-pump', brand: 'volvo', categories: ['others'], sku: '102874', attributes: [{ name: 'Sku', value: '102874' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5T' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '30736633' }, { name: 'Engine Code', value: 'B5256' }], options: [], Sku: '102874', OriginalPrice: '800.0000', NewPrice: '780.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '113249', name: 'VOLVO S80 25T 2006 ABS PUMP', price: '60.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s80-25t-2006-abs-pump', brand: 'volvo', categories: ['others'], sku: '113249', attributes: [{ name: 'Sku', value: '113249' }, { name: 'Stock No', value: '05891' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5T' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '30736633' }, { name: 'Engine Code', value: 'B5254' }], options: [], Sku: '113249', OriginalPrice: '0.0000', NewPrice: '250.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112608', name: 'VOLVO S80 32 2007 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s80-32-2007-abs-pump', brand: 'volvo', categories: ['others'], sku: '112608', attributes: [{ name: 'Sku', value: '112608' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '3.2' }, { name: 'Year', value: '2007' }, { name: 'Part Number', value: 'P31261142' }, { name: 'Engine Code', value: 'B63245' }], options: [], Sku: '112608', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '113360', name: 'MINI R52 COOPER 2006 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mini-r52-cooper-2006-abs-pump', brand: 'mini', categories: ['others'], sku: '113360', attributes: [{ name: 'Sku', value: '113360' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MINI' }, { name: 'Spec', value: 'COOPER' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'W10' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '115062', name: 'VOLKSWAGEN POLO 14 2005 ABS PUMP', price: '6.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volkswagen-polo-14-2005-abs-pump', brand: 'volkswagen', categories: ['others'], sku: '115062', attributes: [{ name: 'Sku', value: '115062' }, { name: 'Stock No', value: '05949' }, { name: 'Model', value: 'VOLKSWAGEN' }, { name: 'Spec', value: '1.4' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '6Q0907379AG' }, { name: 'Engine Code', value: 'BKY' }], options: [], Sku: '115062', OriginalPrice: '350.0000', NewPrice: '150.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114425', name: 'MERCEDES BENZ W169 A170 2006 ABS PUMP', price: '16.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w169-a170-2006-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '114425', attributes: [{ name: 'Sku', value: '114425' }, { name: 'Stock No', value: '06137' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A170' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '0054319512' }, { name: 'Engine Code', value: 'M266 940' }], options: [], Sku: '114425', OriginalPrice: '0.0000', NewPrice: '280.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '105802', name: 'MERCEDES BENZ W169 A200 2005 ABS PUMP', price: '50.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w169-a200-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '105802', attributes: [{ name: 'Sku', value: '105802' }, { name: 'Stock No', value: '05652' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'A200' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'M266 960' }], options: [], InvoiceDate: '05/12/2015', Sku: '105802', Qty: '1.000', Price: '420.0000', OriginalPrice: '0.0000', NewPrice: '450.0000',
    }, {
        id: '102878', name: 'MERCEDES BENZ W209 CLK 240 2004 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w209-clk-240-2004-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '102878', attributes: [{ name: 'Sku', value: '102878' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'CLK-240' }, { name: 'Year', value: '2004' }, { name: 'Part Number', value: '0054310712' }, { name: 'Engine Code', value: '112 912' }], options: [], Sku: '102878', OriginalPrice: '480.0000', NewPrice: '650.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '113199', name: 'MERCEDES BENZ W203 C180 2005 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w203-c180-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '113199', attributes: [{ name: 'Sku', value: '113199' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C180' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '0365454032' }, { name: 'Engine Code', value: '271 946' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '112605', name: 'VOLKSWAGEN NEW BEETLE 16 2001 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volkswagen-new-beetle-16-2001-abs-pump', brand: 'volkswagen', categories: ['others'], sku: '112605', attributes: [{ name: 'Sku', value: '112605' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLKSWAGEN' }, { name: 'Spec', value: '1.6' }, { name: 'Year', value: '2001' }, { name: 'Part Number', value: '1C0907379J' }, { name: 'Engine Code', value: 'AYD' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '106043', name: 'BMW E90 3 320i 2005 ABS PUMP', price: '100.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e90-3-320i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '106043', attributes: [{ name: 'Sku', value: '106043' }, { name: 'Stock No', value: '05376' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '320i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N46' }], options: [], Sku: '106043', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '111016', name: 'MERCEDES BENZ W203 C200 KOMP 2005 ABS PUMP', price: '24.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w203-c200-komp-2005-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '111016', attributes: [{ name: 'Sku', value: '111016' }, { name: 'Stock No', value: '05902' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C200-KOMP' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '0345457732' }, { name: 'Engine Code', value: 'M271 940' }], options: [], Sku: '111016', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114241', name: 'JAGUAR X TYPE 20 SE 2005 ABS PUMP', price: '10.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'jaguar-x-type-20-se-2005-abs-pump', brand: 'jaguar', categories: ['others'], sku: '114241', attributes: [{ name: 'Sku', value: '114241' }, { name: 'Stock No', value: '06091' }, { name: 'Model', value: 'JAGUAR' }, { name: 'Spec', value: '2.0-SE' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'YC' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '111084', name: 'BMW X3 E83 X3 25i 2005 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-x3-e83-x3-25i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '111084', attributes: [{ name: 'Sku', value: '111084' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: 'X3-2.5i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '6762059' }, { name: 'Engine Code', value: 'M54' }], options: [], Sku: '111084', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '103079', name: 'PORSCHE 911 997 2005 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'porsche-911-997-2005-abs-pump', brand: 'porsche', categories: ['others'], sku: '103079', attributes: [{ name: 'Sku', value: '103079' }, { name: 'Stock No', value: '05566' }, { name: 'Model', value: 'PORSCHE' }, { name: 'Spec', value: '997' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '99735575506' }, { name: 'Engine Code', value: 'C2' }], options: [], Sku: '103079', OriginalPrice: '850.0000', NewPrice: '1200.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '105178', name: 'VOLKSWAGEN PASSAT 18 TSI 2005 ABS PUMP', price: '10.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volkswagen-passat-18-tsi-2005-abs-pump', brand: 'volkswagen', categories: ['others'], sku: '105178', attributes: [{ name: 'Sku', value: '105178' }, { name: 'Stock No', value: '05629' }, { name: 'Model', value: 'VOLKSWAGEN' }, { name: 'Spec', value: '1.8-TSI' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '4B0614517G\t' }, { name: 'Engine Code', value: 'BZB' }], options: [], Sku: '105178', OriginalPrice: '0.0000', NewPrice: '280.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '106289', name: 'PORSCHE CAYMAN S 987 2007 ABS PUMP', price: '0.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'porsche-cayman-s-987-2007-abs-pump', brand: 'porsche', categories: ['others'], sku: '106289', attributes: [{ name: 'Sku', value: '106289' }, { name: 'Stock No', value: '04213' }, { name: 'Model', value: 'PORSCHE' }, { name: 'Spec', value: '987' }, { name: 'Year', value: '2007' }, { name: 'Part Number', value: '0265234142' }, { name: 'Engine Code', value: '3.4' }], options: [], Sku: '106289', OriginalPrice: '0.0000', NewPrice: '1200.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '114245', name: 'VOLVO S60 20T 2005 ABS PUMP', price: '10.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s60-20t-2005-abs-pump', brand: 'volvo', categories: ['others'], sku: '114245', attributes: [{ name: 'Sku', value: '114245' }, { name: 'Stock No', value: '05824' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.0T' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '30643981' }, { name: 'Engine Code', value: 'B5204' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '113523', name: 'MINI R50 COOPER 2006 ABS PUMP', price: '8.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mini-r50-cooper-2006-abs-pump', brand: 'mini', categories: ['others'], sku: '113523', attributes: [{ name: 'Sku', value: '113523' }, { name: 'Stock No', value: '06077' }, { name: 'Model', value: 'MINI' }, { name: 'Spec', value: 'COOPER' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '6765282' }, { name: 'Engine Code', value: 'W10' }], options: [], Sku: '113523', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112849', name: 'VOLVO S80 24 2006 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-s80-24-2006-abs-pump', brand: 'volvo', categories: ['others'], sku: '112849', attributes: [{ name: 'Sku', value: '112849' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.4' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: 'P30736633' }, { name: 'Engine Code', value: 'B5244' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '113606', name: 'MERCEDES BENZ W245 B200 2006 ABS PUMP', price: '24.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w245-b200-2006-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '113606', attributes: [{ name: 'Sku', value: '113606' }, { name: 'Stock No', value: '06082' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'B200' }, { name: 'Year', value: '2006' }, { name: 'Part Number', value: '0054319512' }, { name: 'Engine Code', value: 'M266 960' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '106020', name: 'BMW E90 3 320i 2005 ABS PUMP', price: '50.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'bmw-e90-3-320i-2005-abs-pump', brand: 'bmw', categories: ['others'], sku: '106020', attributes: [{ name: 'Sku', value: '106020' }, { name: 'Stock No', value: '05658' }, { name: 'Model', value: 'BMW' }, { name: 'Spec', value: '320i' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N46' }], options: [], Sku: '106020', OriginalPrice: '0.0000', NewPrice: '350.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '111748', name: 'MINI R60 COUNTRYMAN COOPER S 2014 ABS PUMP', price: '88.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mini-r60-countryman-cooper-s-2014-abs-pump', brand: 'mini', categories: ['others'], sku: '111748', attributes: [{ name: 'Sku', value: '111748' }, { name: 'Stock No', value: '05964' }, { name: 'Model', value: 'MINI' }, { name: 'Spec', value: 'COOPER-S' }, { name: 'Year', value: '2014' }, { name: 'Part Number', value: '' }, { name: 'Engine Code', value: 'N18' }], options: [], Sku: '111748', OriginalPrice: '0.0000', NewPrice: '800.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110937', name: 'MERCEDES BENZ W202 C230 2003 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w202-c230-2003-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110937', attributes: [{ name: 'Sku', value: '110937' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C230' }, { name: 'Year', value: '2003' }, { name: 'Part Number', value: '0034319412' }, { name: 'Engine Code', value: 'M111 974' }], options: [], Sku: '110937', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '106853', name: 'VOLVO XC 90 25 T 2005 ABS PUMP', price: '24.5000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volvo-xc-90-25-t-2005-abs-pump', brand: 'volvo', categories: ['others'], sku: '106853', attributes: [{ name: 'Sku', value: '106853' }, { name: 'Stock No', value: '05702' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5-T' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '30643979' }, { name: 'Engine Code', value: 'B5254' }], options: [], Sku: '106853', OriginalPrice: '0.0000', NewPrice: '480.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '110935', name: 'MERCEDES BENZ W203 C180 2003 ABS PUMP', price: '30.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'mercedes-benz-w203-c180-2003-abs-pump', brand: 'mercedes-benz', categories: ['others'], sku: '110935', attributes: [{ name: 'Sku', value: '110935' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'MERCEDES-BENZ' }, { name: 'Spec', value: 'C180' }, { name: 'Year', value: '2003' }, { name: 'Part Number', value: '0345457032' }, { name: 'Engine Code', value: '271 946' }], options: [], Sku: '110935', OriginalPrice: '0.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112595', name: 'VOLVO S80 25T 2007 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'volvo-s80-25t-2007-abs-pump', brand: 'volvo', categories: ['others'], sku: '112595', attributes: [{ name: 'Sku', value: '112595' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.5T' }, { name: 'Year', value: '2007' }, { name: 'Part Number', value: 'P31261142' }, { name: 'Engine Code', value: 'B5254' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    }, {
        id: '113390', name: 'VOLKSWAGEN JETTA 16 2005 ABS PUMP', price: '130.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '1.000', slug: 'volkswagen-jetta-16-2005-abs-pump', brand: 'volkswagen', categories: ['others'], sku: '113390', attributes: [{ name: 'Sku', value: '113390' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLKSWAGEN' }, { name: 'Spec', value: '1.6' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '1K0614517K' }, { name: 'Engine Code', value: 'BSE' }], options: [], Sku: '113390', OriginalPrice: '650.0000', NewPrice: '450.0000', Qty: '0', Price: '0', InvoiceDate: '',
    }, {
        id: '112596', name: 'VOLVO S60 24 T 2005 ABS PUMP', price: '20.0000', compareAtPrice: null, images: ['images/products/all.jpg'], badges: '', rating: '', reviews: '', availability: '2.000', slug: 'volvo-s60-24-t-2005-abs-pump', brand: 'volvo', categories: ['others'], sku: '112596', attributes: [{ name: 'Sku', value: '112596' }, { name: 'Stock No', value: '' }, { name: 'Model', value: 'VOLVO' }, { name: 'Spec', value: '2.4-T' }, { name: 'Year', value: '2005' }, { name: 'Part Number', value: '30643980' }, { name: 'Engine Code', value: 'B5244' }], options: [], Qty: '0', Price: '0', InvoiceDate: '', NewPrice: '0', OriginalPrice: '0',
    },
];

// const url = `${config.production.apiUrl}search?stock=true`;
// console.log(url);
// let productsDef1 = [];
// productsDef1 = fetch(`${url}`, {
//     method: 'GET',
//     // mode: 'no-cors',
//     headers: {
//         'Content-Type': 'application/json',
//         // 'API-Key': 'YkRAX5bOdIXLggv2XfSQsjnJln9BaIzA',
//         // 'Authorization': `token ${access_token}`
//     },
// }).then((response) => response.json());

// console.log('productsDef1', productsDef1);

const productsData = productsDef.map((productDef) => {
    // console.log(productDef);
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === 'string' ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || []).map((productAttributeDef) => {
        const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

        if (!attributeDef) {
            return null;
        }

        let valuesDef = [];

        if (typeof productAttributeDef.values === 'string') {
            valuesDef = [productAttributeDef.values];
        } else if (productAttributeDef.values) {
            valuesDef = productAttributeDef.values;
        }

        const values = valuesDef.map((valueSlug) => {
            const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

            if (!valueDef) {
                return null;
            }

            return {
                ...valueDef,
                customFields: {},
            };
        }).filter((x) => x !== null);

        if (!values.length) {
            return null;
        }

        return {
            name: attributeDef.name,
            slug: attributeDef.slug,
            featured: !!productAttributeDef.featured,
            values,
            customFields: {},
        };
    }).filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: parseFloat(productDef.sku),
        slug: productDef.slug,
        price: parseFloat(productDef.price),
        compareAtPrice: parseFloat(productDef.compareAtPrice), // || productDef.price * 1.33,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories: productDef.categories,
        attributes: productDef.attributes,
        // customFields: {},
    };
});

export default productsData;
