/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable */
// react
import React, { Component, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, BrowserRouter, Switch } from 'react-router-dom';
import { Router, Route, Routes, redirect } from "react-router";

// application
import { PayPalButton } from 'react-paypal-button-v2';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Alert, Button } from 'react-bootstrap';
import qs from 'query-string';
import {
    Country, State, City,
} from 'country-state-city';

import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import config from '../../data/configurations';
import shopApi from '../../api/shop';
import ShopPagePayment from './ShopPagePayment';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);
        this.renderData = this.renderData.bind();
        this.state = {
            payment: 'bank',
            country: '',
            region: '',
            city: '',
            firstname: '',
            cities: '',
            redirect : ''
        };
    }

    renderData() {
        console.log("render");
        return (
            <ShopPagePayment></ShopPagePayment>
        )
    }
    
    onSuccess(data) {
        const el = document.createElement('p');
        el.innerHTML = 'success';
        document.body.appendChild(el);
    }

    onClose(data) {
        const el = document.createElement('p');
        el.innerHTML = 'closed';
        document.body.appendChild(el);
    }

    onError(error) {
        const el = document.createElement('p');
        el.innerHTML = `Error: ${error}`;
        document.body.appendChild(el);
    }

    onPayClick() {
        const { cart } = this.props;
        const details = {
            amount: cart.total,
            currency: 'SGD',
            send_email: true,
            redirect_url: `${config.production.siteUrl}orders/`,
            webhook: `${config.production.siteUrl}orders/`,
        };

        let formBody = [];
        // eslint-disable-next-line func-names
        return function () {
        for (const property in details) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(details[property]);
            formBody.push(`${encodedKey}=${encodedValue}`);
        }
        formBody = formBody.join('&');

        fetch('https://api.sandbox.hit-pay.com/v1/payment-requests', {
            method: 'POST',
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
                'X-BUSINESS-API-KEY': '0a7c69145c3190fe242e77dd11cd79e435d74aab89124f347c03b79e1714ae3a',
                // "mode": 'no-cors',
            },
            body: formBody,
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // console.log('sandbox results', result);
                    /* this.setState({
                        isLoaded: true,
                        brands: result.brands,
                        models: result.models,
                        specs: result.specs,
                        list: result.list,
                    }); */

                    if (typeof (window.HitPay) !== 'undefined' && !window.HitPay.inited) {
                        window.HitPay.init('https://securecheckout.sandbox.hit-pay.com/payment-request/@propel-auto-parts-pte-ltd', {
                        // Optional, default is https
                        // scheme: 'http',
                        // Optional, default is hit-pay.com
                            domain: 'sandbox.hit-pay.com',
                            apiDomain: 'sandbox.hit-pay.com',
                        // Optional default is false
                        // closeOnError: true
                        },
                            // Optional callbacks
                        /* {
                            onClose: this.onClose(),
                            onSuccess: this.onSuccess(),
                            onError: this.onError(),
                        } */
                        // eslint-disable-next-line function-paren-newline
                        );

                        window.HitPay.toggle({
                            // Payment request method params
                            paymentRequest: result.id,
                            // Optional
                            // method: 'card',
                            // Default method params
                            // Optional
                            // amount: 500
                        });
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    // console.log('sandbox errors', JSON.stringify(error));
                    /* this.setState({
                        isLoaded: true,
                        error,
                    }); */
                },
            );
         };
    }

    selectCountry(val) {
        this.setState({ country: val });
        this.setState({ cities: '' });
    }

    selectRegion(val) {
        this.setState({ region: val });
        const { country } = this.state;
        const result = City.getCitiesOfState(country, val);
        this.setState({ cities: result });
    }

    selectCity(event) {
        this.setState({ city: event.target.value });
       // const { city } = this.state;
       // console.log('city event', event.target.value, this.state);
        // this.shippingCalc(event.target.value);
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    renderTotals() {
        const { cart } = this.props;
        localStorage.setItem('cartvalue', JSON.stringify(cart));
        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td id={index}><Currency value={extraLine.price} /></td>
            </tr>
        ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;
// console.log('redercart', cart);
        const items = cart.items.map((item) => (
            <tr key={item.id}>
                {/* {console.log('item', item)} */}
                <td>{`${item.product.descriptions[0].name} × ${item.quantity}`}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Total</th>
                        <td><Currency value={cart.total} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;

        const payments = this.payments.map((payment, index) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef} key={index}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                        {payment.content}
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    paybutton() {
        const { cart } = this.props;
        // console.log('cart', cart);
        return (
            <PayPalButton
                createOrder={(data, actions) =>
                // throw new Error('force the createOrder callback to fail');throw new Error('force the createOrder callback to fail');
                    actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: 'USD',
                                value: cart.total,
                                details: {
                                    subtotal: cart.subtotal,
                                    tax: '7', // cart.tax
                                },
                                shippingPreference: 'SET_PROVIDED_ADDRESS',
                            },
                        },
                        ],
                        // application_context: {
                        //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                        // }
                    })}
                onApprove={(data, actions) =>
                // throw new Error('force the onApprove callback to fail');
                // // Capture the funds from the transaction
                    actions.order.capture().then((details) => {
                        //   // Show a success message to your buyer
                        // console.log(details);
                      //  alert(`Transaction completed by ${details.payer.name.given_name}`);

                        //   // OPTIONAL: Call your server to save the transaction
                        //   return fetch("/paypal-transaction-complete", {
                        //     method: "post",
                        //     body: JSON.stringify({
                        //       orderID: data.orderID
                        //     })
                        //   });
                    })}
                onCancel={(data) => {
                    // console.log('cancel', data);
                }}
                onError={(err) => {
                    // console.error('error from the onError callback', err);
                    // window.location.href = "/error";
                }}
            />
        );
    }

    AlertDismissible(type, title, content = null) {
        const [show, setShow] = useState(true);

        return (
            <React.Fragment>
                <Alert show={show} variant={type}>
                    <Alert.Heading>{title}</Alert.Heading>
                    <p>
                        {content}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setShow(false)} variant={type}>
                            Close
                        </Button>
                    </div>
                </Alert>

                {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
            </React.Fragment>
        );
    }

    handleInputChange(event) {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        // console.log('inputchange', name, value);
        this.setState({
          [name]: value,
        });
      }


      
    handleSubmit(event) {
         // alert('A form has been submitted: Click ok to proceed');
         event.preventDefault();
        // this.AlertDismissible('success', 'Hai');
        // const values = event.target.form.map((val, index) => {
        //     console.log(`val${index}`, val);
        //     return val;
        // });

        // this.setState({ loading: true }, () => {
        //     fetch('/endpoint')
        //     .then((result) => this.setState({
        //         loading: false,
        //         data: [...result.data],
        //     }));
        // });
        const carts = JSON.parse(localStorage.getItem('cartvalue'));
         // console.log('cart', carts.items);

        const data = {
            'firstname': event.target.firstname.value,
            'lastname': event.target.lastname.value,
            'company': event.target.company.value,
            'country': event.target.country.value,
            'street': event.target.street.value,
            'suite': event.target.suite.value,
            'city': event.target.city.value,
            'state': event.target.state.value,
            'postcode': event.target.postcode.value,
            'email': event.target.email.value,
            'phone': event.target.phone.value,
            'notes': event.target.notes.value,
        };
        const items = carts.items.map((item) => ({
            height: item.product.height,
            width: item.product.width,
            itemlength: item.product.length,
            weight: item.product.weight,
            weight_class: item.product.weight_class,
        }
        ));
        // console.log('datas', data, items[0]);
        const qData = {
            accountNumber: config.production.accountNumber,
            originCountryCode: config.production.originCountryCode,
            originPostalCode: config.production.originPostalCode,
            originCityName: config.production.originCityName,
            destinationCountryCode: data.country,
            destinationPostalCode: data.postcode,
            destinationCityName: data.city,
            height: items[0].height,
            length: items[0].itemlength,
            width: items[0].width,
            weight: items[0].weight,
            plannedShippingDate: new Date().toJSON().slice(0, 10),
            isCustomsDeclarable: true,
            unitOfMeasurement: 'metric',
            requestEstimatedDeliveryDate: true,
        };
        const params = { ...qData };
        const url = `${config.production.apiUrl}shippingRates?${qs.stringify(params)}`;

        const shipping = fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
        shipping.then((res) => {
           // console.log('res', res);
            localStorage.setItem('shipping', JSON.stringify(res));
            localStorage.setItem('orderdata', JSON.stringify(data));
            const { delivery, total } = res;
            window.location.replace(`${config.production.siteUrl}shop/payment/`);
           
            // window.location.reload(false);
            // window.location.replace('/shop/payment');
            // this.setState({ redirect: "/shop/payment" });
            // <BrowserRouter>
            // <Switch>
            // <Route path="/shop/payment" exact component={<ShopPagePayment/>} />
            // </Switch>
            // </BrowserRouter>
            // this.props.history.push({ pathname: `/shop/payment`});
            // history.push('/shop/payment');

           console.log('after redirect');
            // this.render();
            // ReactDOM.render(<Currency value={res.price} />, document.getElementById('0'));
        });

     //   let canceled = false;
    //    shopApi.getShippingRates(data, items[0]).then((result) => {
    //         if (canceled) {
    //             return;
    //         }

    //         console.log('API result', result);
    //     });
    //     return () => {
    //         canceled = true;
    //     };

       // [event.target.name]: event.target.value.trim()
    }

    

    shippingCalc(maincity) {
        const { cart } = this.props;
        const { country, region, city } = this.state;
       // console.log('shipping calc,this.state, city, maincity', cart, this.state, city, maincity);

        const carts = JSON.parse(localStorage.getItem('cartvalue'));
         // console.log('cart', carts.items);
       // console.log('lenght', carts.items.length);
        const items = carts.items.length === 1 && carts.items.map((item) => ({
            height: item.product.height,
            width: item.product.width,
            itemlength: item.product.length,
            weight: item.product.weight,
            weight_class: item.product.weight_class,
        }
        ));
        // console.log('datas', data, items[0]);
        const qData = {
            accountNumber: config.production.accountNumber,
            originCountryCode: config.production.originCountryCode,
            originPostalCode: config.production.originPostalCode,
            originCityName: config.production.originCityName,
            destinationCountryCode: country,
            destinationPostalCode: '',
            destinationCityName: maincity || city,
            height: items[0] ? items[0].height : '',
            length: items[0] ? items[0].itemlength : '',
            width: items[0] ? items[0].width : '',
            weight: items[0] ? items[0].weight : '',
            plannedShippingDate: new Date().toJSON().slice(0, 10),
            isCustomsDeclarable: true,
            unitOfMeasurement: 'metric',
            requestEstimatedDeliveryDate: true,
        };
        const params = { ...qData };
        const url = `${config.production.apiUrl}shippingRates?${qs.stringify(params)}`;

        const shipping = fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());

        shipping.then((res) => {
           // console.log('res', res);
            localStorage.setItem('shipping', JSON.stringify(res));
        //    localStorage.setItem('orderdata', JSON.stringify(data));
            const { delivery, total } = res;
             window.location.replace('/shop/payment');
           // console.log('after redirect');
            // this.render();
            // ReactDOM.render(<Currency value={res.price} />, document.getElementById('0'));
        });
    }

   
    render() {
        const { cart } = this.props;
        const { country, region, cities } = this.state;

    const cityList = cities.length > 0
    && cities.map((item, i) => (
        <option key={i} value={item.name}>{item.name}</option>
      ), this);

        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }

        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Shopping Cart', url: '/shop/cart' },
            { title: 'Checkout', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                    <script src="https://sandbox.hit-pay.com/hitpay.js" />
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />
                <form onSubmit={this.handleSubmit}>
                    <div className="checkout block">
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-12 mb-3">
                                <div className="alert alert-primary alert-lg">
                                    Returning customer?
                                    {' '}
                                    <Link to="/account/login">Click here to login</Link>
                                </div>
        </div> */}

                                <div className="col-12 col-lg-6 col-xl-7">
                                    <div className="card mb-lg-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Billing / Shipping details</h3>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-first-name">
                                                        First Name
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-first-name"
                                                        placeholder="First Name"
                                                        required
                                                        name="firstname"                                                        
                                                       // onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-last-name">
                                                        Last Name
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-last-name"
                                                        placeholder="Last Name"
                                                        required
                                                        name="lastname"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-company-name">
                                                    Company Name
                                                    {' '}
                                                    {/* <span className="text-muted">(Optional)</span> */}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-company-name"
                                                    placeholder="Company Name"
                                                    name="company"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-street-address">
                                                    Street Address
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-street-address"
                                                    placeholder="Street Address"
                                                    required
                                                    name="street"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-address">
                                                    Apartment, suite, Area, Location etc.
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <input type="text" name="suite" className="form-control" id="checkout-address" required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-country">
                                                    Country
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <CountryDropdown
                                                    id="checkout-country"
                                                    className="form-control"
                                                    value={country}
                                                    valueType="short"
                                                    onChange={(val) => this.selectCountry(val)}
                                                    required
                                                    name="country"
                                                />

                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-state">
                                                    State / County
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <RegionDropdown
                                                    className="form-control"
                                                    id="checkout-state"
                                                    countryValueType="short"
                                                    country={country}
                                                    value={region}
                                                    valueType="short"
                                                    onChange={(val) => this.selectRegion(val)}
                                                    required
                                                    name="state"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-city">
                                                    Town / City
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                {/* <input name="city" type="text" className="form-control" id="checkout-city" required /> */}
                                                <select name="city" className="form-control" aria-label="Select City" required onChange={(val) => this.selectCity(val)}>
                                                    <option key="0">Select city</option>
                                                    {cityList}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-postcode">
                                                    Postcode / ZIP
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <input name="postcode" type="text" className="form-control" id="checkout-postcode" required />
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-email">
                                                        Email address
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="checkout-email"
                                                        placeholder="Email address"
                                                        required
                                                        name="email"
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-phone">
                                                        Phone
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <input type="text" name="phone" className="form-control" id="checkout-phone" placeholder="Phone" required />
                                                </div>
                                            </div>

                                            {/* <div className="form-group">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <span className="input-check__body">
                                                            <input className="input-check__input" type="checkbox" id="checkout-create-account" required />
                                                            <span className="input-check__box" />
                                                            <Check9x7Svg className="input-check__icon" />
                                                        </span>
                                                    </span>
                                                    <label className="form-check-label" htmlFor="checkout-create-account">
                                                        Create an account?
                                                    </label>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="card-divider" />
                                        <div className="card-body">
                                            {/* <h3 className="card-title">Shipping Details</h3>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-different-address" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-different-address">
                                                    Ship to a different address?
                                                </label>
                                            </div>
                                        </div> */}

                                            <div className="form-group">
                                                <label htmlFor="checkout-comment">
                                                    Order notes
                                                </label>
                                                <textarea name="notes" id="checkout-comment" className="form-control" rows="4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Your Order</h3>

                                            {this.renderCart()}

                                            <div className="checkout__agree form-group">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <span className="input-check__body">
                                                            <input className="input-check__input" type="checkbox" id="checkout-terms" required />
                                                            <span className="input-check__box" />
                                                            <Check9x7Svg className="input-check__icon" />
                                                        </span>
                                                    </span>
                                                    <label className="form-check-label" htmlFor="checkout-terms">
                                                        I have read and agree to the website
                                                        <Link to="site/terms" className="text-red-color"> terms and conditions</Link>
                                                        *
                                                    </label>
                                                </div>
                                            </div>
                                            {/* {this.renderPaymentsList()} */}
                                            <button type="submit" className="btn btn-primary btn-xl btn-block bg-red-color">Place Order</button>
                                            {/* this.paybutton() */}
                                            {/* <button type="submit" className="btn btn-primary btn-xl btn-block bg-red-color">Place Order</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
