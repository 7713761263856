/* eslint-disable quote-props */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
// react
import React, { Component } from 'react';
import { useForm } from 'react-hook-form';
// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import qs from 'query-string';
// application
import { PayPalButton } from 'react-paypal-button-v2';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import axios from 'axios';
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import paymentsList from '../../data/shopPayments';
import theme from '../../data/theme';
import config from '../../data/configurations';

// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// class ShopPageCheckout extends Component {
const ShopPagePayment = (props) => {
    // constructor(props) {
       // super(props);

        // this.state = {
        //     payment: 'bank',
        //     country: '',
        //     region: '',
        // };
   // }
    const { cart, shipping, orderdata } = props;
    // const { country, region } = this.state;

        if (cart.items.length < 1) {
            return <Redirect to="/shop/cart" />;
        }

        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Shopping Cart', url: '/shop/cart' },
            { title: 'Checkout', url: '/shop/checkout' },
            { title: 'Payment', url: '' },
        ];

    function onSuccess(data) {
        const el = document.createElement('p');
        el.innerHTML = 'success';
        document.body.appendChild(el);
    }

    function onClose(data) {
        const el = document.createElement('p');
        el.innerHTML = 'closed';
        document.body.appendChild(el);
    }

    function onError(error) {
        const el = document.createElement('p');
        el.innerHTML = `Error: ${error}`;
        document.body.appendChild(el);
    }

    function saveOrder() {
        const { cart, shipping, orderdata } = props;
        console.log('cart', cart);
            console.log('shipping', shipping);
            console.log('orderdata', orderdata);
            const qData = {
                cart,
                shipping,
                orderdata,
            };
            const params = { ...qData };
        const url = `${config.production.apiUrl}order-add?order=true`;

            const payment = fetch(`${url}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(qData),
            }).then((response) => response.json());
            payment.then((res) => {
                console.log('response', res);
                if (res.url) {
                    // window.location.replace(res.url);
                } else if (res.message) {
                    alert(res.message);
                }
            });
    }

    function onPayClick() {
        const { cart, shipping, orderdata } = props;
        const { state } = props;

        return () => {
            const oData = {
                cart,
                shipping,
                orderdata,
            };
            console.log('cart', cart);
            console.log('shipping', shipping);
            console.log('orderdata', orderdata);
            const qData = {
                amount: cart.total + shipping.total.price,
                email: orderdata.email,
                name: `${orderdata.firstname} ${orderdata.lastname}`,
                // currency: 'SGD',
                // send_email: true,
                // redirect_url: `${config.production.siteUrl}shop/track-order/`,
                // webhook: `${config.production.siteUrl}shop/track-order/`,
            };
            const params = { ...qData };
            const url = `${config.production.apiUrl}getPayment?${qs.stringify(params)}`;

            const payment = fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(oData),
            }).then((response) => response.json());
            payment.then((res) => {
                console.log('response', res);
                state.cart.items = [];
                state.cart.lastItemId = 0;
                state.cart.subtotal = 0;
                state.cart.total = 0;
                state.cart.quantity = 0;
                if (res.url) {
                     window.location.replace(res.url);
                } else if (res.message) {
                    alert(res.message);
                }
            });
        };
    }

    function selectCountry(val) {
        // this.setState({ country: val });
    }

    function selectRegion(val) {
        // this.setState({ region: val });
    }

    function handlePaymentChange(event) {
        if (event.target.checked) {
            // this.setState({ payment: event.target.value });
        }
    }

    function shippingCalc() {
        return (
            <React.Fragment>
                <div className="">
                    shipping
                </div>
            </React.Fragment>
        );
    }

    function renderTotals() {
        const { cart, shipping } = props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            // { if(title = 'Shipping') {
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={extraLine.title === 'Shipping' ? shipping.total.price : extraLine.price} /></td>
            </tr>
            // }},
    ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    function renderCart() {
        const { cart, shipping } = props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.descriptions[0].name} × ${item.quantity}`}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Total</th>
                        <td><Currency value={cart.total + shipping.total.price} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    function renderPaymentsList() {
        const { payment: currentPayment } = ''; // this.state;

        const payments = paymentsList.map((payment, index) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef} key={index}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={handlePaymentChange()}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                        {payment.content}
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    function paybutton() {
        const { cart, shipping } = props;
        // console.log('cart', cart);
        return (
            <PayPalButton
                createOrder={(data, actions) =>
                // throw new Error('force the createOrder callback to fail');throw new Error('force the createOrder callback to fail');
                    actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: 'USD',
                                value: cart.total,
                                details: {
                                    subtotal: cart.subtotal,
                                    tax: '7', // cart.tax
                                },
                                shippingPreference: 'SET_PROVIDED_ADDRESS',
                            },
                        },
                        ],
                        // application_context: {
                        //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                        // }
                    })}
                onApprove={(data, actions) =>
                // throw new Error('force the onApprove callback to fail');
                // // Capture the funds from the transaction
                    actions.order.capture().then((details) => {
                        //   // Show a success message to your buyer
                        // console.log(details);
                        alert(`Transaction completed by ${details.payer.name.given_name}`);

                        //   // OPTIONAL: Call your server to save the transaction
                        //   return fetch("/paypal-transaction-complete", {
                        //     method: "post",
                        //     body: JSON.stringify({
                        //       orderID: data.orderID
                        //     })
                        //   });
                    })}
                onCancel={(data) => {
                    // console.log('cancel', data);
                }}
                onError={(err) => {
                    // console.error('error from the onError callback', err);
                    // window.location.href = "/error";
                }}
            />
        );
    }

    function FormValidation() {
        // const { country, region } = this.state;
        const { register, handleSubmit, formState: { errors } } = useForm();
        const onSubmit = (data) => {
            // console.log(data);
        };

        return (
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="checkout block">
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-12 mb-3">
                                <div className="alert alert-primary alert-lg">
                                    Returning customer?
                                    {' '}
                                    <Link to="/account/login">Click here to login</Link>
                                </div>
        </div> */}

                                <div className="col-12 col-lg-6 col-xl-7">
                                    <div className="card mb-lg-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Billing / Shipping details</h3>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-first-name">First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-first-name"
                                                        placeholder="First Name"
                                                        value={orderdata.firstname}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-last-name">Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-last-name"
                                                        placeholder="Last Name"
                                                        value={orderdata.lastname}
                                                        disabled
                                                    />
                                                </div>
                                                {errors.lastname && <p>Please check the Last Name</p>}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-company-name">
                                                    Company Name
                                                    {' '}
                                                    <span className="text-muted">(Optional)</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-company-name"
                                                    placeholder="Company Name"
                                                    value={orderdata.company}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-country">Country</label>
                                                <CountryDropdown
                                                    id="checkout-country"
                                                    className="form-control"
                                                  //  value={country}
                                                    valueType="short"
                                                    onChange={(val) => selectCountry(val)}
                                                    value={orderdata.country}
                                                    disabled
                                                />

                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-street-address">Street Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-street-address"
                                                    placeholder="Street Address"
                                                    value={orderdata.street}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-address">
                                                    Apartment, suite, unit etc.
                                                    {' '}
                                                    <span className="text-muted">(Optional)</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-address"
                                                    value={orderdata.suite}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-city">Town / City</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-city"
                                                    value={orderdata.city}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-state">State / County</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-state"
                                                   // country={country}
                                                  //  value={region}
                                                    onChange={(val) => selectRegion(val)}
                                                    value={orderdata.state}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-postcode"
                                                    value={orderdata.postcode}
                                                    disabled
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-email">Email address</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="checkout-email"
                                                        placeholder="Email address"
                                                        value={orderdata.email}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-phone">Phone</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-phone"
                                                        placeholder="Phone"
                                                        value={orderdata.phone}
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="form-group">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <span className="input-check__body">
                                                            <input className="input-check__input" type="checkbox" id="checkout-create-account" />
                                                            <span className="input-check__box" />
                                                            <Check9x7Svg className="input-check__icon" />
                                                        </span>
                                                    </span>
                                                    <label className="form-check-label" htmlFor="checkout-create-account">
                                                        Create an account?
                                                    </label>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="card-divider" />
                                        <div className="card-body">
                                            {/* <h3 className="card-title">Shipping Details</h3>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-different-address" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-different-address">
                                                    Ship to a different address?
                                                </label>
                                            </div>
                                        </div> */}

                                            <div className="form-group">
                                                <label htmlFor="checkout-comment">
                                                    Order notes
                                                    {' '}
                                                    <span className="text-muted">(Optional)</span>
                                                </label>
                                                <textarea
                                                    id="checkout-comment"
                                                    className="form-control"
                                                    rows="4"
                                                    value={orderdata.notes}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Your Order</h3>

                                            {renderCart()}

                                            {/* <div className="checkout__agree form-group">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <span className="input-check__body">
                                                            <input className="input-check__input" type="checkbox" id="checkout-terms" />
                                                            <span className="input-check__box" />
                                                            <Check9x7Svg className="input-check__icon" />
                                                        </span>
                                                    </span>
                                                    <label className="form-check-label" htmlFor="checkout-terms">
                                                        I have read and agree to the website
                                                        <Link to="site/terms" className="text-red-color"> terms and conditions</Link>
                                                        *
                                                    </label>
                                                </div>
                                            </div> */}
                                            {/* {renderPaymentsList()} */}
                                            <button type="button" onClick={onPayClick()} className="btn btn-primary btn-xl btn-block bg-red-color">Make Payment</button>
                                            {/* this.paybutton() */}
                                            {/* <button type="submit" className="btn btn-primary btn-xl btn-block bg-red-color">Make Payment</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    function render() {
        // const { cart } = this.props;
        // const { country, region } = this.state;

        // if (cart.items.length < 1) {
        //     return <Redirect to="cart" />;
        // }

        // const breadcrumb = [
        //     { title: 'Home', url: '' },
        //     { title: 'Shopping Cart', url: '/shop/cart' },
        //     { title: 'Checkout', url: '' },
        // ];
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Payment — ${theme.name}`}</title>
                <script src="https://sandbox.hit-pay.com/hitpay.js" />
            </Helmet>

            <PageHeader header="Payment" breadcrumb={breadcrumb} />
            {/* {console.log('cart', cart)}
            {console.log('shipping', shipping)}
            {console.log('orderdata', orderdata)} */}
            {FormValidation()}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    state,
    shipping: JSON.parse(localStorage.getItem('shipping')),
    orderdata: JSON.parse(localStorage.getItem('orderdata')),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPagePayment);
