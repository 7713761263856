/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// react
// import React from 'react';
import { React, useEffect, useState } from 'react';
// third-party
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
// import { listBrands } from '../../data/shopBrands';
import brandList from '../../fake-server/database/brandList';
import config from '../../data/configurations';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <i className="fas fa-arrow-right d-lg-block d-none" />
        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {' '}
            <i className="fas fa-arrow-left d-lg-block d-none" />
            {' '}
        </div>
    );
}
const slickSettings = {
    // dots: false,
    // arrows: false,
    // infinite: true,
    // speed: 1400,
    // slidesToShow: 7,
    // slidesToScroll: 7,
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    // speed: 1000,
    rows: 1,
    autoplaySpeed: 6000,
    cssEase: 'linear',
    slidesToShow: 8,
    slidesToScroll: 6,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 6,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 6,
            },
        },
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 5,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

export default function BlockBrands(props) {
    const { layout } = props;
    // const brands = [];
    const [loading, setLoading] = useState(false);
    // const [brandsData, setBrandsData] = useState([]);
    // useEffect(() => {
    //     listBrands(setBrandsData);
    // }, []);

    // console.log(brandsData);
    // debugger;
    const brandsList = brandList.map((brand, index) => (
        <div key={index} className="block-brands__item">
            <Link to={`brands/${brand.slug}`}><img src={config.production.mediaUrl + brand.image} alt="" /></Link>
            {/* <b>{brand.name}</b> */}
        </div>
    ));

    return (
        <div className={`block block-brands block-features--layout--${layout}`}>
            <div className="container m-auto">
                <h2 className="block-header__title px-4">Browse by Brands</h2>
                <div className="block-brands__slider">
                    <StroykaSlick {...slickSettings}>
                        {brandsList}
                    </StroykaSlick>
                </div>
            </div>
        </div>
    );
}
