/* eslint-disable no-unused-vars */
// react
import React, {
 Component, useEffect, useReducer, useState,
} from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import {
    Country, State, City,
} from 'country-state-city';
// import Select from 'react-select-2';
import 'react-select-2/dist/css/react-select-2.css';
import Select from 'react-select';
import config from '../../data/configurations';

class Shipping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            country: '',
            //     region: '',
            city: '',
            cities: '',
            total: 0,
            delivery: '',
            priceCurrency: '',
            errorMsg: '',
            isSearchable: true,
            countries: Country.getAllCountries(),
        };
    }

    selectCountry(event) {
        // // console.log(Country.getAllCountries());
        this.setState({ country: event.value });
        // console.log('county', event.value);
        // console.log(City.getCitiesOfCountry(event.value));
        this.setState({ cities: City.getCitiesOfCountry(event.value) });
    }

    // selectRegion(val) {
    //     this.setState({ region: val });
    //     const { country } = this.state;
    //     const result = City.getCitiesOfState(country, val);
    //     this.setState({ cities: result });
    // }

    selectCity(event) {
        this.setState({ city: event.value });
        // console.log('city event', event.value);
        // this.shippingCalc();
    }

    shippingCalc() {
        const { product } = this.props;
        const { country, city } = this.state;
        // console.log('shipping calc', this.state, 'city='.city);
        // console.log('product', product);
        const qData = {
            accountNumber: config.production.accountNumber,
            originCountryCode: config.production.originCountryCode,
            originPostalCode: config.production.originPostalCode,
            originCityName: config.production.originCityName,
            destinationCountryCode: country,
            // destinationPostalCode: '',
            destinationCityName: city,
            height: product ? product.height : '',
            length: product ? product.length : '',
            width: product ? product.width : '',
            weight: product ? product.weight : '',
            plannedShippingDate: new Date().toJSON().slice(0, 10),
            isCustomsDeclarable: true,
            unitOfMeasurement: 'metric',
            requestEstimatedDeliveryDate: true,
        };
        const params = { ...qData };
        const url = `${config.production.apiUrl}shippingRates?${qs.stringify(params)}`;

        const shipping = fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());

        shipping.then((res) => {
            // console.log('res', res);
            const date = new Date(res.delivery);
            if (!res.total) { this.setState({ errorMsg: res }); }
            if (res.total) {
                this.setState({ errorMsg: '' });
                this.setState({ delivery: date.toLocaleDateString() });
                this.setState({ total: res.total.price });
                this.setState({ priceCurrency: res.total.priceCurrency });
            }
        });
    }

    render() {
        const { country, cities, countries } = this.state;
        const { product } = this.props;
        console.log('product', product);
        const {
            delivery, total, priceCurrency, errorMsg,
        } = this.state;
        const { isSearchable } = this.state;
        const cityList = cities.length > 0
            && cities.map((item, i) => ({
                value: item.name, label: item.name,
            }
            ), this);
        const countryList = countries.length > 0
            && countries.map((item, i) => ({
                value: item.isoCode, label: item.name,
            }
            ), this);
        return (
            <React.Fragment>
                <div className={product.weight === 0 || product.weight === '0' ? 'd-none' : ''}>
                    <span>
                        Estimated Shipping calculation
                    </span>
                    <div className="row m-0">
                        <Select
                            className="basic-single col-md-5 pl-0"
                            classNamePrefix="select"
                            placeholder="Select Country"
                            isClearable={isSearchable}
                            isSearchable={isSearchable}
                            name="country"
                            options={countryList}
                            onChange={(e) => this.selectCountry(e)}
                        />

                        <Select
                            className="basic-single col-md-5 pl-0"
                            classNamePrefix="select"
                            placeholder="Select City"
                        // defaultValue={colourOptions[0]}
                        // isDisabled={isDisabled}
                        // isLoading={isLoading}
                            isClearable={isSearchable}
                        // isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="city1"
                            options={cityList}
                            onChange={(e) => this.selectCity(e)}
                        />

                        <button
                            type="button"
                            title="Get Rates"
                            onClick={() => this.shippingCalc()}
                            className={classNames('btn btn-md btn-dark col-md-2')}
                        >
                            Calc

                        </button>
                    </div>
                    <span>
                        {errorMsg && errorMsg !== ''
                        ? (
                            <div className="alert alert-danger" role="alert">
                                {errorMsg}
                            </div>
                        ) : ''}
                        {total && total !== 0
                        ? (
                            <div className="alert alert-success" role="alert">
                                Total:&nbsp;
                                <b>
                                    {total}
                                    &nbsp;
                                    {priceCurrency}

                                </b>
                                &nbsp;, Estimated Delivery: &nbsp;
                                {' '}
                                <b>{delivery}</b>
                            </div>
                        ) : ''}

                    </span>
                </div>
                <div className={product.weight === 0 || product.weight === '0' ? '' : 'd-none'}>
                    <p>
                        This item will ship to Singapore,
                        <a href={`mailto:sales@propel-auto.com?subject=${product.descriptions[0].name}`}><b><i className="text-danger"> Contact us</i></b></a>
                        {' '}
                        and request a shipping method to your location.
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

Shipping.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
};

Shipping.defaultProps = {
    //    withSidebar: false,
};

export default Shipping;
