/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// react
import React from 'react';

function ProductTabDescription() {
    return (
        <div className="typography">
            <h3>Product Details</h3>

            <h4>Item Description:</h4>
            Original used parts.
            Please do not hesitate to contact us if you have any question regarding this item.
            <h4>Compatibility:</h4>
            BEFORE PURCHASE, double check your part number to ensure COMPATIBILITY!
            It is the
            {' '}
            <span>BUYER'S RESPONSIBILITY</span>
            {' '}
            to check carefully all the pictures for details or request more pictures if necessary.
            It is the BUYER'S RESPONSIBILITY to determine the fitment of the parts.
            <h4>Payment Mode:</h4>
            Paynow, Visa, Mastercard and American Express, GrabPay, Shopee Pay.
            <br />
            Cash for local pick up
            <br />
            9% GST APPLIES TO SINGAPORE LOCAL SALES

            <h3>Shipping Policy:</h3>
            If payment is received before 3 pm Singapore time, the item will be shipped out the same day. Otherwise, the consignment will be sent within the next business day. For large items may take a day or two for packing.
            Note that no shipment on weekends and holidays.
            If the buyer chooses to cancel the order that in the way of delivery, the buyer will be responsible for the return shipping cost.
            Note that Import duties, taxes, and charges are NOT INCLUDED in the item price or shipping cost. These charges are the buyer's responsibility.
            Please check with your country's customs office to determine what these additional costs will be prior to bidding or buying.
            <h4>Returns / Refunds Policy:</h4>
            Guarantee period of 14 DAYS after the buyer receiving the item. Buyer will either get the money back (THE COST OF THE ITEM ONLY) or item exchange (BUYER’S RESPONSIBILITY TO PAY FOR AND SHIP THE ITEM BACK TO US).
            The refund (THE COST OF ITEM ONLY) will be issued once the item has been received and verified as faulty.
            Note that all our dispatched items are marked. There will be no refund or exchange if the marking is damaged or the item is not in original condition.
            <h4>Contact us:</h4>
            We package our items to the safest extent possible. Yet there are chances of getting damaged on the way of delivery. If so, please notify us to seek the best solution.
            If the item is damaged, defective, and incorrect or other issues arise, please contact us directly BEFORE opening a case, so that we can resolve the problems as soon as possible. We appreciate your kind cooperation and understanding.
            If you need MORE QUANTITIES of the parts, or if you do not find a suitable part in our eBay store, please contact us, and we will get back to you as soon as possible.
        </div>
    );
}

export default ProductTabDescription;
