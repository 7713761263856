/* eslint-disable */
// react
import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from  'react-loader-spinner'
// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import config from '../../data/configurations';

export default function AccountPageProfile() {
    const [profile, setProfile] = useState([]);
    const [loading, setLoading] = useState(true);
    const data = {
        userId: 1
    }
    function getProfileData() {
        setTimeout(() => {
            setLoading(false);
          }, 2000);

        fetch(`http://localhost:5000/profile.json?${qs.stringify(data)}`, {
            method: "GET",
            mode: 'no-cors',
            headers: { "Content-type": "application/json" },
        }).then((response) => response.json())
            .then((data) => {
                setProfile(data)
            });
    }

    useEffect(() => {
        getProfileData();
    }, []);

    const { emailVal, firstVal, lastVal, phoneVal } = useLocation();
    const searchParams = new URLSearchParams(emailVal);
    const searchParams1 = new URLSearchParams(firstVal);
    const searchParams2 = new URLSearchParams(lastVal);
    const searchParams3 = new URLSearchParams(phoneVal);
    const email = searchParams.get("email");
    const first = searchParams1.get("first");
    const last = searchParams2.get("last");
    const phone = searchParams3.get("phone");

    const [values, setValues] = useState('');
    const [txt, profileTxt] = useState('Save');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(value)
        setValues({
            ...values,
            [name]: value,
        });
    }
    const editProfile=(e)=>{
        const data = {
            userId: 1,
            email: values.email,
            firstName: values.first,
            lastName: values.last,
            phone: values.phone
        }
        profileTxt("Updated...");
        setTimeout(() => {
            setLoading(false);
          }, 2000);
        toast.success("Profile data are changed Successful");
        window.location.replace('/account/dashboard');
        fetch(config.production + '/editProfile', {
            method: 'POST',
            mode:  'no-cors',
            body: JSON.stringify(data)

        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // profileTxt("Updated...");
                 // toast.success("Profile data are changed Successful");
                    console.log('success results', result);
                })
            .catch((err) => {
                // toast.error(err);
                  });
            
    }

    return (
        <div>
            {loading ? (
       <ThreeDots color="green" radius={"3px"} style={{position: "relative",left:"50%"}}/>
      ) : (
        <div>
            <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
            {profile.map((item) => (
                <div className="card" style={{diplay: 'block !important',width: '70%'}}>
                    <Helmet>
                        <title>{`Profile — ${theme.name}`}</title>
                    </Helmet>

                    <div className="card-header">
                        <h5>Edit Profile</h5>
                    </div>
                    <div className="card-divider" />
                    <div className="card-body">
                        <div className="row no-gutters">
                            <div className="col-12 col-lg-7 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="profile-first-name">First Name</label>
                                    <input
                                        id="profile-first-name"
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="first"
                                        defaultValue={item.firstName ? item.firstName: first}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="profile-last-name">Last Name</label>
                                    <input
                                        id="profile-last-name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="last"
                                        defaultValue={item.lastName? item.lastName : last}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="profile-email">Email Address</label>
                                    <input
                                        id="profile-email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        name="email"
                                        defaultValue={item.email? item.email :email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="profile-phone">Phone Number</label>
                                    <input
                                        id="profile-phone"
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        name="phone"
                                        defaultValue={item.phone? item.phone: phone}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group mt-5 mb-0">
                                    <button type="button" className="btn btn-primary" disabled={!values} onClick={editProfile}>{txt}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
      )}
    </div>
    );
}
