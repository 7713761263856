export const url = {
    home: () => '/',

    catalog: () => '/parts',

    category: (category) => `/parts/${category.slug}`,

    product: (product) => `/shop/products/${product.alias ? product.alias : product.id}`,

    brand: (brand) => `/brands/${brand}`,

    model: (brand, model) => `/brands/${brand}/${model}`,
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
