/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
// react
import React, { Component } from 'react';
// import ReactDOM from "react-dom";
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
// import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg } from '../../svg'; // Compare16Svg
import { wishlistAddItem } from '../../store/wishlist';
import Indicator from '../header/Indicator';
import Shipping from './Shipping';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    // fetchSplFeatures = () => {
    //      const features = this.props.product.features.map((feature, index) => (
    //        //console.log(feature);
    //         <li key={index}>
    //             if( feature.name != null && feature.name == 'Brand' )
    //              <   b>{feature.name}</b> : {feature.value}
    //             else
    //                 <p>empty</p>
    //         </li>

    //     ));
    //     console.log('in function',features);
    //     //return features;
    // };

    fetchSplFeatures = () => {
        const list = this.props.product.customfields;
        if (!list) {
            return null;
        }
        if (!Object.keys(list).length) {
            return '';
        }
        return (
            Object.values(list).map((key, index) => (
                <li key={index}>
                    {key.name}
                    {' '}
                    :
                    <b className="text-dark">
                        {' '}
                        {key.text}
                    </b>
                </li>
            ))
        );
    }

    render() {
        const {
            product,
            layout,
            wishlistAddItem,
            // compareAddItem,
            cartAddItem,
        } = this.props;
        const { quantity } = this.state;
        let prices;

        if (product.promotion_price) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price"><Currency value={product.promotion_price} /></span>
                    {' '}
                    <span className="product__old-price"><Currency value={product.price} /></span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.price} />;
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={product.images} mainimage={product.image} />

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            {/* <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            /> */}
                        </div>

                        <div className="product__sidebar">

                            <form className="product__options">

                                <div className="product__footer">
                                    <div className="product__share-links share-links">
                                        <ul className="share-links__list">
                                            <li className="share-links__item share-links__item--type--like">
                                                <a href="https://www.facebook.com/Propelauto/">
                                                    <i className="fab fa-facebook-messenger" />
                                                    {' '}
                                                    &nbsp;Message Us&nbsp;
                                                </a>
                                            </li>
                                            <li className="share-links__item bg-success text-white">
                                                <a external="true" href={`https://wa.me/6585198833/?text=SKU: ${product.sku} | I'm Interested in ${window.location.href}`}>
                                                    <i className="fab fa-whatsapp my-float" />
                                                    {' '}
                                                    &nbsp;Chat now&nbsp;
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="form-group product__option">
                                    {/* <label htmlFor="product-quantity" className="product__option-label">Quantity</label> */}
                                    <div className="product__actions">
                                        <div className="product__actions-item d-none">
                                            <InputNumber
                                                id="product-quantity"
                                                aria-label="Quantity"
                                                className="product__quantity"
                                                size="lg"
                                                min={1}
                                                value={quantity}
                                                onChange={this.handleChangeQuantity}
                                            />
                                        </div>
                                        <div className={product.stock <= 0 || (product.weight === 0 || product.weight === '0') ? 'd-none' : 'product__actions-item product__actions-item--addtocart'}>
                                            <AsyncAction
                                                action={() => cartAddItem(product, [], quantity)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            run();
                                                            document.getElementById('cartIndicator').click();
                                                        }}
                                                        disabled={!quantity}
                                                        className={classNames('btn btn-primary bg-danger btn-md', {
                                                            'btn-loading': loading,
                                                        })}
                                                    >
                                                        <i className="fa fa-shopping-cart" aria-hidden="true" />
                                                        &nbsp;&nbsp;BUY&nbsp;
                                                    </button>
                                                )}
                                            />
                                        </div>
                                        <div className={product.stock <= 0 || (product.weight === 0 || product.weight === '0') ? 'share-links__item ' : 'd-none'}>
                                            <a className="text-white" external="true" href={`https://wa.me/6585198833/?text=SKU: ${product.sku} | I'm Interested in ${window.location.href}`}>
                                                <button type="button" className="btn btn-danger btn-sm">&nbsp;CONTACT US&nbsp;</button>
                                            </a>
                                            <small><em className="text-danger">*Contact for shipping</em></small>
                                        </div>
                                        <div className="product__actions-item product__actions-item--wishlist">
                                            <AsyncAction
                                                action={() => wishlistAddItem(product)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Wishlist"
                                                        onClick={run}
                                                        className={classNames('btn btn-secondary btn-svg-icon btn-md', {
                                                            'btn-loading': loading,
                                                        })}
                                                    >
                                                        <Wishlist16Svg />
                                                    </button>
                                                )}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </form>

                            <div className="product__prices">
                                {prices}
                            </div>
                        </div>

                        <h1 className="product__name">{product.descriptions[0] ? product.descriptions[0].name : '..'}</h1>

                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                Availability:
                                {' '}
                                <b><span className={`${product.stock > 0 || product.stock === 'in-stock' ? 'text-success' : 'text-danger'}`}>{`${product.stock > 0 || product.stock === 'in-stock' ? 'In Stock' : 'No Stock'}`}</span></b>
                            </li>
                            <li className="product__meta-availability">
                                SKU:
                                {' '}
                                <b><span className="text-dark">{`${product.sku ? product.sku : 'NO SKU'}`}</span></b>
                            </li>
                            <li className="product__meta-availability">
                                BRAND:
                                {' '}
                                <b><span className="text-dark">{`${product.brand ? product.brand.name : '-'}`}</span></b>
                            </li>
                            {this.fetchSplFeatures()}
                        </ul>
                    </div>
                    <div className="product_shipping">
                        <Shipping product={product} />
                    </div>

                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(Product);
