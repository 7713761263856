/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
import axios from 'axios';
import config from './configurations';

/**
 *-----------Get Available Brands----------
 *
 * @export
 */
export function listBrands(setBrandsData) {
    const url = `${config.production.apiUrl}brands`;
    // console.log(url);
    const head = {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            apikey: `${config.production.apikey}`,
            apiconnection: `${config.production.apiconnection}`,
        },
    };
    axios(url, head).then((res) => {
        const brands = res.data;
        // console.log('response', res);
        // console.log('listBrandsResponse', brands);
        if (res.status == 200) {
            setBrandsData(brands);
        }
    }).catch((error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }
        // console.log(error.config);
    });
}

// export default [
//     { name: 'Mercedes Benz', url: 'brands/mercedes-benz', image: 'images/logos/Mercedes-Benz.png' },
//     { name: 'Audi', url: 'brands/audi', image: 'images/logos/Audi.png' },
//     { name: 'Alfa Romeo', url: 'brands/alfa-romeo', image: 'images/logos/Alfa-Romeo.png' },
//     { name: 'Aston Martin', url: 'brands/aston-martin', image: 'images/logos/Aston-Martin.png' },
//     { name: 'BMW', url: 'brands/bmw', image: 'images/logos/bmw.png' },
//     { name: 'Bentley', url: 'brands/bentley', image: 'images/logos/Bentley.png' },
//     { name: 'Ferrari', url: 'brands/ferrari', image: 'images/logos/Ferrari.png' },
//     { name: 'Jeep', url: 'brands/jeep', image: 'images/logos/Jeep.png' },
//     { name: 'Jaguar', url: 'brands/jaguar', image: 'images/logos/jaguar.png' },
//     { name: 'Lamborghini', url: 'brands/lamborghini', image: 'images/logos/lamborghini.png' },
//     { name: 'Lexus', url: 'brands/lexus', image: 'images/logos/Lexus.png' },
//     { name: 'Lotus', url: 'brands/lotus', image: 'images/logos/Lotus.png' },
//     { name: 'Land Rover', url: 'brands/land-rover', image: 'images/logos/Land-Rover.png' },
//     { name: 'Maserati', url: 'brands/maserati', image: 'images/logos/Maserati.png' },
//     { name: 'McLaren', url: 'brands/mclaren', image: 'images/logos/McLaren.png' },
//     { name: 'Mini', url: 'brands/mini', image: 'images/logos/Mini.png' },
//     { name: 'porsche', url: 'brands/porsche', image: 'images/logos/porsche.png' },
//     { name: 'Peugeot', url: 'brands/peugeot', image: 'images/logos/Peugeot.png' },
//     { name: 'Volkswagen', url: 'brands/volkswagen', image: 'images/logos/Volkswagen.png' },
//     { name: 'Volvo', url: 'brands/volvo', image: 'images/logos/Volvo.png' },
// ];
