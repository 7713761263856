/* eslint-disable no-const-assign */
/* eslint-disable prefer-const */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
// react
import React, {
    Component, useMemo, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
    CardDeck, CardColumns, Card, Button,
} from 'react-bootstrap';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import config from '../../data/configurations';
import shopApi from '../../api/shop';

import 'react-tabs/style/react-tabs.css';

// import axios from 'axios';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

// data stubs
// eslint-disable-next-line import/no-named-as-default
import CategoryBlock from './ShopCategoryBlock';
import brandList from '../../fake-server/database/brandList';
import { url } from '../../services/utils';
import { listBrands } from '../../data/shopBrands';
import { sidebarClose } from '../../store/sidebar';

function ShopPageBrands(props) {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         error: null,
    //         isLoaded: false,
    //         items: [],
    //         divData: 'no content',
    //         categories: null,
    //     };
    // }

    // const url = `${config.production.apiUrl}select/list`;
    // fetch(`${url}`, {
    //     method: 'GET',
    //     // "mode": 'no-cors',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         // 'API-Key': 'YkRAX5bOdIXLggv2XfSQsjnJln9BaIzA',
    //         // 'Authorization': `token ${access_token}`
    //     },
    // })
    //     .then((res) => res.json())
    //     .then(
    //         (result) => {
    //             // console.log('results set', result);
    //             this.setState({
    //                 isLoaded: true,
    //                 brands: result.brands,
    //                 models: result.models,
    //                 specs: result.specs,
    //                 list: result.list,

    //             });
    //         },
    //         // Note: it's important to handle errors here
    //         // instead of a catch() block so that we don't swallow
    //         // exceptions from actual bugs in components.
    //         (error) => {
    //             this.setState({
    //                 isLoaded: true,
    //                 error,
    //             });
    //         },
    //     );

    // shopApi.getCategories({ depth: 1 }).then((result) => this.setState({
    //     categories: result,
    // }));
    // console.log('state', this.state);
    // console.log('state', categories);

    // const [loading, setLoading] = useState(false);
    const [brandsData, setBrandsData] = useState([]);
    useEffect(() => {
        listBrands(setBrandsData);
    }, []);
    const {
        error, isLoaded, brands, models, categories,
    } = props; // specs
    console.log(props);
    // const { match: { params } } = this.props;
    // console.log('props', this.props);
    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Brands', url: '/brands' },
        // { title: params.brandName.toUpperCase(), url: params.brandName },
    ];
    let content = (
        <div>
            <div className="container">
                <CardColumns className="brandColumns">
                    {brandList.map((item, index) => (
                        <Card style={{ width: '110px', height: '100px' }} key={{ index }} className="p-0">
                            <Link to={`/brands/${item.slug}`}>
                                <Card.Img style={{ width: '110px', height: '100px' }} variant="top" src={config.production.mediaUrl + item.image} />
                            </Link>
                        </Card>
                    ))}
                </CardColumns>
            </div>
        </div>
    );

    // if (brandsData) isLoaded = true;
    if (error) {
        return (
            <div className="block-header__title text-center">
                Error:
                {error.message}
            </div>
        );
    }
    // if (!isLoaded) {
    //     return <div className="block-header__title text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true" /></div>;
    // }
    // debugger;
    return (
        <React.Fragment>
            <Helmet>
                <title>{` ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="" breadcrumb={breadcrumb} />
            {content}
        </React.Fragment>
    );
}

ShopPageBrands.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageBrands.defaultProps = {
    columns: 5,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageBrands);
