/* eslint-disable max-len */
export default [
    {
        title: 'ENGINE',
        url: '/parts/engines',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'ENGINE',
                    url: '/parts/engine-parts',
                    submenu: [
                        { title: 'ENGINE ONLY', url: '/parts/engine-only' },
                        { title: 'ENGINE ONLY COMPLETE', url: '/parts/engine-only-complete' },
                        { title: 'ENGINE SET GEARBOX', url: '/parts/engine-set-gearbox' },
                        { title: 'ENGINE BLOCK', url: '/parts/engine-block' },
                        { title: 'ENGINE SUSPENSION', url: '/parts/engine-suspension' },
                    ],
                },
                {
                    title: 'CHARGING',
                    url: '#',
                    submenu: [
                        { title: 'FUEL DISTRIBUTOR', url: '/parts/fuel-distributor' },
                        { title: 'KOMPRESSOR-SUPERCHARGE', url: '/parts/kompressor-supercharge' },
                        { title: 'TURBO CHARGER', url: '/parts/turbo' },
                        { title: 'AC COMPRESSOR', url: '/parts/ac-compressor' },
                        { title: 'HIGH PRESSURE FUEL PUMP', url: '/parts/high-pressure-fuel-pump' },
                        { title: 'AIR FILTER BOX', url: '/parts/air-filter' },
                        { title: 'POWER STEERING PUMP', url: '/parts/power-steering-pump' },
                        { title: 'FUEL INJECTORS', url: '/parts/fuel-injector' },
                        { title: 'THROTTLE BODY', url: '/parts/throttle-body' },
                        { title: 'WATER PUMP', url: '/parts/water-pump' },
                    ],
                },
                {
                    title: 'ELECTRICAL ITEMS',
                    url: '#',
                    submenu: [
                        { title: 'STARTER', url: '/parts/starter' },
                        { title: 'ALTERNATOR', url: '/parts/alternator' },
                        { title: 'ENGINE WIRE', url: '/parts/engine-wire' },
                        { title: 'ENGINE COIL', url: '/parts/engine-coil' },
                        { title: 'ECU', url: '/parts/ecu' },
                    ],
                },
                {
                    title: 'IN-OUT MANIFOLDS',
                    url: '#',
                    submenu: [
                        { title: 'MANIFOLD IN', url: '/parts/manifold-in' },
                        { title: 'MANIFOLD OUT', url: '/parts/manifold-out' },
                    ],
                },
                {
                    title: 'OTHERS',
                    url: '#',
                    submenu: [
                        // { title: 'OIL SUMP', url: '/parts/oil-sump' },
                        { title: 'ENGINE BLOCK', url: '/parts/engine-block' },
                        // { title: 'ENGINE COVER-HEAD COVER', url: '/parts/engine-cover-head-cover' },
                    ],
                },
            ],
        },
    },
    {
        title: 'TRANSMISSION',
        url: '/parts/gearbox-transmission',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'GEARBOX',
                    url: '#',
                    submenu: [
                        { title: 'AUTO GEARBOX', url: '/parts/earbox-automativ' },
                        { title: 'MANUAL GEARBOX', url: '/parts/gearbox-manual' },
                        // { title: 'TORQUE CONVERTOR', url: '/parts/torque-convertor' },
                        // { title: 'FLYWHEEL', url: '/parts/flywheel' },
                        { title: 'TRANSFER CASE', url: '/parts/transfer-case' },
                        { title: 'GEARBOX ECU', url: '/parts/ecu' },
                    ],
                },
            ],
        },
    },
    {
        title: 'BODYPARTS',
        url: '/parts/body-parts',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'FRONT BODY PARTS',
                    url: '#',
                    submenu: [
                        { title: 'BUMPER FRONT', url: '/parts/bumper-front' },
                        { title: 'FENDER LH', url: '/parts/fender-left' },
                        { title: 'FENDER RH', url: '/parts/fender-right' },
                        { title: 'BONNET', url: '/parts/bonnet' },
                        { title: 'FRONT SUPPORT PANEL', url: '/parts/support-panel-front' },
                        { title: 'GRILLE', url: '/parts/grille' },
                        { title: 'FRONT CUT', url: '/parts/front-cut' },
                    ],
                },
                {
                    title: 'REAR BODY PARTS',
                    url: '#',
                    submenu: [
                        { title: 'BUMPER REAR', url: '/parts/bumper-rear' },
                        { title: 'BOOT LID-TRUNK LID', url: '/parts/boot-lid' },
                        { title: 'REAR FENDER LH', url: '/parts/rear-fender-lh' },
                        { title: 'REAR FENDER RH', url: '/parts/rear-fender-rh' },
                        { title: 'REAR END PANEL', url: '/parts/rear-end-panel' },
                        // { title: 'REINFORCEMENT', url: '/parts/reinforcement' },
                        { title: 'REAR CUT', url: '/parts/rear-cut' },
                    ],
                },
                {
                    title: 'DOORS',
                    url: '#',
                    submenu: [
                        { title: 'DOOR FRONT LEFT', url: '/parts/door-front-left' },
                        { title: 'DOOR FRONT RIGHT', url: '/parts/door-front-right' },
                        { title: 'DOOR REAR LEFT', url: '/parts/door-rear-left' },
                        { title: 'DOOR REAR RIGHT', url: '/parts/door-rear-right' },
                        { title: 'SIDE SKIRT LH', url: '/parts/side-skirt-left' },
                        { title: 'SIDE SKIRT RH', url: '/parts/side-skirt-right' },
                        { title: 'DOOR SILLS', url: '/parts/door-sill-set' },
                    ],
                },
            ],
        },
    },
    {
        title: 'INTERIOR',
        url: '/parts/interior-parts',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'CENTER CONSOLE', url: '/parts/center-console' },
                { title: 'SUN VISOR', url: '/parts/sun-visor' },
                { title: 'ASHTRAY', url: '/parts/ashtray' },
                { title: 'CUP HOLDER', url: '/parts/cup-holder' },
                { title: 'SENSOR', url: '/parts/sensor' },
            ],
        },
    },
    {
        title: 'LIGHTING',
        url: '/parts/lighting',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'FRONT LIGHTING',
                    url: '#',
                    submenu: [
                        { title: 'HEADLAMP LH', url: '/parts/headlamp-left' },
                        { title: 'HEADLAMP RH', url: '/parts/headlamp-right' },
                        { title: 'FOG LAMP-SIGNAL LAMP', url: '/parts/fog-lamp' },
                        { title: 'HEADLAMP BALLAST', url: '/parts/ballast' },
                    ],
                },
                {
                    title: 'REAR LIGHTING',
                    url: '#',
                    submenu: [
                        { title: 'TAIL LAMP LH', url: '/parts/tail-lamp-left' },
                        { title: 'TAIL LAMP RH', url: '/parts/tail-lamp-right' },
                        { title: 'REFLECTOR LH', url: '/parts/reflector-lh' },
                        { title: 'REFLECTOR RH', url: '/parts/reflector-rh' },
                        // { title: 'THIRD BRAKE LAMP', url: '/parts/third-brake-lamp' },
                    ],
                },
            ],
        },
    },
    // {
    //     title: 'CONTROLS',
    //     url: '/parts/controls',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             {
    //                 title: 'SHIFTER',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'GEAR SHIFTER', url: '/parts/gear-shifter' },
    //                     { title: 'GEAR KNOB', url: '/parts/gear-knob' },
    //                 ],
    //             },
    //             {
    //                 title: 'PEDALS',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'ACC PEDAL', url: '/parts/acc-pedal' },
    //                     { title: 'BRAKE PEDAL', url: '/parts/brake-pedal' },
    //                 ],
    //             },
    //         ],
    //     },
    // },
    // {
    //     title: 'FUEL SUPPLY',
    //     url: '/parts/fuel-supply',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             {
    //                 title: 'FUEL TANK',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'FUEL TANK', url: '/parts/fuel-tank' },
    //                     { title: 'FUEL PUMP', url: '/parts/fuel-pump' },
    //                     { title: 'FUEL PUMP MODULE', url: '/parts/fuel-pump-module' },
    //                 ],
    //             },
    //         ],
    //     },
    // },
    // {
    //     title: 'RADIATOR',
    //     url: '/parts/radiator',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             {
    //                 title: 'RADIATOR',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'RADIATOR', url: '/parts/radiator' },
    //                     { title: 'AC CONDENSOR', url: '/parts/ac-condensor' },
    //                     { title: 'AUTO FAN', url: '/parts/auto-fan' },
    //                     { title: 'TURBO COOLER', url: '/parts/turbo-cooler' },
    //                     { title: 'COOLANT TANK', url: '/parts/coolant-tank' },
    //                 ],
    //             },
    //         ],
    //     },
    // },
    {
        title: 'BRAKES',
        url: '/parts/brakes',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'BRAKE ELECTRIC UNITS',
                    url: '#',
                    submenu: [
                        { title: 'ABS PUMB', url: '/parts/abs-pumb' },
                        { title: 'HAND BRAKE MOTOR', url: '/parts/hand-brake-motor' },
                        { title: 'BRAKE BOOSTER', url: '/parts/brake-booster' },
                    ],
                },
                {
                    title: 'ROTORS-DISCS',
                    url: '#',
                    submenu: [
                        { title: 'FRONT DISC LH', url: '/parts/brake-disc-left' },
                        { title: 'FRONT DISC RH', url: '/parts/brake-disc-right' },
                        { title: 'REAR DISC LH', url: '/parts/rear-brake-disc-left' },
                        { title: 'REAR DISC RH', url: '/parts/rear-brake-disc-right' },
                    ],
                },
                {
                    title: 'BRAKE CALIPERS',
                    url: '#',
                    submenu: [
                        { title: 'FRONT BRAKE CALLIPER LH', url: '/parts/front-brake-caliper-left' },
                        { title: 'FRONT BRAKE CALLIPER RH', url: '/parts/front-brake-caliper-right' },
                        { title: 'REAR BRAKE CALLIPER LH', url: '/parts/rear-brake-caliper-left' },
                        { title: 'REAR BRAKE CALLIPER RH', url: '/parts/rear-brake-caliper-right' },
                    ],
                },
            ],
        },
    },
    {
        title: 'AXLE',
        url: '#',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'AXLE SUPPORT',
                    url: '#',
                    submenu: [
                        { title: 'CROSS MEMBER', url: '/parts/cross-member' },
                        { title: 'STRUT BAR-STABILIZER BAR', url: '/parts/front-stabilizer-bar' },
                        { title: 'FRONT AXLE', url: '/parts/front-axle' },
                        { title: 'REAR AXLE', url: '/parts/rear-axle' },
                    ],
                },
                {
                    title: 'SHAFTS',
                    url: '#',
                    submenu: [
                        { title: 'DRIVE SHAFT LEFT', url: '/parts/driveshaft-left' },
                        { title: 'DRIVE SHAFT RIGHT', url: '/parts/driveshaft-right' },
                        // { title: 'DRIVE SHAFT REAR LH', url: '/parts/drive-shaft-rear-lh' },
                        // { title: 'DRIVE SHAFT REAR RH', url: '/parts/drive-shaft-rear-rh' },
                        // { title: 'PROPELLOR SHAFT', url: '/parts/propellor-shaft' },
                        { title: 'STERRING SHAFT', url: '/parts/steering-shaft' },
                        { title: 'CRANKSHAFT', url: '/parts/crankshaft' },
                    ],
                },
                {
                    title: 'ABSORBERS',
                    url: '#',
                    submenu: [
                        { title: 'FRONT ABSORBER LH', url: '/parts/absorber-front-left' },
                        { title: 'FRONT ABSORBER RH', url: '/parts/absorber-front-right' },
                        { title: 'REAR ABSORBER LH', url: '/parts/absorber-rear-left' },
                        { title: 'REAR ABSORBER RH', url: '/parts/absorber-rear-right' },
                        { title: 'SPRING LH', url: '/parts/front-coil-spring' },
                        { title: 'SPRING RH', url: '/parts/rear-coil-spring' },
                    ],
                },
                {
                    title: 'KNUCKLE-HUB',
                    url: '#',
                    submenu: [
                        { title: 'FRONT KNUCKLE LH', url: '/parts/front-knuckle-left' },
                        { title: 'FRONT KNUCKLE RH', url: '/parts/front-knuckle-right' },
                        { title: 'REAR KNUCKLE LH', url: '/parts/rear-knuckle-left' },
                        { title: 'REAR KNUCKLE RH', url: '/parts/rear-knuckle-right' },
                    ],
                },
                {
                    title: 'ARMS',
                    url: '/parts/arm-rest',
                    submenu: [
                        { title: 'LOWER ARM LH', url: '/parts/lower-arm-front-lh' },
                        { title: 'LOWER ARM RH', url: '/parts/lower-arm-front-rh' },
                        { title: 'UPPER ARM LH', url: '/parts/upper-arm-front-lh' },
                        { title: 'UPPER ARM RH', url: '/parts/upper-arm-front-rh' },
                        { title: 'CONTROL ARMS', url: '/parts/lower-control-arm' },
                    ],
                },
            ],
        },
    },
    // {
    //     title: 'REAR AXLE',
    //     url: '/parts/rear-axle',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             {
    //                 title: 'REAR AXLE SUPPORT',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'REAR DIFFERENTIAL AXLE', url: '/parts/rear-differential-axle' },
    //                     { title: 'CROSS MEMBER REAR', url: '/parts/cross-member-rear' },
    //                     { title: 'REAR STABILIZER BAR', url: '/parts/rear-stabilizer-bar' },
    //                 ],
    //             },
    //             {
    //                 title: 'SHAFTS',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'DRIVE SHAFT REAR LH', url: '/parts/drive-shaft-rear-lh' },
    //                     { title: 'DRIVE SHAFT REAR RH', url: '/parts/drive-shaft-rear-rh' },
    //                 ],
    //             },
    //             {
    //                 title: 'SUSPENSION',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'REAR SUSPENSION LH', url: '/parts/rear-suspension-lh' },
    //                     { title: 'REAR SUSPENSION RH', url: '/parts/rear-suspension-rh' },
    //                     { title: 'SPRING LH', url: '/parts/spring-lh' },
    //                     { title: 'SPRING RH', url: '/parts/spring-rh' },
    //                 ],
    //             },
    //             {
    //                 title: 'KNUCKLE-HUB',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'REAR KNUCKLE LH', url: '/parts/rear-knuckle-lh' },
    //                     { title: 'REAR KNUCKLE RH', url: '/parts/rear-knuckle-rh' },
    //                 ],
    //             },
    //             {
    //                 title: 'ARMS',
    //                 url: '#',
    //                 submenu: [
    //                     { title: 'LOWER ARM LH', url: '/parts/lower-arm-lh' },
    //                     { title: 'LOWER ARM RH', url: '/parts/lower-arm-rh' },
    //                     { title: 'UPPER ARM LH', url: '/parts/upper-arm-lh' },
    //                     { title: 'UPPER ARM RH', url: '/parts/upper-arm-rh' },
    //                     { title: 'CONTROL ARM', url: '/parts/control-arm' },
    //                 ],
    //             },
    //         ],
    //     },
    // },
    {
        title: 'STEERING',
        url: '/parts/steering',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'STEERING',
                    url: '#',
                    submenu: [
                        { title: 'STEERING RACK', url: '/parts/steering-rack' },
                        { title: 'STEERING SHAFT', url: '/parts/steering-shaft' },
                        { title: 'STEERING BOX', url: '/parts/steering-box' },
                        { title: 'STEERING WHEEL', url: '/parts/steering-wheel-only' },
                        { title: 'STEERING AIRBAG', url: '/parts/steering-wheel-with-airbag' },
                    ],
                },
            ],
        },
    },
    {
        title: 'WHEELS',
        url: '/parts/wheels',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'AMG',
                    url: '#',
                    submenu: [
                        { title: '18"', url: '/parts/wheels' },
                        { title: '19"', url: '/parts/wheels' },
                        { title: '20"', url: '/parts/wheels' },
                    ],
                },
                {
                    title: 'MSPORT',
                    url: '#',
                    submenu: [
                        { title: '18"', url: '/parts/wheels' },
                        { title: '19"', url: '/parts/wheels' },
                        { title: '20"', url: '/parts/wheels' },
                    ],
                },
                {
                    title: 'ORIGINAL',
                    url: '#',
                    submenu: [
                        { title: '15"', url: '/parts/wheels' },
                        { title: '16"', url: '/parts/wheels' },
                        { title: '17"', url: '/parts/wheels' },
                        { title: '18"', url: '/parts/wheels' },
                        { title: '19"', url: '/parts/wheels' },
                        { title: '20"', url: '/parts/wheels' },
                    ],
                },
                {
                    title: 'OEM REPLICA',
                    url: '#',
                    submenu: [
                        { title: '15"', url: '/parts/wheels' },
                        { title: '16"', url: '/parts/wheels' },
                        { title: '17"', url: '/parts/wheels' },
                        { title: '18"', url: '/parts/wheels' },
                        { title: '19"', url: '/parts/wheels' },
                        { title: '20"', url: '/parts/wheels' },
                    ],
                },
            ],
        },
    },
    {
        title: 'GLAZING',
        url: '/parts/glazing',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'MIRRORS',
                    url: '#',
                    submenu: [
                        { title: 'SIDE MIRROR LH', url: '/parts/side-mirror-left' },
                        { title: 'SIDE MIRROR RH', url: '/parts/side-mirror-right' },
                        { title: 'WINDSCREEN FRONT', url: '/parts/windscreen-front' },
                        { title: 'WINDSCREEN REAR', url: '/parts/windscreen-rear' },
                        // { title: 'DOOR GLASS', url: '/parts/door-glass' },
                        { title: 'CENTER MIRROR-REAR VIEW MIRROR', url: '/parts/center-mirror' },
                        { title: 'WIPER SET', url: '/parts/wiper-set' },
                    ],
                },
            ],
        },
    },
    {
        title: 'EXHAUST',
        url: '/parts/exhaust-system',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'FRONT EXHAUST',
                    url: '/parts/exhaust-front',
                    submenu: [
                        // { title: 'CATY-DOWNPIPE', url: '/parts/caty-downpipe' },
                        { title: 'FRONT CATY WITH EXHAUST', url: '/parts/exhaust-front-with-caty' },
                        // { title: 'MID PIPE', url: '/parts/mid-pipe' },
                        { title: 'O2 SENSOR', url: '/parts/o2-sensor' },
                    ],
                },
                {
                    title: 'REAR EXHAUST',
                    url: '#',
                    submenu: [
                        { title: 'SILENCER', url: '/parts/exhaust-rear-silencer' },
                        { title: 'EXHAUST SET', url: '/parts/exhaust-set' },
                        { title: 'REAR EXHAUST', url: '/parts/exhaust-rear-silencer' },
                    ],
                },
            ],
        },
    },

];
