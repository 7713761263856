const brandsData = [
    { name: 'Mercedes Benz', slug: 'mercedes-benz', image: 'images/logos/Mercedes-Benz.png' },
    { name: 'Audi', slug: 'audi', image: 'images/logos/Audi.png' },
    { name: 'Alfa Romeo', slug: 'alfa-romeo', image: 'images/logos/Alfa-Romeo.png' },
    { name: 'Aston Martin', slug: 'aston-martin', image: 'images/logos/Aston-Martin.png' },
    { name: 'BMW', slug: 'bmw', image: 'images/logos/bmw.png' },
    { name: 'Bentley', slug: 'bentley', image: 'images/logos/Bentley.png' },
    { name: 'Ferrari', slug: 'ferrari', image: 'images/logos/Ferrari.png' },
    { name: 'Jeep', slug: 'jeep', image: 'images/logos/Jeep.png' },
    { name: 'Jaguar', slug: 'jaguar', image: 'images/logos/jaguar.png' },
    { name: 'Lamborghini', slug: 'lamborghini', image: 'images/logos/lamborghini.png' },
    { name: 'Lexus', slug: 'lexus', image: 'images/logos/Lexus.png' },
    { name: 'Lotus', slug: 'lotus', image: 'images/logos/Lotus.png' },
    { name: 'Land Rover', slug: 'land-rover', image: 'images/logos/Land-Rover.png' },
    { name: 'Maserati', slug: 'maserati', image: 'images/logos/Maserati.png' },
    { name: 'McLaren', slug: 'mclaren', image: 'images/logos/McLaren.png' },
    { name: 'Mini', slug: 'mini', image: 'images/logos/Mini.png' },
    { name: 'Porsche', slug: 'porsche', image: 'images/logos/porsche.png' },
    { name: 'Peugeot', slug: 'peugeot', image: 'images/logos/Peugeot.png' },
    { name: 'Volkswagen', slug: 'volkswagen', image: 'images/logos/Volkswagen.png' },
    { name: 'Volvo', slug: 'volvo', image: 'images/logos/Volvo.png' },
];

export default brandsData;
