/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
// react
import React, { Component, useMemo } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
// import CategorySidebar from './CategorySidebar';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import PageHeader from '../shared/PageHeader';
// import ProductsView from './ProductsView';
// import { sidebarClose } from '../../store/sidebar';

// data stubs
// import products from '../../data/shopProducts';
import theme from '../../data/theme';
import config from '../../data/configurations';
import shopApi from '../../api/shop';

import 'react-tabs/style/react-tabs.css';

// import axios from 'axios';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
// import WidgetSearch from '../widgets/WidgetSearch';
// import WidgetTags from '../widgets/WidgetTags';

// data stubs
import categories from '../../data/shopCategories';
// eslint-disable-next-line import/no-named-as-default
import CategoryBlock from './ShopCategoryBlock';
import { url } from '../../services/utils';

export default class ShopCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            divData: 'no content',
            categories: null,
            categories1: null,
        };
    }

    componentDidMount() {
        // var access_token = '';
        const url = `${config.production.apiUrl}maincategories`;
        fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // console.log('results set', result);
                    this.setState({
                        isLoaded: true,
                        categories1: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                },
            );
        shopApi.getCategories({ depth: 1 }).then((result) => this.setState({
            categories: result,
        }));
        // console.log('state', this.state);
    }

    render() {
        const {
            error, isLoaded, brands, models, categories, categories1,
        } = this.state; // specs
        const { match: { params } } = this.props;
        const { brandName, modelName } = params;
        // const {
        //     columns,
        //    // viewMode,
        //     //sidebarPosition,
        //     //position,
        // } = this.props;
        // console.log('brandName', brandName);
        // console.log('params', params);
        // console.log('url', url);
        const breadcrumb = [
            { title: 'Home', url: url.home() },
            { title: 'Brands', url: '/brands' },
            // eslint-disable-next-line no-whitespace-before-property
            { title: brandName.toUpperCase(), url: url.brand(brandName) },
            { title: modelName.toUpperCase(), url: modelName },
        // { title: product.name, url: url.product(product) },
        ];

        if (error) {
            return (
                <div className="block-header__title text-center">
                    Error:
                    {error.message}
                </div>
            );
        } if (!isLoaded) {
            return <div className="block-header__title text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true" /></div>;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{` ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="" breadcrumb={breadcrumb} />
                <div>
                    <div className="container p-0">

                        {/* <Tabs
                            forceRenderTabPanel
                            defaultIndex={defaultIndex}
                            selectedTabClassName="tab-active"
                        >
                            <h4>BRANDS</h4>
                            <TabList>
                                {brands.map((item) => (
                                    <Tab
                                        key={item.Cat1ID}
                                        // className={item.Cat1ID === 18 ? 'tab-active' : ''}
                                    >
                                        {' '}
                                        {item.Description}
                                    </Tab>
                                ))}
                            </TabList>
                            {brands.map((item) => (

                                <TabPanel key={item.Cat1ID}>
                                    <Tabs
                                        forceRenderTabPanel
                                        selectedTabClassName="tab-panel-active"
                                    >
                                        <h4>MODELS</h4>
                                        <TabList>
                                            {models.filter((model) => model.Cat1ID === item.Cat1ID).map((model) => (
                                                <Tab key={model.Cat2ID}>
                                                    {' '}
                                                    {model.Description}
                                                </Tab>
                                            ))}
                                        </TabList>
                                        {models.filter((model) => model.Cat1ID === item.Cat1ID).map((model) => (
                                            <TabPanel key={`${item.Cat1ID}_${model.Cat2ID}`}>
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-12">
                                                        <b>
                                                            {item.Description}
                                                            {' '}
                                                            -
                                                            {' '}
                                                            {model.Description}  Parts
                                                        </b>
                                                        <CategoryBlock
                                                            brand={item}
                                                            category={model}
                                                            categories={categories}
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        ))}

                                    </Tabs>
                                </TabPanel>
                            ))}

                                        </Tabs> */}
                        <CategoryBlock
                            brand={brandName}
                            model={modelName}
                            categories={categories}
                            categories1={categories1}
                        />

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

ShopCategories.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopCategories.defaultProps = {
    columns: 3,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

// const mapStateToProps = (state) => ({
//     sidebarState: state.sidebar,
// });

// const mapDispatchToProps = {
//     sidebarClose,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(ShopCategories);
