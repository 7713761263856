/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable */
import qs from 'query-string';
import base64 from 'react-native-base64';
import { getCategories, getCategoryBySlug } from '../fake-server/endpoints/categories';
import {
    getDiscountedProducts,
    getFeaturedProducts,
    getLatestProducts,
    getPopularProducts,
    getProductBySlug,
    getProductsList,
    getRelatedProducts,
    getSuggestions, getTopRatedProducts, getSearchProducts,
    getOrderSuccessList
} from '../fake-server/endpoints/products';
import config from '../data/configurations';
import CategoryFilterBuilder from '../fake-server/filters/category';
import CheckFilterBuilder from '../fake-server/filters/check';
import productsData from '../fake-server/database/products';
import RadioFilterBuilder from '../fake-server/filters/range';
import ColorFilterBuilder from '../fake-server/filters/color';
import RangeFilterBuilder from '../fake-server/filters/price';
const shopApi = {
    /**
     * Returns array of categories.
     *
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<Array<object>>}
     */
    getCategories: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getCategories(options).then((data) => {
            // console.log('getCategories data', data);

            return data;
        });
    },
    /**
     * Returns array of categories.
     *
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<Array<object>>}
     */
    getCategoriesLive: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getCategories(options).then((data) => {
            // console.log('getCategories data', data);

            return data;
        });
    },
    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    getCategoryBySlug: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getCategoryBySlug(slug, options).then((data) => {
            console.log('getCategoryBySlug data', data);

            return data;
        });
    },
    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    getCategoryBySlugLive: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        const params = { ...options };

        Object.keys(options).forEach((slug) => {
            params[`filter_${slug}`] = options[slug];
        });
        const url = `${config.production.apiUrl}categories?stock=true&${qs.stringify(params)}`;
        return fetch(`${url}`)
            .then((response) => response.json());
        //  .then((result) => console.log('getCategoryBySlug live response', result));

        // return getCategoryBySlug(slug, options).then((data) => {
        //     console.log('getCategoryBySlug data', data);

        //     return data;
        // });
    },
    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @return {Promise<object>}
     */
    getProductBySlug: (slug) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/screwdriver-a2017.json
         *
         * where:
         * - screwdriver-a2017 = slug
         */
        // return fetch(`https://example.com/api/products/${slug}.json`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getProductBySlug(slug).then((data) => {
        //     // console.log('getProductBySlug data', data);

        //     return data;
        // });

        const params = { ...slug };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}allproducts/${slug}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Returns array of related products.
     *
     * @param {string}  slug
     * @param {object?} options
     * @param {number?} options.limit
     *
     * @return {Promise<Array<object>>}
     */
    getRelatedProducts: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
         *
         * where:
         * - screwdriver-a2017 = slug
         * - limit             = options.limit
         */
        // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getRelatedProducts(slug, options).then((data) => {
        //     // console.log('getRelatedProducts data', data);

        //     return data;
        // });

        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}topproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getProductsList: (options = {}, filters = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
         *
         * where:
         * - page            = options.page
         * - limit           = options.limit
         * - sort            = options.sort
         * - filter_category = filters.category
         * - filter_price    = filters.price
         */
        const params = { ...options };

        Object.keys(filters).forEach((slug) => {
            params[`filter_${slug}`] = filters[slug];
        });
        // const url = `${config.production.apiUrl}search?stock=true&${qs.stringify(params)}`;
        const url = `${config.production.apiUrl}allproducts?stock=true&${qs.stringify(params)}`;
        const productsDef1 = null;

        return fetch(`${url}`, {
            method: 'GET',
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                // 'API-Key': 'YkRAX5bOdIXLggv2XfSQsjnJln9BaIzA',
                // 'Authorization': `token ${access_token}`
            },
        }).then((response) => response.json());
        // return getProductsList(options, filters).then((data) => {
        //     console.log('getProductsList data', data);

        //     return data;
        // });
    },

    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getFilterProductsList: async (options = {}, filters = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
         *
         * where:
         * - page            = options.page
         * - limit           = options.limit
         * - sort            = options.sort
         * - filter_category = filters.category
         * - filter_price    = filters.price
         */
        const params = { ...options };

        Object.keys(filters).forEach((slug) => {
            params[`filter_${slug}`] = filters[slug];
        });
        const url = `${config.production.apiUrl}allproducts?stock=true&${qs.stringify(params)}`;
        const productsDef1 = null;

        // return fetch(`${url}`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        // }).then((response) => response.json());

        const customfilters = [
            new CategoryFilterBuilder('category', 'Categories'),
            new CheckFilterBuilder('brand', 'Brand'),
            // new RangeFilterBuilder('price', 'Price'),
            //  new RadioFilterBuilder('discount', 'Discount'),
            // new ColorFilterBuilder('color', 'Color'),
        ];

        const items = await fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            // eslint-disable-next-line consistent-return
            .then((result) => {
                // eslint-disable-next-line max-len
                console.log('filters', filters);
                customfilters.forEach((filter) => filter.makeItems(result, filters[filter.slug]));
                // Calculate items count for filter values.
                customfilters.forEach((filter) => filter.calc(result, customfilters));
                // Apply filters to products list.
                let list = result;
                if (list) {
                    // list = list.filter(
                    //     (product) => customfilters.reduce((mr, filter) => mr && filter.test(product), true),
                    // );
                    const page = options.page || 1;
                    const limit = options.limit || 12;
                    const sort = options.sort || 'default';
                    const total = list.length;
                    const pages = Math.ceil(total / limit);
                    const from = (page - 1) * limit + 1;
                    const to = Math.max(Math.min(page * limit, total), from);
                    // list = list.sort((a, b) => {
                    //     if (['name_asc', 'name_desc'].includes(sort)) {
                    //         if (a.name === b.name) {
                    //             return 0;
                    //         }

                    //         return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
                    //     }

                    //     return 0;
                    // });
                    const start = (page - 1) * limit;
                    const end = start + limit;

                    list = list.slice(start, end);
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve({
                                page,
                                limit,
                                sort,
                                total,
                                pages,
                                from,
                                to,
                                list,
                                filters: customfilters.map((x) => x.build()),
                            });
                        }, 350);
                    });
                }
            }); // productsData.slice(0);
        return items;
        // return getProductsList(options, filters).then((data) => {
        //     console.log('getProductsList data', data);

        //     return data;
        // });
    },
    /**
     * Returns array of featured products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getFeaturedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getFeaturedProducts(options).then((data) => {
        //     // console.log('getFeaturedProducts data', data);

        //     return data;
        // });

        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}topproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Returns array of latest products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getLatestProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getLatestProducts(options).then((data) => {
        //     // console.log('getLatestProducts data', data);

        //     return data;
        // });
        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}topproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Returns an array of top rated products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getTopRatedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getTopRatedProducts(options).then((data) => {
        //     console.log('getTopRatedProducts data', data);

        //     return data;
        // });

        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}topproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Returns an array of discounted products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getDiscountedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getDiscountedProducts(options).then((data) => {
        //     // console.log('getDiscountedProducts data', data);

        //     return data;
        // });
        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}topproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getPopularProducts(options).then((data) => {
        //     console.log('getPopularProducts data', data);

        //     return data;
        // });

        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}popularproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Returns search suggestions.
     *
     * @param {string}  query
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSuggestions: (query, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getSuggestions(query, options).then((data) => {
        //     // console.log('getSuggestions data', data);

        //     return data;
        // });

        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}topproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },

    /**
     * Returns search values.
     *
     * @param {string}  query
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSearchProducts: (query, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getSearchProducts(query, options).then((data) => {
        //     // console.log('getSearchProducts data', data);

        //     return data;
        // });

        const params = { ...options };

        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        const url = `${config.production.apiUrl}searchproducts?stock=true&${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    },
    /**
     * Return rate list.
     *
     * @param {object?} data
     *
     * @return {Promise<object>}
     */
    getShippingRates: (data, items) => {
        const qData = {
            accountNumber: config.production.accountNumber,
            originCountryCode: config.production.originCountryCode,
            originPostalCode: config.production.originPostalCode,
            originCityName: config.production.originCityName,
            destinationCountryCode: data.country,
            destinationPostalCode: data.postcode,
            destinationCityName: data.city,
            height: items.height,
            length: items.itemlength,
            width: items.width,
            weight: items.weight,
            plannedShippingDate: new Date().toJSON().slice(0, 10),
            isCustomsDeclarable: false,
            unitOfMeasurement: 'metric',
            requestEstimatedDeliveryDate: true,
        };
        const params = { ...qData };
        const url = `${config.production.apiUrl}shippingRates?${qs.stringify(params)}`;

        return fetch(`${url}`, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
        // fetch(`${url}`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        // }).then((response) => {
        //     return new Promise((resolve) => {
        //         setTimeout(() => {
        //             resolve({
        //                 response,
        //             });
        //         }, 50);
        //     });
        // });

        // const url = `${config.production.ShippingAPI}?${qs.stringify(params)}`; // ?${qs.stringify(params)}
        // const auth = base64.encode(`${config.production.userName}:${config.production.passWord}`);
        // console.log('AUTH', auth);
        // const options = {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //          Authorization: `Basic ${base64.encode(`${config.production.userName}:${config.production.passWord}`)}`,
        //          // Authorization: 'Basic YXBBMWFDMmtPNmJFNWk6Q14wdlhANmlOQDZxVUAybA==',
        //     },
        //    // redirect: 'follow',
        //     mode: 'no-cors',
        //   };
        //  return fetch(url, options)
        //       .then((res) => res.json())
        //       .then((json) => console.log('json', json))
        //       .catch((err) => console.error(`error:${err}`));
    },

    // order success api
    /**
     * Returns product.
     *
     * @param {string} orderId
     * @param {object?} options
     */
    
    getOrderSuccessList: (options = {}) => {
        return fetch("http://localhost:5000/orderDetails.json", {
            method: 'GET',
            "mode": 'no-cors',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => response.json())
            .then(data => {
                // console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
};

export default shopApi;
