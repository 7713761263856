/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import config from './configurations';

/**
 *-----------Get Available Brands----------
 *
 * @export
 */
export function listData(setAllData) {
    const url = `${config.production.apiUrl}select/list`;
   // console.log(url);
    const head = {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            apikey: `${config.production.apikey}`,
            apiconnection: `${config.production.apiconnection}`,
        },
    };
    axios(url, head).then((res) => {
        const datas = res.data;
        // console.log('response', res);
        // console.log('listBrandsResponse', brands);
        if (res.status === 200) {
            setAllData(datas);
        }
    }).catch((error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }
        // console.log(error.config);
    });
}
