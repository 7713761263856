/* eslint-disable no-console */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// import { render } from "react-dom";
import InfiniteScroll1 from 'react-infinite-scroll-component';

// Application
// import classNames from 'classnames';
import PropTypes from 'prop-types';

// 3rd Party
import ProductCard from '../shared/ProductCard';

export default class BlockInfiniteScroll extends Component {
  state = {
      products: this.props.products.slice(0, 5),
      hasMore: true,
  };

  fetchMoreProducts = () => {
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line max-len
      if ((this.state.products.length >= this.props.products.length) && (this.state.products.length !== 0)) { // this.props.products.length
          this.setState({ hasMore: false });
          return;
      }
      setTimeout(() => {
          this.setState(
              ({ products: this.props.products.slice(0, this.state.products.length + 8) }),
          );
          this.setState({ hasMore: true });
      }, 2000);
  };

  render() {
      // const {
      //   layout,
      //   title,
      //   withSidebar,
      //   onGroupClick,
      //   groups,
      //   loading,
      // } = this.props;

      return (
          <div className="container infinityscroll">
              <InfiniteScroll1
                  dataLength={this.state.products.length}
                  next={this.fetchMoreProducts}
                  hasMore={this.state.hasMore}
                  loader={<div className="block-header__title text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true" /></div>}
                  pageStart={0}
                  endMessage={(
                      <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                      </p>
                  )}
              >
                  <div className="block" style={{ overflow: 'hidden' }}>
                      <div className="products-view">
                          <div className="products-view__content">
                              <div className="products-view__list products-list" data-layout="grid-5-full">
                                  <div className="products-list__body">
                                      {
                                          this.state.products.map((product, index) => (
                                              <div key={index} className="products-list__item">
                                                  <ProductCard product={product} />
                                              </div>
                                          ))
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </InfiniteScroll1>
          </div>
      );
  }
}

BlockInfiniteScroll.propTypes = {
    // title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-5']),
    rows: PropTypes.number,
    products: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func,
};
BlockInfiniteScroll.defaultProps = {
    layout: 'grid-5',
    rows: 1,
    products: [],
    groups: [],
    withSidebar: false,
    loading: false,
    onGroupClick: undefined,
};
