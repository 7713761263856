/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

export default [
    // {
    //     key: 'bank',
    //     title: 'Direct bank transfer',
    //     description: 'Make your payment directly into our bank account. Please use your Order ID as the payment reference. '
    //         + 'Your order will not be shipped until the funds have cleared in our account.',
    //     content: <button type="submit" className="btn btn-primary btn-xl btn-block bg-red-color">Place Order</button>,
    // },
    // {
    //     key: 'check',
    //     title: 'Check payments',
    //     description: 'Please send a check to Store Name, Store Street, Store Town, Store State County, Store Postcode.',
    // },
    // {
    //     key: 'cash',
    //     title: 'Cash on delivery',
    //     description: 'Pay with cash upon delivery.',
    // },
    {
        key: 'paynow',
        title: 'Paynow',
        description: 'Pay via Paynow; you can pay with your cards, alipay, etc., ',
        // eslint-disable-next-line no-undef
        content: '',
    },
    // {
    //     key: 'paypal',
    //     title: 'PayPal',
    //     description: 'Pay via PayPal; you can pay with your cards if you don’t have a PayPal account.',
    //     content: '',
    // },
];
