// react
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import config from '../../data/configurations';

export default function AccountPagePassword() {
    const [values, setValues] = useState('');
    const [txt, changeTxt] = useState('Change');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    }

    const changePassword = (e) => {
        const data = {
            userId: 1,
            currentPassword: values.currentpassword,
            newPassword: values.newpassword,
            reenterPassword: values.resetpwd
        };
        changeTxt("Updated...");
        toast.success("Password changed Successful");
        fetch(config.production + '/password', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data)

        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // changeTxt("Updated");
                    // toast.success("Password changed Successful");
                    console.log('success results', result);
                })
            .catch((err) => {
                toast.error(err);
            })
    }
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
    };
    const [isVisible1, setVisible1] = useState(false);
    const toggle1 = () => {
        setVisible1(!isVisible1);
    };
    const [isVisible2, setVisible2] = useState(false);
    const toggle2 = () => {
        setVisible2(!isVisible2);
    };
    return (
        <div className="card" style={{ diplay: 'block !important', width: '70%' }}>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-current">Current Password</label>
                            <input
                                type={!isVisible ? "password" : "text"}
                                className="form-control"
                                id="password-current"
                                placeholder="Current Password"
                                value={values.currentpassword}
                                name="currentpassword"
                                onChange={handleInputChange}
                                required
                            />
                            <span className="icon" onClick={toggle} style={{position:"absolute",top:"11%",left:"87%"}}>
                                {isVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                            </span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-new">New Password</label>
                            <input
                                type={!isVisible1 ? "password" : "text"}
                                className="form-control"
                                id="password-new"
                                placeholder="New Password"
                                value={values.newpassword}
                                onChange={handleInputChange}
                                name="newpassword"
                                required
                            />
                            <span className="icon" onClick={toggle1} style={{position:"absolute",top:"39%",left:"87%"}}>
                                {isVisible1 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                            </span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm">Reenter New Password</label>
                            <input
                                type={!isVisible2 ? "password" : "text"}
                                className="form-control"
                                id="password-confirm"
                                value={values.resetpwd}
                                onChange={handleInputChange}
                                name="resetpwd"
                                placeholder="Reenter New Password"
                                required
                            />
                            <span className="icon" onClick={toggle2} style={{position:"absolute",top:"66%",left:"87%"}}>
                                {isVisible2 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                            </span>
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" className="btn btn-primary" onClick={changePassword}>{txt}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
