const brandList = [
    {
        id: 1,
        name: 'Mercedes Benz',
        slug: 'mercedes-benz',
        image: 'data/brand/Mercedes-Benz.png',
        models: [
            {
                name: 'A Class',
                slug: 'a-class',
                image: 'data/brand/mercedes/aclass.jpg',
                children: [
                    {
                        name: 'W168',
                        slug: 'w168',
                        year: '(1997-2004)',
                        catid: [2],
                    },
                    {
                        name: 'W169',
                        slug: 'w169',
                        year: '(2004-2012)',
                        catid: [3],
                    },
                    {
                        name: 'W176',
                        slug: 'w176',
                        year: '(2013-2018)',
                        catid: [4],
                    },
                    {
                        name: 'W177',
                        slug: 'w177',
                        year: '(2018-now)',
                        catid: [343],
                    },
                ],
            },
            {
                name: 'B Class',
                slug: 'b-class',
                image: 'data/brand/mercedes/bclass.jpg',
                children: [
                    {
                        name: 'W245',
                        slug: 'w245',
                        year: '(2005-2011)',
                        catid: [5],
                    },
                    {
                        name: 'W246',
                        slug: 'w246',
                        year: '(2011-2019)',
                        catid: [6],
                    },
                    //   {
                    //       name: 'W247',
                    //       slug: 'w247',
                    //       year: '(2018-now)',
                    //   },
                ],
            },
            {
                name: 'C Class',
                slug: 'c-class',
                image: 'data/brand/mercedes/cclass.jpg',
                children: [
                    {
                        name: 'W202',
                        slug: 'w202',
                        year: '(1993-2000)',
                        catid: [8],
                    },
                    {
                        name: 'W203',
                        slug: 'w203',
                        year: '(2000-2007)',
                        catid: [9],
                    },
                    {
                        name: 'W204',
                        slug: 'w204',
                        year: '(2007-2015)',
                        catid: [10],
                    },
                    {
                        name: 'W205',
                        slug: 'w205',
                        year: '(2014-2021)',
                        catid: [280],
                    },
                    //   {
                    //       name: 'W206',
                    //       slug: 'w206',
                    //       year: '(2021-now)',
                    //  },
                ],
            },
            {
                name: 'E Class',
                slug: 'e-class',
                image: 'data/brand/mercedes/eclass.jpg',
                children: [
                    {
                        name: 'W124',
                        slug: 'w124',
                        year: '(1984-1995)',
                        catid: [14],
                    },
                    {
                        name: 'W210',
                        slug: 'w210',
                        year: '(1996-2002)',
                        catid: [15],
                    },
                    {
                        name: 'W211',
                        slug: 'w211',
                        year: '(2003-2009)',
                        catid: [16],
                    },
                    {
                        name: 'W212',
                        slug: 'w212',
                        year: '(2010-2016)',
                        catid: [17],
                    },
                    {
                        name: 'W212 FL',
                        slug: 'w212-fl',
                        year: '(2010-2016)',
                        catid: [333],
                    },
                    {
                        name: 'W213',
                        slug: 'w213',
                        year: '(2017-now)',
                        catid: [298],
                    },
                ],
            },
            {
                name: 'G Class',
                slug: 'g-class',
                image: 'images/no-image.jpg',
                children: [
                    //   {
                    //       name: 'W461',
                    //       slug: 'w461',
                    //       year: '(1992-now)',
                    //   },
                    {
                        name: 'W463',
                        slug: 'w463',
                        year: '(1990-now)',
                        catid: [331],
                    },
                ],
            },
            {
                name: 'CLA Class',
                slug: 'cla-class',
                image: 'data/brand/mercedes/claclass.jpg',
                children: [
                    {
                        name: 'W117',
                        slug: 'w117',
                        year: '(2013-2019)',
                        catid: [284],
                    },
                    {
                        name: 'W118',
                        slug: 'w118',
                        year: '(2019-now)',
                        catid: [339],
                    },
                ],
            },
            {
                name: 'CLK Class',
                slug: 'clk-class',
                image: 'data/brand/mercedes/clkclass.jpg',
                children: [
                    {
                        name: 'W208',
                        slug: 'w208',
                        year: '(1997-2003)',
                        catid: [201],
                    },
                    {
                        name: 'W209',
                        slug: 'w209',
                        year: '(2003-2010)',
                        catid: [190],
                    },
                    {
                        name: 'C209',
                        slug: 'c209',
                        year: '(2002-2010)',
                        catid: [42],
                    },
                ],
            },
            {
                name: 'CLS Class',
                slug: 'cls-class',
                image: 'data/brand/mercedes/clsclass.jpg',
                children: [
                    {
                        name: 'W219',
                        slug: 'w219',
                        year: '(2004-2010)',
                        catid: [48],
                    },
                    {
                        name: 'W218',
                        slug: 'w218',
                        year: '(2010-2018)',
                        catid: [49],
                    },
                    {
                        name: 'W257',
                        slug: 'w257',
                        year: '(2018-now)',
                        catid: [341],
                    },
                ],
            },
            {
                name: 'GLA Class',
                slug: 'gla-class',
                image: 'data/brand/mercedes/glaclass.jpg',
                children: [
                    {
                        name: 'W156',
                        slug: 'w156',
                        year: '(2013-2020)',
                        catid: [299],
                    },
                ],
            },
            {
                name: 'GLC Class',
                slug: 'glc-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W253',
                        slug: 'w253',
                        year: '(2015-now)',
                        catid: [300],
                    },
                ],
            },
            {
                name: 'GLE Class',
                slug: 'gle-class',
                image: 'data/brand/mercedes/gleclass.jpg',
                children: [
                    //   {
                    //       name: 'W163',
                    //       slug: 'w163',
                    //       year: '(1997-2005)',
                    //   },
                    {
                        name: 'W164',
                        slug: 'w164',
                        year: '(2006-2011)',
                        catid: [191],
                    },
                    {
                        name: 'W166',
                        slug: 'w166',
                        year: '(2011-2018)',
                        catid: [242],
                    },
                    //   {
                    //       name: 'W167',
                    //       slug: 'w167',
                    //       year: '(2019-now)',
                    //   },
                ],
            },
            {
                name: 'SLS Class',
                slug: 'sls-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W197',
                        slug: 'w197',
                        year: '(2006-2015)',
                        catid: [323],
                    },
                ],
            },
            //   {
            //       name: 'SLR Class',
            //       slug: 'slr-class',
            //       image: 'images/no-image.jpg',
            //       children: [
            //           {
            //               name: 'W199',
            //               slug: 'w199',
            //               year: '(2003-2009)',
            //           },
            //       ],
            //   },
            {
                name: 'SLK Class',
                slug: 'slk-class',
                image: 'data/brand/mercedes/slkclass.jpg',
                children: [
                    {
                        name: 'W170',
                        slug: 'w170',
                        year: '(1996-2004)',
                        catid: [200],
                    },
                    {
                        name: 'W171',
                        slug: 'w171',
                        year: '(2004-2010)',
                        catid: [207],
                    },
                    {
                        name: 'W172',
                        slug: 'w172',
                        year: '(2011-2020)',
                        catid: [317],
                    },
                ],
            },
            {
                name: 'SL Class',
                slug: 'sl-class',
                image: 'data/brand/mercedes/slclass.jpg',
                children: [
                    {
                        name: 'W107',
                        slug: 'w107',
                        year: '(1971-1989)',
                        catid: [210],
                    },
                    {
                        name: 'W129',
                        slug: 'w129',
                        year: '(1989-2001)',
                        catid: [224],
                    },
                    {
                        name: 'W230',
                        slug: 'w230',
                        year: '(2001-2012)',
                        catid: [270],
                    },
                    {
                        name: 'R230',
                        slug: 'r230',
                        year: '(2001-2012)',
                        catid: [59],
                    },
                    //   {
                    //       name: 'W231',
                    //       slug: 'w231',
                    //       year: '(2012-now)',
                    //   },
                ],
            },
            {
                name: 'S Class',
                slug: 's-class',
                image: 'data/brand/mercedes/sclass.jpg',
                children: [
                    {
                        name: 'W126',
                        slug: 'w126',
                        year: '(1980-1991)',
                        catid: [24],
                    },
                    {
                        name: 'W140',
                        slug: 'w140',
                        year: '(1991-1998)',
                        catid: [25],
                    },
                    {
                        name: 'W220',
                        slug: 'w220',
                        year: '(1998-2005)',
                        catid: [26],
                    },
                    {
                        name: 'W221',
                        slug: 'w221',
                        year: '(2005-2013)',
                        catid: [27],
                    },
                    {
                        name: 'W222',
                        slug: 'w222',
                        year: '(2013-2020)',
                        catid: [28],
                    },
                    //   {
                    //       name: 'W223',
                    //       slug: 'w223',
                    //       year: '(2020-now)',
                    //   },
                ],
            },
            {
                name: 'S Coupe Class',
                slug: 's-coupe-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'C215',
                        slug: 'c215',
                        year: '(1999-2006)',
                        catid: [52],
                    },
                    //   {
                    //       name: 'W216',
                    //       slug: 'w216',
                    //       year: '(2006-2013)',
                    //   },
                    //   {
                    //       name: 'W217',
                    //       slug: 'w217',
                    //       year: '(2014-now)',
                    //   },
                ],
            },
            {
                name: 'R Class',
                slug: 'r-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W251',
                        slug: 'w251',
                        year: '(2005-2017)',
                        catid: [249],
                    },
                ],
            },
            {
                name: 'GLE Coupe Class',
                slug: 'gle-coupe-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W292',
                        slug: 'w292',
                        year: '(2015-2019)',
                        catid: [324],
                    },
                ],
            },
            //   {
            //       name: 'AMG GT Class',
            //       slug: 'amg-gt-class',
            //       image: 'images/no-image.jpg',
            //       children: [
            //           {
            //               name: 'W290',
            //              slug: 'W290',
            //               year: '(2018-now)',
            //           },
            //           {
            //               name: 'W190',
            //               slug: 'W190',
            //               year: '(2014-now)',
            //           },
            //       ],
            //   },
            {
                name: 'V Class',
                slug: 'v-class',
                image: 'images/no-image.jpg',
                children: [
                    //   {
                    //       name: 'W638',
                    //       slug: 'w638',
                    //       year: '(1996-2003)',
                    //   },
                    {
                        name: 'W639',
                        slug: 'w639',
                        year: '(2003-2014)',
                        catid: [216],
                    },
                    //   {
                    //       name: 'W447',
                    //       slug: 'w447',
                    //       year: '(2014-now)',
                    //   },
                ],
            },
            {
                name: 'E Coupe Class',
                slug: 'e-coupe-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W207',
                        slug: 'w207',
                        year: '(2009-2017)',
                        catid: [293],
                    },
                    {
                        name: 'C207',
                        slug: 'c207',
                        year: '(2009-2017)',
                        catid: [43],
                    },
                ],
            },
            {
                name: 'Compact Class',
                slug: 'compact-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W201',
                        slug: 'w201',
                        year: '(1982-1993)',
                        catid: [7],
                    },
                ],
            },
            {
                name: 'Executive Class',
                slug: 'executive-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W123',
                        slug: 'w123',
                        year: '(1976-1986)',
                        catid: [231],
                    },
                ],
            },
            {
                name: 'E Coupe Class',
                slug: 'e-coupe-class',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'W238',
                        slug: 'w238',
                        year: '(2017-now)',
                        catid: [345],
                    },
                ],
            },
            {
                name: 'OTHER Class',
                slug: 'other-class',
                image: 'images/no-image.jpg',
                catid: [215],
                children: [
                    {
                        name: '450 SL',
                        slug: '450-sl',
                        year: '(1995-2014)',
                        catid: [1167],
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        name: 'BMW',
        slug: 'bmw',
        image: 'data/brand/bmw.png',
        models: [
            {
                name: '1 Series',
                slug: '1-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'E81',
                        slug: 'e81',
                        year: '(2004-2013)',
                        catid: [70],
                    },
                    {
                        name: 'E82',
                        slug: 'e82',
                        year: '(2004-2013)',
                        catid: [65],
                    },
                    {
                        name: 'E87',
                        slug: 'e87',
                        year: '(2004-2013)',
                        catid: [63],
                    },
                    {
                        name: 'E87 LCI',
                        slug: 'e87-lci',
                        year: '(2004-2013)',
                        catid: [64],
                    },
                    {
                        name: 'E88',
                        slug: 'e88',
                        year: '(2004-2013)',
                        catid: [67],
                    },
                    {
                        name: 'F20',
                        slug: 'f20',
                        year: '(2011-2019)',
                        catid: [68],
                    },
                    {
                        name: 'F20 LCI',
                        slug: 'f20-lci',
                        year: '(2011-2019)',
                        catid: [337],
                    },
                    //   {
                    //       name: 'F21',
                    //       slug: 'f21',
                    //       year: '(2011-2019)',
                    //   },
                    //   {
                    //       name: 'F40',
                    //       slug: 'f40',
                    //       year: '(2019-now)',
                    //   },
                ],
            },
            {
                name: '2 Series',
                slug: '2-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'F22',
                        slug: 'f22',
                        year: '(2014-now)',
                        catid: [71],
                    },
                    //   {
                    //       name: 'F23',
                    //       slug: 'f23',
                    //       year: '(2014-now)',
                    //   },
                    {
                        name: 'F45',
                        slug: 'f45',
                        year: '(2014-now)',
                        catid: [226],
                    },
                    {
                        name: 'F45 LCI',
                        slug: 'f45-lci',
                        year: '(2014-now)',
                        catid: [334],
                    },
                    {
                        name: 'F46',
                        slug: 'f46',
                        year: '(2014-now)',
                        catid: [277],
                    },
                    //   {
                    //       name: 'F44',
                    //       slug: 'f44',
                    //       year: '(2019-now)',
                    //   },
                ],
            },
            {
                name: '3 Series',
                slug: '3-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'E30',
                        slug: 'e30',
                        year: '(1982-1994)',
                        catid: [199],
                    },
                    {
                        name: 'E36',
                        slug: 'e36',
                        year: '(1990-2000)',
                        catid: [72],
                    },
                    {
                        name: 'E46',
                        slug: 'e46',
                        year: '(1997-2006)',
                        catid: [73],
                    },
                    {
                        name: 'E90',
                        slug: 'e90',
                        year: '(2004-2013)',
                        catid: [74],
                    },
                    {
                        name: 'E90 LCI',
                        slug: 'e90-lci',
                        year: '(2004-2013)',
                        catid: [75],
                    },
                    {
                        name: 'E91',
                        slug: 'e91',
                        year: '(2004-2013)',
                        catid: [76],
                    },
                    {
                        name: 'E91 LCI',
                        slug: 'e91-lci',
                        year: '(2004-2013)',
                        catid: [77],
                    },
                    {
                        name: 'E92',
                        slug: 'e92',
                        year: '(2004-2013)',
                        catid: [78],
                    },
                    {
                        name: 'E92 LCI',
                        slug: 'e92-lci',
                        year: '(2004-2013)',
                        catid: [79],
                    },
                    {
                        name: 'E93',
                        slug: 'e93',
                        year: '(2004-2013)',
                        catid: [80],
                    },
                    {
                        name: 'E93 LCI',
                        slug: 'e93-lci',
                        year: '(2004-2013)',
                        catid: [81],
                    },
                    {
                        name: 'F30',
                        slug: 'f30',
                        year: '(2011-2019)',
                        catid: [82],
                    },
                    {
                        name: 'F30 LCI',
                        slug: 'f30-lci',
                        year: '(2011-2019)',
                        catid: [316],
                    },
                    //   {
                    //       name: 'F31',
                    //       slug: 'f31',
                    //       year: '(2011-2019)',
                    //   },
                    //   {
                    //       name: 'F34',
                    //       slug: 'f34',
                    //       year: '(2011-2019)',
                    //   },
                    //   {
                    //       name: 'G20',
                    //       slug: 'g20',
                    //       year: '(2018-now)',
                    //   },
                ],
            },
            {
                name: '4 Series',
                slug: '4-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'F32',
                        slug: 'f32',
                        year: '(2013-2020)',
                        catid: [87],
                    },
                    //   {
                    //       name: 'F33',
                    //       slug: 'f33',
                    //       year: '(2013-2020)',
                    //   },
                    {
                        name: 'F36',
                        slug: 'f36',
                        year: '(2013-2020)',
                        catid: [90],
                    },
                    //   {
                    //       name: 'G22',
                    //       slug: 'g22',
                    //       year: '(2020-now)',
                    //   },
                    //   {
                    //       name: 'G23',
                    //       slug: 'g23',
                    //       year: '(2020-now)',
                    //   },
                ],
            },
            {
                name: '5 Series',
                slug: '5-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'E28',
                        slug: 'e28',
                        year: '(1981-1988)',
                        catid: [223],
                    },
                    {
                        name: 'E34',
                        slug: 'e34',
                        year: '(1988-1996)',
                        catid: [219],
                    },
                    {
                        name: 'E39',
                        slug: 'e39',
                        year: '(1995-2003)',
                        catid: [91],
                    },
                    {
                        name: 'E60',
                        slug: 'e60',
                        year: '(2003-2010)',
                        catid: [92],
                    },
                    {
                        name: 'E60 LCI',
                        slug: 'e60-lci',
                        year: '(2003-2010)',
                        catid: [93],
                    },
                    {
                        name: 'E61 LCI',
                        slug: 'e61-lci',
                        year: '(2003-2010)',
                        catid: [95],
                    },
                    {
                        name: 'F10',
                        slug: 'f10',
                        year: '(2010-2017)',
                        catid: [98],
                    },
                    {
                        name: 'F10 LCI',
                        slug: 'f10-lci',
                        year: '(2010-2017)',
                        catid: [99],
                    },
                    {
                        name: 'F11',
                        slug: 'f11',
                        year: '(2010-2017)',
                        catid: [100],
                    },
                    {
                        name: 'F07 GT',
                        slug: 'f07-gt',
                        year: '(2010-2017)',
                        catid: [96],
                    },
                    {
                        name: 'G30',
                        slug: 'g30',
                        year: '(2016-now)',
                        catid: [283],
                    },
                    //   {
                    //       name: 'G31',
                    //       slug: 'g31',
                    //       year: '(2016-now)',
                    //   },
                ],
            },
            {
                name: '6 Series',
                slug: '6-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'E24',
                        slug: 'e24',
                        year: '(1976-1989)',
                        catid: [212],
                    },
                    {
                        name: 'E63',
                        slug: 'e63',
                        year: '(2003-2010)',
                        catid: [104],
                    },
                    {
                        name: 'E63 LCI',
                        slug: 'e63-lci',
                        year: '(2003-2010)',
                        catid: [105],
                    },
                    {
                        name: 'E64',
                        slug: 'e64',
                        year: '(2003-2010)',
                        catid: [106],
                    },
                    {
                        name: 'E64 LCI',
                        slug: 'e64-lci',
                        year: '(2003-2010)',
                        catid: [107],
                    },
                    {
                        name: 'F06 GC',
                        slug: 'f06-gc',
                        year: '(2011-2018)',
                        catid: [108],
                    },
                    {
                        name: 'F12',
                        slug: 'f12',
                        year: '(2011-2018)',
                        catid: [109],
                    },
                    {
                        name: 'F13',
                        slug: 'f13',
                        year: '(2011-2018)',
                        catid: [110],
                    },
                    //   {
                    //       name: 'G32',
                    //       slug: 'g32',
                    //       year: '(2017-now)',
                    //   },
                ],
            },
            {
                name: '7 Series',
                slug: '7-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'E23',
                        slug: 'e23',
                        year: '(1977-1987)',
                        catid: [235],
                    },
                    {
                        name: 'E32',
                        slug: 'e32',
                        year: '(1986-1994)',
                        catid: [234],
                    },
                    {
                        name: 'E38',
                        slug: 'e38',
                        year: '(1994-2001)',
                        catid: [111],
                    },
                    {
                        name: 'E65',
                        slug: 'e65',
                        year: '(2001-2008)',
                        catid: [112],
                    },
                    {
                        name: 'E66',
                        slug: 'e66',
                        year: '(2001-2008)',
                        catid: [113],
                    },
                    {
                        name: 'E67',
                        slug: 'e67',
                        year: '(2001-2008)',
                        catid: [114],
                    },
                    //   {
                    //       name: 'E68',
                    //       slug: 'e68',
                    //       year: '(2001-2008)',
                    //   },
                    {
                        name: 'F01',
                        slug: 'f01',
                        year: '(2008-2015)',
                        catid: [115],
                    },
                    {
                        name: 'F01 LCI',
                        slug: 'f01-lci',
                        year: '(2008-2015)',
                        catid: [116],
                    },
                    {
                        name: 'F02',
                        slug: 'f02',
                        year: '(2008-2015)',
                        catid: [117],
                    },
                    {
                        name: 'F02 LCI',
                        slug: 'f02-lci',
                        year: '(2008-2015)',
                        catid: [118],
                    },
                    {
                        name: 'F03 LCI',
                        slug: 'f03-lci',
                        year: '(2008-2015)',
                        catid: [120],
                    },
                    //   {
                    //       name: 'F04',
                    //       slug: 'f04',
                    //       year: '(2008-2015)',
                    //   },
                    //   {
                    //       name: 'G11',
                    //       slug: 'g11',
                    //       year: '(2015-2019)',
                    //   },
                    {
                        name: 'G12',
                        slug: 'g12',
                        year: '(2015-2019)',
                        catid: [335],
                    },
                ],
            },
            {
                name: 'X1 Series',
                slug: 'x1-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'X1 E84',
                        slug: 'x1-e84',
                        year: '(2009-2015)',
                        catid: [122],
                    },
                    //   {
                    //       name: 'X1 F48',
                    //       slug: 'x1-f48',
                    //       year: '(2015-now)',
                    //   },
                ],
            },
            {
                name: 'X3 Series',
                slug: 'x3-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'X3 E83',
                        slug: 'x3-e83',
                        year: '(2003-2010)',
                        catid: [123],
                    },
                    {
                        name: 'X3 E83 LCI',
                        slug: 'x3-e83-lci',
                        year: '(2003-2010)',
                        catid: [124],
                    },
                    {
                        name: 'X3 F25',
                        slug: 'x3-f25',
                        year: '(2011-2017)',
                        catid: [125],
                    },
                    //   {
                    //       name: 'X3 G01',
                    //       slug: 'x3-g01',
                    //       year: '(2018-now)',
                    //   },
                ],
            },
            {
                name: 'X4 Series',
                slug: 'x4-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'X4 F26',
                        slug: 'x4-f26',
                        year: '(2014-2018)',
                        catid: [126],
                    },
                    //   {
                    //       name: 'X4 G02',
                    //       slug: 'x4-g02',
                    //       year: '(2018-now)',
                    //   },
                ],
            },
            {
                name: 'X5 Series',
                slug: 'x5-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'X5 E53',
                        slug: 'x5-e53',
                        year: '(1999-2006)',
                        catid: [127],
                    },
                    {
                        name: 'X5 E70',
                        slug: 'x5-e70',
                        year: '(2006-2013)',
                        catid: [128],
                    },
                    {
                        name: 'X5 E70 LCI',
                        slug: 'x5-e70-lci',
                        year: '(2006-2013)',
                        catid: [129],
                    },
                    //   {
                    //       name: 'X5 F15',
                    //       slug: 'x5-f15',
                    //       year: '(2013-2018)',
                    //   },
                    //   {
                    //       name: 'X5 F85',
                    //       slug: 'x5-f85',
                    //       year: '(2013-2018)',
                    //   },
                    //   {
                    //       name: 'X5 G05',
                    //       slug: 'x5-g05',
                    //       year: '(2019-now)',
                    //   },
                    //   {
                    //       name: 'X5 F95',
                    //       slug: 'x5-f95',
                    //       year: '(2019-now)',
                    //   },
                ],
            },
            {
                name: 'X6 Series',
                slug: 'x6-series',
                image: 'images/no-image.jpg',
                children: [
                    {
                        name: 'X6 E71',
                        slug: 'x6-e71',
                        year: '(2008-2014)',
                        catid: [131],
                    },
                    //   {
                    //       name: 'X6 E72',
                    //       slug: 'x6-e72',
                    //       year: '(2009-2010)',
                    //   },
                    //   {
                    //       name: 'X6 M',
                    //       slug: 'x6-m',
                    //       year: '(2009-2014)',
                    //   },
                    //   {
                    //       name: 'X6 F16',
                    //       slug: 'x6-f16',
                    //       year: '(2015-2019)',
                    //   },
                    //   {
                    //       name: 'X6 F86',
                    //       slug: 'x6-f86',
                    //       year: '(2015-2019)',
                    //   },
                    //   {
                    //       name: 'X6 G06',
                    //       slug: 'x6-g06',
                    //       year: '(2020-now)',
                    //   },
                    //   {
                    //       name: 'X6 F96',
                    //       slug: 'x6-f96',
                    //       year: '(2020-now)',
                    //   },
                ],
            },
            //   {
            //       name: 'X7 Series',
            //       slug: 'x7-series',
            //       image: 'images/no-image.jpg',
            //       children: [
            //           {
            //               name: 'X7 G07',
            //               slug: 'X7-G07',
            //               year: '(2019-now)',
            //           },
            //           {
            //               name: 'Alphina XB7',
            //               slug: 'alphina-xB7',
            //               year: '(2020-now)',
            //           },
            //       ],
            //   },
            {
                name: 'Z Series',
                slug: 'z-Series',
                image: 'images/no-image.jpg',
                children: [
                    //   {
                    //       name: 'Z1 E30',
                    //       slug: 'z1-e30',
                    //       year: '(1989-1991)',
                    //   },
                    {
                        name: 'Z3 E36',
                        slug: 'e3-e36',
                        year: '(1995-2002)',
                        catid: [133],
                    },
                    //   {
                    //       name: 'Z3 E37',
                    //       slug: 'e3-e37',
                    //       year: '(1995-2002)',
                    //   },
                    //   {
                    //       name: 'Z3 E38',
                    //       slug: 'e3-e38',
                    //       year: '(1995-2002)',
                    //   },
                    //   {
                    //       name: 'Z8 E52',
                    //       slug: 'z8-e52',
                    //       year: '(2000-2003)',
                    //   },
                    {
                        name: 'Z4 E85',
                        slug: 'z4-e85',
                        year: '(2002-2008)',
                        catid: [134],
                    },
                    //   {
                    //       name: 'Z4 E86',
                    //       slug: 'z4-e86',
                    //       year: '(2002-2008)',
                    //   },
                    {
                        name: 'Z4 E89',
                        slug: 'z4-e89',
                        year: '(2009-2016)',
                        catid: [136],
                    },
                    //   {
                    //       name: 'Z4 G29',
                    //       slug: 'z4-g29',
                    //       year: '(2018-now)',
                    //   },
                ],
            },
            {
                name: 'I01',
                slug: 'I01',
                image: 'images/no-image.jpg',
                catid: [306],
                children: [
                    {
                        name: 'I3 REX',
                        slug: 'i3-rex',
                        year: '(2013-now)',
                        catid: [1374],
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        name: 'Audi',
        slug: 'audi',
        image: 'data/brand/Audi.png',
        models: [
            {
                name: 'A1',
                slug: 'a1',
                image: 'images/no-image.jpg',
                catid: [247],
                children: [
                    {
                        name: '1.0 TFSI',
                        slug: '1-0-tfsi',
                        year: '(2010-2018)',
                        catid: [1407],
                    },
                    {
                        name: '1.4 TFSI',
                        slug: '1-4-tfsi',
                        year: '(2010-2018)',
                        catid: [1229],
                    },
                ],
            },
            {
                name: 'A3',
                slug: 'a3',
                image: 'images/no-image.jpg',
                catid: [168, 167],
                children: [
                    {
                        name: '8V 1.4 TFSI',
                        slug: '8v-1-4-tfsi',
                        year: '(2012-now)',
                        catid: [985],
                    },
                    {
                        name: '8V 1.8 TFSI',
                        slug: '8v-1-8-tfsi',
                        year: '(2012-now)',
                        catid: [984],
                    },
                    {
                        name: '8P 1.2 TFSI',
                        slug: '8p-1-2-tfsi',
                        year: '(2003-2013)',
                        catid: [975],
                    },
                    {
                        name: '8P 1.4 TFSI',
                        slug: '8p-1-4-tfsi',
                        year: '(2003-2013)',
                        catid: [976],
                    },
                    {
                        name: '8P 1.6',
                        slug: '8p-1-6',
                        year: '(2003-2013)',
                        catid: [977],
                    },
                    {
                        name: '8P 1.6 FSI',
                        slug: '8p-1-6-fsi',
                        year: '(2003-2013)',
                        catid: [978],
                    },
                    {
                        name: '8P 1.8 TFSI',
                        slug: '8p-1-8-tfsi',
                        year: '(2003-2013)',
                        catid: [979],
                    },
                    {
                        name: '8P 2.0 FSI',
                        slug: '8p-2-0-fsi',
                        year: '(2003-2013)',
                        catid: [980],
                    },
                    {
                        name: '8P 2.0 TFSI',
                        slug: '8p-2-0-tfsi',
                        year: '(2003-2013)',
                        catid: [981],
                    },
                    {
                        name: '8P 2.5 TFSI',
                        slug: '8p-2-5-tfsi',
                        year: '(2003-2013)',
                        catid: [982],
                    },
                    {
                        name: '8P 3.2 FSI',
                        slug: '8p-3-2-fsi',
                        year: '(2003-2013)',
                        catid: [983],
                    },
                ],
            },
            {
                name: 'A4',
                slug: 'a4',
                image: 'images/no-image.jpg',
                catid: [169, 312, 170],
                children: [
                    {
                        name: '1.8 T Quattro',
                        slug: '1-8-t-quattro',
                        year: '(2000-2011)',
                        catid: [992],
                    },
                    {
                        name: '1.8 TFSI MU',
                        slug: '1-8-tfsi-mu',
                        year: '(2000-2011)',
                        catid: [1294],
                    },
                    {
                        name: '1.8T MU',
                        slug: '1-8t-mu',
                        year: '(2000-2011)',
                        catid: [1150],
                    },
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(2000-2011)',
                        catid: [986],
                    },
                    {
                        name: '2.0 SE',
                        slug: '2-0-se',
                        year: '(2000-2011)',
                        catid: [987],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(2000-2011)',
                        catid: [991],
                    },
                    {
                        name: '2.4 SE',
                        slug: '2-4-se',
                        year: '(2000-2011)',
                        catid: [990],
                    },
                    {
                        name: '2.4',
                        slug: '2-4',
                        year: '(2000-2011)',
                        catid: [993],
                    },
                    {
                        name: '3.0 Quattro',
                        slug: '3-0-quattro',
                        year: '(2000-2011)',
                        catid: [988],
                    },
                    {
                        name: '3.0 Quattro Sport',
                        slug: '3-0-quattro-sport',
                        year: '(2000-2011)',
                        catid: [989],
                    },
                    {
                        name: 'B9 1.4 TFSI',
                        slug: 'b9-1-4-tfsi',
                        year: '(2015-now)',
                        catid: [1396],
                    },
                    {
                        name: 'B9 2.0 TFSI',
                        slug: 'b9-2-0-tfsi',
                        year: '(2015-now)',
                        catid: [1382],
                    },
                    {
                        name: 'B8 1.8 TFSI',
                        slug: 'b8-1-8-tfsi',
                        year: '(2007-2015)',
                        catid: [994],
                    },
                    {
                        name: 'B8 1.8 TFSI Quattro',
                        slug: 'b8-1-8-tfsi-quattro',
                        year: '(2007-2015)',
                        catid: [995],
                    },
                    {
                        name: 'B8 2.0 TFSI',
                        slug: 'b8-2-0-tfsi',
                        year: '(2007-2015)',
                        catid: [996],
                    },
                    {
                        name: 'B8 2.0 TFSI Quattro',
                        slug: 'b8-2-0-tfsi-quattro',
                        year: '(2007-2015)',
                        catid: [997],
                    },
                    {
                        name: 'B8 3.2 FSI',
                        slug: 'b8-3-2-fsi',
                        year: '(2007-2015)',
                        catid: [998],
                    },
                    {
                        name: 'B8 3.2 FSI Quattro',
                        slug: 'b8-3-2-fsi-quattro',
                        year: '(2007-2015)',
                        catid: [999],
                    },
                ],
            },
            {
                name: 'A5',
                slug: 'a5',
                image: 'images/no-image.jpg',
                catid: [230],
                children: [
                    {
                        name: '1.8 TFSI',
                        slug: '1-8-tfsi',
                        year: '(2007-now)',
                        catid: [1257],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(2007-now)',
                        catid: [1297],
                    },
                    {
                        name: '3.2 FSI',
                        slug: '3-2-fsi',
                        year: '(2007-now)',
                        catid: [1200],
                    },
                ],
            },
            {
                name: 'A6',
                slug: 'a6',
                image: 'images/no-image.jpg',
                catid: [173, 174, 175],
                children: [
                    {
                        name: '1.8T',
                        slug: '1-8t',
                        year: '(1997-2005)',
                        catid: [1136],
                    },
                    {
                        name: '2.0T FSI MU',
                        slug: '2-0t-fsi-mu',
                        year: '(1997-2005)',
                        catid: [1290],
                    },
                    {
                        name: '2.4',
                        slug: '2-4',
                        year: '(1997-2005)',
                        catid: [1009],
                    },
                    {
                        name: '2.4 MU',
                        slug: '2-4-mu',
                        year: '(1997-2005)',
                        catid: [1287],
                    },
                    {
                        name: '2.4 Quattro',
                        slug: '2-4-quattro',
                        year: '(1997-2005)',
                        catid: [1011],
                    },
                    {
                        name: '2.7 T Quattro',
                        slug: '2-7-t-quattro',
                        year: '(1997-2005)',
                        catid: [1014],
                    },
                    {
                        name: '2.8 Quattro',
                        slug: '2-8-quattro',
                        year: '(1997-2005)',
                        catid: [1010],
                    },
                    {
                        name: '2.8 FSIMU',
                        slug: '2-8-fsimu',
                        year: '(1997-2005)',
                        catid: [1324],
                    },
                    {
                        name: '3.0 Quattro',
                        slug: '3-0-quattro',
                        year: '(1997-2005)',
                        catid: [1012],
                    },
                    {
                        name: '3.0 Quattro SE',
                        slug: '3-0-quattro-se',
                        year: '(1997-2005)',
                        catid: [1013],
                    },
                    {
                        name: 'C6 2.4',
                        slug: 'c6-2-4',
                        year: '(2004-2011)',
                        catid: [1017],
                    },
                    {
                        name: 'C6 2.4 Dynamic Line',
                        slug: 'c6-2-4-dynamic-line',
                        year: '(2004-2011)',
                        catid: [1018],
                    },
                    {
                        name: 'C6 2.8 FSI Quattro',
                        slug: 'c6-2-8-fsi-quattro',
                        year: '(2004-2011)',
                        catid: [1020],
                    },
                    {
                        name: 'C6 3.0 TFSI Quattro',
                        slug: 'c6-3-0-tfsi-quattro',
                        year: '(2004-2011)',
                        catid: [1021],
                    },
                    {
                        name: 'C6 3.2 FSI Quattro',
                        slug: 'c6-3-2-fsi-quattro',
                        year: '(2004-2011)',
                        catid: [1016],
                    },
                    {
                        name: 'C6 4.2 Quattro',
                        slug: 'c6-4-2-quattro',
                        year: '(2004-2011)',
                        catid: [1015],
                    },
                    {
                        name: 'C6 4.2 FSI Quattro',
                        slug: 'c6-4-2-fsi-quattro',
                        year: '(2004-2011)',
                        catid: [1019],
                    },
                    {
                        name: 'C7 1.8 TFSI U',
                        slug: 'c7-1-8-tfsi-u',
                        year: '(2010-2018)',
                        catid: [1355],
                    },
                    {
                        name: 'C7 2.0 TFSI',
                        slug: 'c7-2-0-tfsi',
                        year: '(2010-2018)',
                        catid: [1024],
                    },
                    {
                        name: 'C7 2.0 TFSI MU',
                        slug: 'c7-2-0-tfsi-mu',
                        year: '(2010-2018)',
                        catid: [1225],
                    },
                    {
                        name: 'C7 2.8 FSI',
                        slug: 'c7-2-8-fsi',
                        year: '(2010-2018)',
                        catid: [1022],
                    },
                    {
                        name: 'C7 3.0 TFSI Quattro',
                        slug: 'c7-3-0-tfsi-quattro',
                        year: '(2010-2018)',
                        catid: [1023],
                    },
                ],
            },
            {
                name: 'A7',
                slug: 'a7',
                image: 'images/no-image.jpg',
                catid: [213],
                children: [
                    {
                        name: '2.8 FSI QU',
                        slug: '2-8-fsi-qu',
                        year: '(2010-2018)',
                        catid: [1164],
                    },
                    {
                        name: '3.0 TFSI',
                        slug: '3-0-tfsi',
                        year: '(2010-2018)',
                        catid: [1309],
                    },
                    {
                        name: 'Sportback 2.8',
                        slug: 'sportback-2-8',
                        year: '(2010-2018)',
                        catid: [1351],
                    },
                ],
            },
            {
                name: 'A8',
                slug: 'a8',
                image: 'images/no-image.jpg',
                catid: [171, 172],
                children: [
                    {
                        name: 'D3 3.2 FSI Quattro',
                        slug: 'd3-3-2-fsi-quattro',
                        year: '(2002-2010)',
                        catid: [1003],
                    },
                    {
                        name: 'D3 3.7 Quattro',
                        slug: 'd3-3-7-quattro',
                        year: '(2002-2010)',
                        catid: [1001],
                    },
                    {
                        name: 'D3 4.2 Quattro',
                        slug: 'd3-4-2-quattro',
                        year: '(2002-2010)',
                        catid: [1000],
                    },
                    {
                        name: 'D3 4.2 FSI Quattro',
                        slug: 'd3-4-2-fsi-quattro',
                        year: '(2002-2010)',
                        catid: [1004],
                    },
                    {
                        name: 'D3 6.0 Quattro',
                        slug: 'd3-6-0-quattro',
                        year: '(2002-2010)',
                        catid: [1002],
                    },
                    {
                        name: 'D4 3.0 TFSI Quattro',
                        slug: 'd4-3-0-tfsi-quattro',
                        year: '(2009-2018)',
                        catid: [1005],
                    },
                    {
                        name: 'D4 3.2 FSI MU',
                        slug: 'd4-3-2-fsi-mu',
                        year: '(2009-2018)',
                        catid: [1337],
                    },
                    {
                        name: 'D4 4.0 TFSI Quattro',
                        slug: 'd4-4-0-tfsi-quattro',
                        year: '(2009-2018)',
                        catid: [1008],
                    },
                    {
                        name: 'D4 4.2 FSI Quattro',
                        slug: 'd4-4-2-fsi-quattro',
                        year: '(2009-2018)',
                        catid: [1006],
                    },
                    {
                        name: 'D4 L W12 Quattro',
                        slug: 'd4-l-w12-quattro',
                        year: '(2009-2018)',
                        catid: [1007],
                    },
                ],
            },
            {
                name: 'Q3 PI',
                slug: 'q3-pi',
                image: 'images/no-image.jpg',
                catid: [314],
                children: [
                    {
                        name: '1.4 TFSI',
                        slug: '1-4-tfsi',
                        year: '(2011-2018)',
                        catid: [1419],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(2011-2018)',
                        catid: [1386],
                    },
                ],
            },
            {
                name: 'Q5',
                slug: 'q5',
                image: 'images/no-image.jpg',
                catid: [246],
                children: [
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(2008-2017)',
                        catid: [1228],
                    },
                    {
                        name: '3.2 FSI',
                        slug: '3-2-fsi',
                        year: '(2008-2017)',
                        catid: [1398],
                    },
                ],
            },
            {
                name: 'Q7',
                slug: 'q7',
                image: 'images/no-image.jpg',
                catid: [205],
                children: [
                    {
                        name: '3.6',
                        slug: '3-6',
                        year: '(2006-2016)',
                        catid: [1141],
                    },
                    {
                        name: '4.2',
                        slug: '4-2',
                        year: '(2006-2016)',
                        catid: [1298],
                    },
                ],
            },
            {
                name: 'R8',
                slug: 'r8',
                image: 'images/no-image.jpg',
                catid: [256],
                children: [
                    {
                        name: '4.2 FSI QU',
                        slug: '4-2-fsi-qu',
                        year: '(2007-2015)',
                        catid: [1245],
                    },
                    {
                        name: '5.2 FSI QU',
                        slug: '5-2-fsi-qu',
                        year: '(2007-2015)',
                        catid: [1430],
                    },
                ],
            },
            {
                name: 'S3',
                slug: 's3',
                image: 'images/no-image.jpg',
                catid: [290],
                children: [
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(1999-2003)',
                        catid: [1340],
                    },
                ],
            },
            {
                name: 'S5',
                slug: 's5',
                image: 'images/no-image.jpg',
                catid: [248],
                children: [
                    {
                        name: '3.0 TFSI',
                        slug: '3-0-tfsi',
                        year: '(2007-2012)',
                        catid: [1410],
                    },
                    {
                        name: '4.2 FSI QU',
                        slug: '4-2-fsi-qu',
                        year: '(2007-2012)',
                        catid: [1230],
                    },
                ],
            },
            {
                name: 'S8',
                slug: 's8',
                image: 'images/no-image.jpg',
                catid: [307],
                children: [
                    {
                        name: '5.2 FSI',
                        slug: '5-2-fsi',
                        year: '(2006-2010)',
                        catid: [1376],
                    },
                ],
            },
            {
                name: 'TT Coupe 8N',
                slug: 'tt-coupe-8n',
                image: 'images/no-image.jpg',
                catid: [178],
                children: [
                    {
                        name: '1.8 T Quattro',
                        slug: '1-8-t-quattro',
                        year: '(1998-2006)',
                        catid: [1029],
                    },
                    {
                        name: '1.8 T',
                        slug: '1-8-t',
                        year: '(1998-2006)',
                        catid: [1030],
                    },
                    {
                        name: '3.2 Quattro S Line',
                        slug: '3-2-quattro-s-line',
                        year: '(1998-2006)',
                        catid: [1031],
                    },
                    {
                        name: 'Quattro Sport',
                        slug: 'quattro-sport',
                        year: '(1998-2006)',
                        catid: [1032],
                    },
                ],
            },
            {
                name: 'TT Coupe 8J',
                slug: 'tt-coupe-8j',
                image: 'images/no-image.jpg',
                catid: [179],
                children: [
                    {
                        name: '1.8 TFSI',
                        slug: '1-8-tfsi',
                        year: '(2006-2014)',
                        catid: [1028],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(2006-2014)',
                        catid: [1026],
                    },
                    {
                        name: '2.0 TFSI Quattro',
                        slug: '2-0-tfsi-quattro',
                        year: '(2006-2014)',
                        catid: [1027],
                    },
                    {
                        name: '3.2 Quattro',
                        slug: '3-2-quattro',
                        year: '(2006-2014)',
                        catid: [1025],
                    },
                ],
            },
            {
                name: 'TT Roadster 8J',
                slug: 'tt-roadster-8j',
                image: 'images/no-image.jpg',
                catid: [177],
                children: [
                    {
                        name: '1.8 TFSI',
                        slug: '1-8-tfsi',
                        year: '(2007-2014)',
                        catid: [1033],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(2007-2014)',
                        catid: [1034],
                    },
                    {
                        name: '2.5 TFSI',
                        slug: '2-5-tfsi',
                        year: '(2007-2014)',
                        catid: [1036],
                    },
                    {
                        name: '3.2 V6 Quattro',
                        slug: '3-2-v6-quattro',
                        year: '(2007-2014)',
                        catid: [1035],
                    },
                ],
            },
            {
                name: 'TTS',
                slug: 'tts',
                image: 'images/no-image.jpg',
                catid: [311],
                children: [
                    {
                        name: '2.0T FSI',
                        slug: '2-0t-fsi',
                        year: '(2008-2016)',
                        catid: [1381],
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        name: 'Volvo',
        slug: 'volvo',
        image: 'data/brand/Volvo.png',
        models: [
            {
                name: '850',
                slug: '850',
                image: 'images/no-image.jpg',
                catid: [238],
                children: [
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(1991-1997)',
                        catid: [1210],
                    },
                    {
                        name: '2.3',
                        slug: '2-3',
                        year: '(1991-1997)',
                        catid: [1211],
                    },
                ],
            },
            {
                name: '960',
                slug: '960',
                image: 'images/no-image.jpg',
                catid: [237],
                children: [
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(1990-1994)',
                        catid: [1207],
                    },
                    {
                        name: '2.3',
                        slug: '2-3',
                        year: '(1990-1994)',
                        catid: [1208],
                    },
                    {
                        name: '2.5',
                        slug: '2-5',
                        year: '(1990-1994)',
                        catid: [1209],
                    },
                ],
            },
            {
                name: 'C30',
                slug: 'c30',
                image: 'images/no-image.jpg',
                catid: [151],
                children: [
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(2006-2013)',
                        catid: [871],
                    },
                    {
                        name: '1.8',
                        slug: '1-8',
                        year: '(2006-2013)',
                        catid: [872],
                    },
                    {
                        name: '1.8F',
                        slug: '1-8f',
                        year: '(2006-2013)',
                        catid: [877],
                    },
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(2006-2013)',
                        catid: [873],
                    },
                    {
                        name: '2.0L AT',
                        slug: '2-0l-at',
                        year: '(2006-2013)',
                        catid: [1363],
                    },
                    {
                        name: '2.4I',
                        slug: '2-4i',
                        year: '(2006-2013)',
                        catid: [874],
                    },
                    {
                        name: 'T5',
                        slug: 't5',
                        year: '(2006-2013)',
                        catid: [876],
                    },
                    {
                        name: 'T5',
                        slug: 't5',
                        year: '(2006-2013)',
                        catid: [875],
                    },
                    {
                        name: 'T5 A',
                        slug: 't5-a',
                        year: '(2006-2013)',
                        catid: [1273],
                    },
                ],
            },
            {
                name: 'C70',
                slug: 'c70',
                image: 'images/no-image.jpg',
                catid: [152],
                children: [
                    {
                        name: '2.4',
                        slug: '2-4',
                        year: '(1996-2013)',
                        catid: [878],
                    },
                    {
                        name: '2.4I',
                        slug: '2-4i',
                        year: '(1996-2013)',
                        catid: [879],
                    },
                    {
                        name: 'T5',
                        slug: 't5',
                        year: '(1996-2013)',
                        catid: [880],
                    },
                ],
            },
            {
                name: 'S40',
                slug: 's40',
                image: 'images/no-image.jpg',
                catid: [157],
                children: [
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(1995-2012)',
                        catid: [915],
                    },
                    {
                        name: '1.8',
                        slug: '1-8',
                        year: '(1995-2012)',
                        catid: [916],
                    },
                    {
                        name: '1.8I',
                        slug: '1-8i',
                        year: '(1995-2012)',
                        catid: [920],
                    },
                    {
                        name: '1.9T',
                        slug: '1-9t',
                        year: '(1995-2012)',
                        catid: [918],
                    },
                    {
                        name: '1.9D',
                        slug: '1-9d',
                        year: '(1995-2012)',
                        catid: [921],
                    },
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(1995-2012)',
                        catid: [917],
                    },
                    {
                        name: '2.0 Turbo',
                        slug: '2-0-turbo',
                        year: '(1995-2012)',
                        catid: [919],
                    },
                    {
                        name: '2.5A',
                        slug: '2-5a',
                        year: '(1995-2012)',
                        catid: [1155],
                    },
                    {
                        name: 'T5 SE AWD',
                        slug: 't5-se-awd',
                        year: '(1995-2012)',
                        catid: [922],
                    },
                ],
            },
            {
                name: 'S60',
                slug: 's60',
                image: 'images/no-image.jpg',
                catid: [155],
                children: [
                    {
                        name: '2.0T',
                        slug: '2-0t',
                        year: '(2000-2018)',
                        catid: [1145],
                    },
                    {
                        name: '2.0T Auto',
                        slug: '2-0t-auto',
                        year: '(2000-2018)',
                        catid: [1265],
                    },
                    {
                        name: '2.4',
                        slug: '2-4',
                        year: '(2000-2018)',
                        catid: [899],
                    },
                    {
                        name: '2.4 T',
                        slug: '2-4-t',
                        year: '(2000-2018)',
                        catid: [900],
                    },
                    {
                        name: '2.5 T',
                        slug: '2-5-T',
                        year: '(2000-2018)',
                        catid: [903],
                    },
                    {
                        name: 'D2',
                        slug: 'd2',
                        year: '(2000-2018)',
                        catid: [1322],
                    },
                    {
                        name: 'T2 AT',
                        slug: 't2-at',
                        year: '(2000-2018)',
                        catid: [1368],
                    },
                    {
                        name: 'T4',
                        slug: 't4',
                        year: '(2000-2018)',
                        catid: [1185],
                    },
                    {
                        name: 'T4 1.6AT',
                        slug: 't4-1-6at',
                        year: '(2000-2018)',
                        catid: [1263],
                    },
                    {
                        name: 'T6 AWD',
                        slug: 't6-awd',
                        year: '(2000-2018)',
                        catid: [905],
                    },
                ],
            },
            {
                name: 'S60 R Design',
                slug: 's60 r-design',
                image: 'images/no-image.jpg',
                catid: [273],
                children: [
                    {
                        name: 'T6 3.0',
                        slug: 't6-3-0',
                        year: '(2019-now)',
                        catid: [1301],
                    },
                ],
            },
            {
                name: 'S60 T4',
                slug: 's60 t4',
                image: 'images/no-image.jpg',
                catid: [297],
                children: [
                    {
                        name: '1.6 AT',
                        slug: '1-6-at',
                        year: '(2010-2018)',
                        catid: [1358],
                    },
                ],
            },
            {
                name: 'S60 T5',
                slug: 's60 t5',
                image: 'images/no-image.jpg',
                catid: [285],
                children: [
                    {
                        name: '2.0 Auto',
                        slug: '2-0-auto',
                        year: '(2010-2018)',
                        catid: [1330],
                    },
                ],
            },
            {
                name: 'S70',
                slug: 's70',
                image: 'images/no-image.jpg',
                catid: [154],
                children: [
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(1996-2000)',
                        catid: [885],
                    },
                    {
                        name: '2.3 T5',
                        slug: '2-3-t5',
                        year: '(1996-2000)',
                        catid: [895],
                    },
                    {
                        name: '2.4',
                        slug: '2-4',
                        year: '(1996-2000)',
                        catid: [888],
                    },
                    {
                        name: '2.4 AWD',
                        slug: '2-4-awd',
                        year: '(1996-2000)',
                        catid: [894],
                    },
                    {
                        name: '2.5',
                        slug: '2-5',
                        year: '(1996-2000)',
                        catid: [889],
                    },
                    {
                        name: '2.5 T AWD',
                        slug: '2-5-t-awd',
                        year: '(1996-2000)',
                        catid: [890],
                    },
                    {
                        name: 'Base 2.4',
                        slug: 'base-2-4',
                        year: '(1996-2000)',
                        catid: [891],
                    },
                    {
                        name: 'GLT',
                        slug: 'glt',
                        year: '(1996-2000)',
                        catid: [893],
                    },
                    {
                        name: 'R',
                        slug: 'r',
                        year: '(1996-2000)',
                        catid: [896],
                    },
                    {
                        name: 'Base 2.4 SE',
                        slug: 'base-2-4-se',
                        year: '(1996-2000)',
                        catid: [892],
                    },
                    {
                        name: '2.0 T',
                        slug: '2-0-t',
                        year: '(1996-2000)',
                        catid: [886],
                    },
                    {
                        name: '2.0 T5',
                        slug: '2-0-t5',
                        year: '(1996-2000)',
                        catid: [887],
                    },
                ],
            },
            {
                name: 'S80',
                slug: 's80',
                image: 'images/no-image.jpg',
                catid: [159],
                children: [
                    {
                        name: '2.0T',
                        slug: '2-0t',
                        year: '(1998-2016)',
                        catid: [930],
                    },
                    {
                        name: '2.4',
                        slug: '2-4',
                        year: '(1998-2016)',
                        catid: [929],
                    },
                    {
                        name: '2.4T',
                        slug: '2-4t',
                        year: '(1998-2016)',
                        catid: [931],
                    },
                    {
                        name: '2.5T',
                        slug: '2-5t',
                        year: '(1998-2016)',
                        catid: [932],
                    },
                    {
                        name: '2.5T A',
                        slug: '2-5t a',
                        year: '(1998-2016)',
                        catid: [1267],
                    },
                    {
                        name: '2.9',
                        slug: '2-9',
                        year: '(1998-2016)',
                        catid: [933],
                    },
                    {
                        name: '3.2',
                        slug: '3-2',
                        year: '(1998-2016)',
                        catid: [1171],
                    },
                    {
                        name: 'T4',
                        slug: 't4',
                        year: '(1998-2016)',
                        catid: [1223],
                    },
                    {
                        name: 'T6',
                        slug: 't6',
                        year: '(1998-2016)',
                        catid: [934],
                    },
                ],
            },
            {
                name: 'S80 T5',
                slug: 's80 t5',
                image: 'images/no-image.jpg',
                catid: [229],
                children: [
                    {
                        name: '2.0 AT',
                        slug: '2-0-at',
                        year: '(1998-2016)',
                        catid: [1199],
                    },
                ],
            },
            {
                name: 'S90',
                slug: 's90',
                image: 'images/no-image.jpg',
                catid: [326],
                children: [
                    {
                        name: 'T5',
                        slug: 't5',
                        year: '(2016-now)',
                        catid: [1415],
                    },
                ],
            },
            {
                name: 'V50',
                slug: 'v50',
                image: 'images/no-image.jpg',
                catid: [225],
                children: [
                    {
                        name: 'T5 2.4IA',
                        slug: 't5-2-4ia',
                        year: '(2004-2012)',
                        catid: [1184],
                    },
                ],
            },
            {
                name: 'V40',
                slug: 'v40',
                image: 'images/no-image.jpg',
                catid: [158],
                children: [
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(2012-2019)',
                        catid: [1193],
                    },
                    {
                        name: '1.8',
                        slug: '1-8',
                        year: '(2012-2019)',
                        catid: [923],
                    },
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(2012-2019)',
                        catid: [925],
                    },
                    {
                        name: '2.0T',
                        slug: '2-0t',
                        year: '(2012-2019)',
                        catid: [926],
                    },
                    {
                        name: 'Basegrade',
                        slug: 'basegrade',
                        year: '(2012-2019)',
                        catid: [927],
                    },
                    {
                        name: 'Classic',
                        slug: 'classic',
                        year: '(2012-2019)',
                        catid: [928],
                    },
                    {
                        name: 'Cross Country T4',
                        slug: 'cross-country-t4',
                        year: '(2012-2019)',
                        catid: [1292],
                    },
                    {
                        name: 'D2 AT',
                        slug: 'd2-at',
                        year: '(2012-2019)',
                        catid: [1334],
                    },
                    {
                        name: 'T2',
                        slug: 't2',
                        year: '(2012-2019)',
                        catid: [1318],
                    },
                    {
                        name: 'T4',
                        slug: 't4',
                        year: '(2012-2019)',
                        catid: [924],
                    },
                ],
            },
            {
                name: 'V50T',
                slug: 'v50t',
                image: 'images/no-image.jpg',
                catid: [206],
                children: [
                    {
                        name: '2.5',
                        slug: '2-5',
                        year: '(2004-2012)',
                        catid: [1146],
                    },
                ],
            },
            {
                name: 'V60',
                slug: 'v60',
                image: 'images/no-image.jpg',
                catid: [282],
                children: [
                    {
                        name: '1.6 T4',
                        slug: '1-6-t4',
                        year: '(2010-now)',
                        catid: [1312],
                    },
                    {
                        name: 'D2',
                        slug: 'd2',
                        year: '(2010-now)',
                        catid: [1422],
                    },
                ],
            },
            {
                name: 'XC 60',
                slug: 'xc-60',
                image: 'images/no-image.jpg',
                catid: [153],
                children: [
                    {
                        name: '2.0 AT',
                        slug: '2-0-at',
                        year: '(2008-now)',
                        catid: [1196],
                    },
                    {
                        name: '3.0L AT',
                        slug: '3-0l-at',
                        year: '(2008-now)',
                        catid: [1335],
                    },
                    {
                        name: '3.2 FWD',
                        slug: '3-2-fwd',
                        year: '(2008-now)',
                        catid: [882],
                    },
                    {
                        name: '3.2 AWD',
                        slug: '3-2-awd',
                        year: '(2008-now)',
                        catid: [883],
                    },
                    {
                        name: 'T6 AWD',
                        slug: 't6-awd',
                        year: '(2008-now)',
                        catid: [884],
                    },
                ],
            },
            {
                name: 'XC 90',
                slug: 'xc-90',
                image: 'images/no-image.jpg',
                catid: [156],
                children: [
                    {
                        name: '2.5 T',
                        slug: '2-5-t',
                        year: '(2002-now)',
                        catid: [906],
                    },
                    {
                        name: '2.5T A',
                        slug: '2-5t-a',
                        year: '(2002-now)',
                        catid: [1266],
                    },
                    {
                        name: '2.9T',
                        slug: '2-9t',
                        year: '(2002-now)',
                        catid: [1157],
                    },
                    {
                        name: '2.9 A',
                        slug: '2-9-a',
                        year: '(2002-now)',
                        catid: [1198],
                    },
                    {
                        name: '3.2',
                        slug: '3-2',
                        year: '(2002-now)',
                        catid: [909],
                    },
                    {
                        name: '3.2 R Design',
                        slug: '3-2-r-design',
                        year: '(2002-now)',
                        catid: [912],
                    },
                    {
                        name: '3.2 SE AWD',
                        slug: '3-2-se-awd',
                        year: '(2002-now)',
                        catid: [910],
                    },
                    {
                        name: '3.2 Sport',
                        slug: '3-2-sport',
                        year: '(2002-now)',
                        catid: [911],
                    },
                    {
                        name: 'R Design',
                        slug: 'r-design',
                        year: '(2002-now)',
                        catid: [914],
                    },
                    {
                        name: 'SE',
                        slug: 'se',
                        year: '(2002-now)',
                        catid: [913],
                    },
                    {
                        name: 'T6',
                        slug: 't6',
                        year: '(2002-now)',
                        catid: [907],
                    },
                    {
                        name: 'V8 TE',
                        slug: 'v8-te',
                        year: '(2002-now)',
                        catid: [908],
                    },
                ],
            },
            {
                name: 'XC 60 T5',
                slug: 'xc-60-t5',
                image: 'images/no-image.jpg',
                catid: [291],
                children: [
                    {
                        name: '2.0 AT',
                        slug: '2-0-at',
                        year: '(2008-now)',
                        catid: [1341],
                    },
                ],
            },
            {
                name: 'XC 90 T6',
                slug: 'xc-90-t6',
                image: 'images/no-image.jpg',
                catid: [292],
                children: [
                    {
                        name: 'Momentun AT',
                        slug: 'momentun-at',
                        year: '(2002-now)',
                        catid: [1342],
                    },
                ],
            },
        ],
    },
    {
        id: 16,
        name: 'Volkswagen',
        slug: 'volkswagen',
        image: 'data/brand/Volkswagen.png',
        models: [
            {
                name: 'Golf 6',
                slug: 'golf-6',
                image: 'images/no-image.jpg',
                catid: [164],
                children: [
                    {
                        name: '1.2 TSI',
                        slug: '1-2-tsi',
                        year: '(2008-2014)',
                        catid: [956],
                    },
                    {
                        name: '1.4',
                        slug: '1-4',
                        year: '(2008-2014)',
                        catid: [954],
                    },
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(2008-2014)',
                        catid: [957],
                    },
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(2008-2014)',
                        catid: [955],
                    },
                    {
                        name: '2.5',
                        slug: '2-5',
                        year: '(2008-2014)',
                        catid: [960],
                    },
                    {
                        name: 'GTI',
                        slug: 'gti',
                        year: '(2008-2014)',
                        catid: [958],
                    },
                    {
                        name: 'GTI-35',
                        slug: 'gti-35',
                        year: '(2008-2014)',
                        catid: [959],
                    },
                    {
                        name: 'R',
                        slug: 'r',
                        year: '(2008-2014)',
                        catid: [961],
                    },
                ],
            },
            {
                name: 'Golf 7',
                slug: 'golf-7',
                image: 'images/no-image.jpg',
                catid: [209],
                children: [
                    {
                        name: '1.2 TSI',
                        slug: '1-2-tsi',
                        year: '(2012-now)',
                        catid: [1423],
                    },
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(2012-now)',
                        catid: [1158],
                    },
                ],
            },
            {
                name: 'Jetta',
                slug: 'jetta',
                image: 'images/no-image.jpg',
                catid: [165],
                children: [
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(1979-now)',
                        catid: [964],
                    },
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(1979-now)',
                        catid: [962],
                    },
                    {
                        name: '1.6 FSI',
                        slug: '1-6-fsi',
                        year: '(1979-now)',
                        catid: [963],
                    },
                    {
                        name: '2.0 FSI',
                        slug: '2-0-fsi',
                        year: '(1979-now)',
                        catid: [965],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(1979-now)',
                        catid: [967],
                    },
                    {
                        name: '2.0 TSI',
                        slug: '2-0-tsi',
                        year: '(1979-now)',
                        catid: [968],
                    },
                    {
                        name: '2.5',
                        slug: '2-5',
                        year: '(1979-now)',
                        catid: [966],
                    },
                    {
                        name: '1.4 GP',
                        slug: '1-4-gp',
                        year: '(1979-now)',
                        catid: [1224],
                    },
                ],
            },
            {
                name: 'Jetta GP',
                slug: 'jetta-gp',
                image: 'images/no-image.jpg',
                catid: [265],
                children: [
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(1979-now)',
                        catid: [1278],
                    },
                    {
                        name: '1.4 TSI 90',
                        slug: '1-4-tsi-90',
                        year: '(1979-now)',
                        catid: [1345],
                    },
                ],
            },
            {
                name: 'New Beetle',
                slug: 'new-beetle',
                image: 'images/no-image.jpg',
                catid: [161],
                children: [
                    {
                        name: '1.2',
                        slug: '1-2',
                        year: '(1998-2011)',
                        catid: [1455],
                    },
                    {
                        name: '1.4',
                        slug: '1-4',
                        year: '(1998-2011)',
                        catid: [935],
                    },
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(1998-2011)',
                        catid: [936],
                    },
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(1998-2011)',
                        catid: [938],
                    },
                ],
            },
            {
                name: 'New Golf',
                slug: 'new-golf',
                image: 'images/no-image.jpg',
                catid: [217],
                children: [
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(2012-now)',
                        catid: [1169],
                    },
                ],
            },
            {
                name: 'Passat',
                slug: 'passat',
                image: 'images/no-image.jpg',
                catid: [162],
                children: [
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(1972-now)',
                        catid: [944],
                    },
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(1972-now)',
                        catid: [945],
                    },
                    {
                        name: '1.6 FSI',
                        slug: '1-6-fsi',
                        year: '(1972-now)',
                        catid: [946],
                    },
                    {
                        name: '1.8 TSI',
                        slug: '1-8-tsi',
                        year: '(1972-now)',
                        catid: [947],
                    },
                    {
                        name: '1.8 TSI',
                        slug: '1-8-tsi',
                        year: '(1972-now)',
                        catid: [1373],
                    },
                    {
                        name: '2.0 FSI',
                        slug: '2-0-fsi',
                        year: '(1972-now)',
                        catid: [948],
                    },
                    {
                        name: '2.0 TFSI',
                        slug: '2-0-tfsi',
                        year: '(1972-now)',
                        catid: [949],
                    },
                    {
                        name: '2.0 TSI',
                        slug: '2-0-tsi',
                        year: '(1972-now)',
                        catid: [950],
                    },
                    {
                        name: '3.2 V6 FSI',
                        slug: '3-2-v6-fsi',
                        year: '(1972-now)',
                        catid: [951],
                    },
                    {
                        name: '3.6 V6 FSI',
                        slug: '3-6-v6-fsi',
                        year: '(1972-now)',
                        catid: [952],
                    },
                    {
                        name: 'R36',
                        slug: 'r36',
                        year: '(1972-now)',
                        catid: [953],
                    },
                ],
            },
            {
                name: 'Passat B8',
                slug: 'passat-b8',
                image: 'images/no-image.jpg',
                catid: [338],
                children: [
                    {
                        name: '1.8 TFSI',
                        slug: '1-8-tfsi',
                        year: '(2015-now)',
                        catid: [1448],
                    },
                ],
            },
            {
                name: 'Passat CC',
                slug: 'passat-cc',
                image: 'images/no-image.jpg',
                catid: [266],
                children: [
                    {
                        name: '1.8T AT',
                        slug: '1-8t-at',
                        year: '(2008-2012)',
                        catid: [1281],
                    },
                ],
            },
            {
                name: 'Phaeton',
                slug: 'phaeton',
                image: 'images/no-image.jpg',
                catid: [160],
                children: [
                    {
                        name: '3.2',
                        slug: '3-2',
                        year: '(2002-2016)',
                        catid: [943],
                    },
                    {
                        name: '3.6 V6 FSI',
                        slug: '3-6-v6-fsi',
                        year: '(2002-2016)',
                        catid: [942],
                    },
                ],
            },
            {
                name: 'Polo',
                slug: 'polo',
                image: 'images/no-image.jpg',
                catid: [166],
                children: [
                    {
                        name: '1.2',
                        slug: '1-2',
                        year: '(1975-now)',
                        catid: [969],
                    },
                    {
                        name: '1.4',
                        slug: '1-4',
                        year: '(1975-now)',
                        catid: [970],
                    },
                    {
                        name: '1.4 FSI',
                        slug: '1-4-fsi',
                        year: '(1975-now)',
                        catid: [971],
                    },
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(1975-now)',
                        catid: [972],
                    },
                    {
                        name: '1.8 T GTI',
                        slug: '1-8-t-gti',
                        year: '(1975-now)',
                        catid: [973],
                    },
                    {
                        name: '1.8 T GTI Cup Edition',
                        slug: '1-8-t-gti-cup-edition',
                        year: '(1975-now)',
                        catid: [974],
                    },
                ],
            },
            {
                name: 'Scirocco',
                slug: 'scirocco',
                image: 'images/no-image.jpg',
                catid: [243],
                children: [
                    {
                        name: '1.4 AT TSI',
                        slug: '1-4-at-tsi',
                        year: '(2008-2017)',
                        catid: [1221],
                    },
                    {
                        name: '2.0L',
                        slug: '2-0l',
                        year: '(2008-2017)',
                        catid: [1313],
                    },
                ],
            },
            {
                name: 'Scirocco R',
                slug: 'scirocco-r',
                image: 'images/no-image.jpg',
                catid: [262],
                children: [
                    {
                        name: '2.0L AT',
                        slug: '2-0l-at',
                        year: '(2008-2017)',
                        catid: [1262],
                    },
                ],
            },
            {
                name: 'Sharan',
                slug: 'sharan',
                image: 'images/no-image.jpg',
                catid: [320],
                children: [
                    {
                        name: '2.0TSI',
                        slug: '2-0tsi',
                        year: '(1995-now)',
                        catid: [1402],
                    },
                ],
            },
            {
                name: 'Tiguan',
                slug: 'tiguan',
                image: 'images/no-image.jpg',
                catid: [261],
                children: [
                    {
                        name: '2.0T',
                        slug: '2-0t',
                        year: '(2007-now)',
                        catid: [1256],
                    },
                    {
                        name: '2.0TSI AT',
                        slug: '2-0tsi-at',
                        year: '(2007-now)',
                        catid: [1259],
                    },
                ],
            },
            {
                name: 'Touareg',
                slug: 'touareg',
                image: 'images/no-image.jpg',
                catid: [241],
                children: [
                    {
                        name: '3.2 V6',
                        slug: '3-2-v6',
                        year: '(2002-now)',
                        catid: [1214],
                    },
                    {
                        name: '3.6 V6',
                        slug: '3-6-v6',
                        year: '(2002-now)',
                        catid: [1319],
                    },
                ],
            },
            {
                name: 'Touran',
                slug: 'touran',
                image: 'images/no-image.jpg',
                catid: [260],
                children: [
                    {
                        name: '1.4 L AT',
                        slug: '1-4-l-at',
                        year: '(2003-2021)',
                        catid: [1254],
                    },
                    {
                        name: '1.4 TSI',
                        slug: '1-4-tsi',
                        year: '(2003-2021)',
                        catid: [1305],
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        name: 'Jaguar',
        slug: 'jaguar',
        image: 'data/brand/jaguar.png',
        models: [
            {
                name: 'X Type',
                slug: 'x-type',
                image: 'images/no-image.jpg',
                catid: [180],
                children: [
                    {
                        name: '2.0 Executive',
                        slug: '2-0-executive',
                        year: '(2001-2009)',
                        catid: [1045],
                    },
                    {
                        name: '2.0 V6 SE Limited',
                        slug: '2-0-v6-se-limited',
                        year: '(2001-2009)',
                        catid: [1049],
                    },
                    {
                        name: '2.0 Sport Luxury',
                        slug: '2-0-sport-luxury',
                        year: '(2001-2009)',
                        catid: [1051],
                    },
                    {
                        name: '2.0 V6',
                        slug: '2-0-v6',
                        year: '(2001-2009)',
                        catid: [1047],
                    },
                    {
                        name: '2.0 SE',
                        slug: '2-0-se',
                        year: '(2001-2009)',
                        catid: [1048],
                    },
                    {
                        name: '2.5 V6',
                        slug: '2-5-v6',
                        year: '(2001-2009)',
                        catid: [1041],
                    },
                    {
                        name: '2.5 Executive',
                        slug: '2-5-executive',
                        year: '(2001-2009)',
                        catid: [1046],
                    },
                    {
                        name: '2.5 V6 Sport',
                        slug: '2-5-v6-sport',
                        year: '(2001-2009)',
                        catid: [1042],
                    },
                    {
                        name: '2.5 V6 SE',
                        slug: '2-5-v6-se',
                        year: '(2001-2009)',
                        catid: [1043],
                    },
                    {
                        name: '3.0 V6 SE',
                        slug: '3-0-v6-se',
                        year: '(2001-2009)',
                        catid: [1044],
                    },
                    {
                        name: '3.0 V6-Sport',
                        slug: '3-0-v6-sport',
                        year: '(2001-2009)',
                        catid: [1050],
                    },
                ],
            },
            {
                name: 'XF',
                slug: 'xf',
                image: 'images/no-image.jpg',
                catid: [245],
                children: [
                    {
                        name: '2.0',
                        slug: '2-2',
                        year: '(2007-now)',
                        catid: [1235],
                    },
                    {
                        name: '2.2',
                        slug: '2-2',
                        year: '(2007-now)',
                        catid: [1246],
                    },
                    {
                        name: '2.2I4D Auto',
                        slug: '2-2i4d-auto',
                        year: '(2007-now)',
                        catid: [1329],
                    },
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(2007-now)',
                        catid: [1227],
                    },
                    {
                        name: '4.2AT',
                        slug: '4-2at',
                        year: '(2007-now)',
                        catid: [1253],
                    },
                ],
            },
            {
                name: 'S Type',
                slug: 's-type',
                image: 'images/no-image.jpg',
                catid: [181],
                children: [
                    {
                        name: '2.5 V6',
                        slug: '2-5-v6',
                        year: '(1998-2008)',
                        catid: [1056],
                    },
                    {
                        name: '2.5SE',
                        slug: '2-5se',
                        year: '(1998-2008)',
                        catid: [1144],
                    },
                    {
                        name: '3.0 Executive',
                        slug: '3-0-executive',
                        year: '(1998-2008)',
                        catid: [1059],
                    },
                    {
                        name: '3.0 V6 SE',
                        slug: '3-0-v6-se',
                        year: '(1998-2008)',
                        catid: [1053],
                    },
                    {
                        name: '3.0 Sports',
                        slug: '3-0-sports',
                        year: '(1998-2008)',
                        catid: [1055],
                    },
                    {
                        name: '3.0 V6',
                        slug: '3-0-v6',
                        year: '(1998-2008)',
                        catid: [1052],
                    },
                    {
                        name: '4.0 V8',
                        slug: '4-0-v8',
                        year: '(1998-2008)',
                        catid: [1054],
                    },
                    {
                        name: '4.2 V8',
                        slug: '4-2-v8',
                        year: '(1998-2008)',
                        catid: [1057],
                    },
                    {
                        name: 'R',
                        slug: 'r',
                        year: '(1998-2008)',
                        catid: [1058],
                    },
                ],
            },
            {
                name: 'F Pace',
                slug: 'f-pace',
                image: 'images/no-image.jpg',
                catid: [321],
                children: [
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(2015-now)',
                        catid: [1404],
                    },
                ],
            },
            {
                name: 'XE',
                slug: 'xe',
                image: 'images/no-image.jpg',
                catid: [313],
                children: [
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(2015-now)',
                        catid: [1383],
                    },
                ],
            },
            {
                name: 'XJ',
                slug: 'xj',
                image: 'images/no-image.jpg',
                catid: [228],
                children: [
                    {
                        name: '2.0 TSS',
                        slug: '2-0-tss',
                        year: '(1968-2019)',
                        catid: [1390],
                    },
                    {
                        name: '3.0 SC PL LWB',
                        slug: '3-0-sc-pl-lwb',
                        year: '(1968-2019)',
                        catid: [1343],
                    },
                    {
                        name: '3.0L D',
                        slug: '3-0l-d',
                        year: '(1968-2019)',
                        catid: [1237],
                    },
                    {
                        name: '5.0L',
                        slug: '5-0l',
                        year: '(1968-2019)',
                        catid: [1192],
                    },
                ],
            },
            {
                name: 'XJ 6',
                slug: 'xj-6',
                image: 'images/no-image.jpg',
                catid: [182],
                children: [
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(1968-1993)',
                        catid: [1122],
                    },
                    {
                        name: '3.2',
                        slug: '3-2',
                        year: '(1968-1993)',
                        catid: [1232],
                    },
                ],
            },
            {
                name: 'XJ 8',
                slug: 'xj-8',
                image: 'images/no-image.jpg',
                catid: [198],
                children: [
                    {
                        name: '3.5',
                        slug: '3-5',
                        year: '(2003-2009)',
                        catid: [1110],
                    },
                ],
            },
            {
                name: 'XK8',
                slug: 'xk8',
                image: 'images/no-image.jpg',
                catid: [274],
                children: [
                    {
                        name: 'Coupe',
                        slug: 'coupe',
                        year: '(1996-2006)',
                        catid: [1302],
                    },
                ],
            },
        ],
    },
    {
        id: 8,
        name: 'Alfa Romeo',
        slug: 'alfa-romeo',
        image: 'data/brand/Alfa-Romeo.png',
        models: [
            {
                name: '147',
                slug: '147',
                image: 'images/no-image.jpg',
                catid: [236],
                children: [
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(2000-2010)',
                        catid: [1206],
                    },
                ],
            },
            {
                name: '156',
                slug: '156',
                image: 'images/no-image.jpg',
                catid: [227],
                children: [
                    {
                        name: '2.0 JTS',
                        slug: '2-0-jts',
                        year: '(1997-2007)',
                        catid: [1191],
                    },
                ],
            },
            {
                name: '159',
                slug: '159',
                image: 'images/no-image.jpg',
                catid: [202],
                children: [
                    {
                        name: '1.75 TBI',
                        slug: '1-75-tbi',
                        year: '(2005-2012)',
                        catid: [1131],
                    },
                    {
                        name: '2.2 JTS',
                        slug: '2-2-jts',
                        year: '(2005-2012)',
                        catid: [1160],
                    },
                    {
                        name: '3.2',
                        slug: '3-2',
                        year: '(2005-2012)',
                        catid: [1197],
                    },
                    {
                        name: 'JTS',
                        slug: 'jts',
                        year: '(2005-2012)',
                        catid: [1261],
                    },
                ],
            },
            {
                name: 'AR GT',
                slug: 'ar-gt',
                image: 'images/no-image.jpg',
                catid: [204],
                children: [
                    {
                        name: '2.0 JTS',
                        slug: '2-0-jts',
                        year: '(2003-2010)',
                        catid: [1147],
                    },
                    {
                        name: '3.2 V6',
                        slug: '3-2-v6',
                        year: '(2003-2010)',
                        catid: [1137],
                    },
                ],
            },
        ],
    },
    {
        id: 15,
        name: 'Aston Martin',
        slug: 'aston-martin',
        image: 'data/brand/Aston-Martin.png',
        models: [
            {
                name: 'DB9',
                slug: 'db9',
                image: 'images/no-image.jpg',
                catid: [258],
                children: [
                    {
                        name: '6.0',
                        slug: '6-0',
                        year: '(2004-2016)',
                        catid: [1248],
                    },
                ],
            },
            {
                name: 'DBS Coupe',
                slug: 'dbs-coupe',
                image: 'images/no-image.jpg',
                catid: [340],
                children: [
                    {
                        name: '6.0L',
                        slug: '6-0l',
                        year: '(2007-now)',
                        catid: [1450],
                    },
                ],
            },
        ],
    },
    {
        id: 11,
        name: 'Bentley',
        slug: 'bentley',
        image: 'data/brand/Bentley.png',
        models: [
            {
                name: 'Continental GT',
                slug: 'continental-gt',
                image: 'images/no-image.jpg',
                catid: [327],
                children: [
                    {
                        name: 'V8',
                        slug: 'v8',
                        year: '(2003-now)',
                        catid: [1416],
                    },
                ],
            },
            {
                name: 'Flying Spur',
                slug: 'flying-spur',
                image: 'images/no-image.jpg',
                catid: [289],
                children: [
                    {
                        name: '6.0 V12',
                        slug: '6-0-v12',
                        year: '(2006-now)',
                        catid: [1339],
                    },
                ],
            },
        ],
    },
    {
        id: 14,
        name: 'Lamborghini',
        slug: 'lamborghini',
        image: 'data/brand/lamborghini.png',
        models: [
            {
                name: 'Gallardo',
                slug: 'gallardo',
                image: 'images/no-image.jpg',
                catid: [271],
                children: [
                    {
                        name: '5.0',
                        slug: '5-0',
                        year: '(2003-now)',
                        catid: [1296],
                    },
                    {
                        name: '5.2 LP570 4',
                        slug: '5-2-lp570-4',
                        year: '(2003-now)',
                        catid: [1461],
                    },
                ],
            },
            {
                name: 'Gallardo LP570 4',
                slug: 'gallardo-lp570-4',
                image: 'images/no-image.jpg',
                catid: [344],
                children: [
                    {
                        name: '5.2',
                        slug: '5-2',
                        year: '(2010-2013)',
                        catid: [1462],
                    },
                ],
            },
        ],
    },
    {
        id: 13,
        name: 'Ferrari',
        slug: 'ferrari',
        image: 'data/brand/Ferrari.png',
        models: [
            {
                name: 'Berlinetta',
                slug: 'berlinetta',
                image: 'images/no-image.jpg',
                catid: [315],
                children: [
                    {
                        name: 'F12',
                        slug: 'f12',
                        year: '(2012-now)',
                        catid: [1387],
                    },
                ],
            },
            {
                name: 'California',
                slug: 'california',
                image: 'images/no-image.jpg',
                catid: [250],
                children: [
                    {
                        name: '4.3 L',
                        slug: '4-3-l',
                        year: '(2008-now)',
                        catid: [1236],
                    },
                ],
            },
            {
                name: 'Italia',
                slug: 'italia',
                image: 'images/no-image.jpg',
                catid: [259],
                children: [
                    {
                        name: '458',
                        slug: '458',
                        year: '(2009-now)',
                        catid: [1250],
                    },
                ],
            },
        ],
    },
    {
        id: 17,
        name: 'Land Rover',
        slug: 'land-rover',
        image: 'data/brand/Land-Rover.png',
        models: [
            {
                name: 'Discovery 4',
                slug: 'discovery-4',
                image: 'images/no-image.jpg',
                catid: [272],
                children: [
                    {
                        name: '3.0 AT',
                        slug: '3-0-at',
                        year: '(2009-now)',
                        catid: [1299],
                    },
                ],
            },
            {
                name: 'Discovery Sport',
                slug: 'discovery-sport',
                image: 'images/no-image.jpg',
                catid: [279],
                children: [
                    {
                        name: '2.0 SI4',
                        slug: '2-0-si4',
                        year: '(2014-now)',
                        catid: [1308],
                    },
                ],
            },
            {
                name: 'Freelander 2',
                slug: 'freelander-2',
                image: 'images/no-image.jpg',
                catid: [318],
                children: [
                    {
                        name: '3.2L',
                        slug: '3-2l',
                        year: '(2006-2015)',
                        catid: [1397],
                    },
                ],
            },
            {
                name: 'Range Rover',
                slug: 'range-rover',
                image: 'images/no-image.jpg',
                catid: [328],
                children: [
                    {
                        name: '5.0L',
                        slug: '5-0l',
                        year: '(1969-now)',
                        catid: [1418],
                    },
                ],
            },
            {
                name: 'Range Rover Sport',
                slug: 'range-rover-sport',
                image: 'images/no-image.jpg',
                catid: [322],
                children: [
                    {
                        name: '3.0L',
                        slug: '3-0l',
                        year: '(2013-now)',
                        catid: [1405],
                    },
                ],
            },
        ],
    },
    {
        id: 7,
        name: 'Lexus',
        slug: 'lexus',
        image: 'data/brand/Lexus.png',
        models: [
            {
                name: 'GS 300',
                slug: 'gs-300',
                image: 'images/no-image.jpg',
                catid: [184],
                children: [
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(1993-2004)',
                        catid: [1066],
                    },
                ],
            },
            {
                name: 'IS 250',
                slug: 'is-250',
                image: 'images/no-image.jpg',
                catid: [183],
                children: [
                    {
                        name: 'Version F',
                        slug: 'version-f',
                        year: '(2005-2013)',
                        catid: [1063],
                    },
                    {
                        name: 'F Sport',
                        slug: 'f-sport',
                        year: '(2005-2013)',
                        catid: [1065],
                    },
                    {
                        name: 'Version I',
                        slug: 'version-i',
                        year: '(2005-2013)',
                        catid: [1062],
                    },
                    {
                        name: 'Version L',
                        slug: 'version-l',
                        year: '(2005-2013)',
                        catid: [1061],
                    },
                    {
                        name: 'Version S',
                        slug: 'version-s',
                        year: '(2005-2013w)',
                        catid: [1060],
                    },
                    {
                        name: 'X Edition',
                        slug: 'x-edition',
                        year: '(2005-2013)',
                        catid: [1064],
                    },
                ],
            },
            {
                name: 'RX 300',
                slug: 'rx-300',
                image: 'images/no-image.jpg',
                catid: [185],
                children: [
                    {
                        name: '2.7',
                        slug: '2-7',
                        year: '(1998-2003)',
                        catid: [1070],
                    },
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(1998-2003)',
                        catid: [1067],
                    },
                    {
                        name: '3.3',
                        slug: '3-3',
                        year: '(1998-2003)',
                        catid: [1068],
                    },
                    {
                        name: '3.5',
                        slug: '3-5',
                        year: '(1998-2003)',
                        catid: [1069],
                    },
                ],
            },
        ],
    },
    {
        id: 18,
        name: 'Lotus',
        slug: 'lotus',
        image: 'data/brand/Lotus.png',
        models: [
            {
                name: 'Elise',
                slug: 'elise',
                image: 'images/no-image.jpg',
                catid: [332],
                children: [
                    {
                        name: 'S1',
                        slug: 's1',
                        year: '(2000-now)',
                        catid: [1431],
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        name: 'Maserati',
        slug: 'maserati',
        image: 'data/brand/Maserati.png',
        models: [
            {
                name: 'Grancabrio',
                slug: 'grancabrio',
                image: 'images/no-image.jpg',
                catid: [211],
                children: [
                    {
                        name: '4.7A',
                        slug: '4-7a',
                        year: '(2010-now)',
                        catid: [1161],
                    },
                ],
            },
            {
                name: 'Ghibli S',
                slug: 'ghibli-s',
                image: 'images/no-image.jpg',
                catid: [319],
                children: [
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(2013-now)',
                        catid: [1401],
                    },
                ],
            },
            {
                name: 'Granturismo',
                slug: 'granturismo',
                image: 'images/no-image.jpg',
                catid: [281],
                children: [
                    {
                        name: '4.2 Auto',
                        slug: '4-2-auto',
                        year: '(2007-now)',
                        catid: [1311],
                    },
                    {
                        name: '4.7',
                        slug: '4-7',
                        year: '(2007-now)',
                        catid: [1459],
                    },
                ],
            },
            {
                name: 'Quattroporte',
                slug: 'quattroporte',
                image: 'images/no-image.jpg',
                catid: [203],
                children: [
                    {
                        name: '4.2',
                        slug: '4-2',
                        year: '(2004-now)',
                        catid: [1295],
                    },
                    {
                        name: '4.3',
                        slug: '4-3',
                        year: '(2004-now)',
                        catid: [1134],
                    },
                ],
            },
        ],
    },
    {
        id: 19,
        name: 'Mclaren',
        slug: 'mclaren',
        image: 'data/brand/McLaren.png',
        models: [
            {
                name: 'MP4 12C',
                slug: 'mp4-12c',
                image: 'images/no-image.jpg',
                catid: [308],
                children: [
                    {
                        name: '650S Spide',
                        slug: '650s-spide',
                        year: '(2011-2014)',
                        catid: [1377],
                    },
                ],
            },
        ],
    },
    {
        id: 12,
        name: 'Mini',
        slug: 'mini',
        image: 'data/brand/Mini.png',
        models: [
            {
                name: 'R56',
                slug: 'r56',
                image: 'images/no-image.jpg',
                catid: [139],
                children: [
                    {
                        name: 'Cooper S BEV',
                        slug: 'cooper-s-bev',
                        year: '(2005-2014)',
                        catid: [311],
                    },
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2005-2014)',
                        catid: [312],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2005-2014)',
                        catid: [313],
                    },
                    {
                        name: 'Cooper D',
                        slug: 'cooper-d',
                        year: '(2005-2014)',
                        catid: [314],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2005-2014)',
                        catid: [315],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2005-2014)',
                        catid: [316],
                    },
                    {
                        name: 'One 1.4L Auto',
                        slug: 'one-1.4l-auto',
                        year: '(2005-2014)',
                        catid: [1291],
                    },
                    {
                        name: 'One D',
                        slug: 'one-d',
                        year: '(2005-2014)',
                        catid: [317],
                    },
                ],
            },
            {
                name: 'F56',
                slug: 'f56',
                image: 'images/no-image.jpg',
                catid: [141],
                children: [
                    {
                        name: '1.6L',
                        slug: '1-6l',
                        year: '(2013-now)',
                        catid: [1338],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2013-now)',
                        catid: [326],
                    },
                    {
                        name: 'Cooper D',
                        slug: 'cooper-d',
                        year: '(2013-now)',
                        catid: [1333],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2013-now)',
                        catid: [327],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2013-now)',
                        catid: [328],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2013-now)',
                        catid: [329],
                    },
                    {
                        name: 'One 55KW',
                        slug: 'one-55kw',
                        year: '(2013-now)',
                        catid: [330],
                    },
                ],
            },
            {
                name: 'R50',
                slug: 'r50',
                image: 'images/no-image.jpg',
                catid: [137],
                children: [
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2001-2006)',
                        catid: [305],
                    },
                    {
                        name: 'One 1.4I',
                        slug: 'one-1-4I',
                        year: '(2001-2006)',
                        catid: [306],
                    },
                    {
                        name: 'One 1.6I',
                        slug: 'one-1-6i',
                        year: '(2001-2006)',
                        catid: [307],
                    },
                    {
                        name: 'One D',
                        slug: 'one-d',
                        year: '(2001-2006)',
                        catid: [308],
                    },
                ],
            },
            {
                name: 'R52',
                slug: 'r52',
                image: 'images/no-image.jpg',
                catid: [144],
                children: [
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2004-2008)',
                        catid: [340],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2004-2008)',
                        catid: [341],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2004-2008)',
                        catid: [342],
                    },
                ],
            },
            {
                name: 'R53',
                slug: 'r53',
                image: 'images/no-image.jpg',
                catid: [138],
                children: [
                    {
                        name: 'Cooper S JCW GP',
                        slug: 'cooper-s-jcw-gp',
                        year: '(2001-2006)',
                        catid: [309],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2001-2006)',
                        catid: [310],
                    },
                ],
            },
            {
                name: 'R55',
                slug: 'r55',
                image: 'images/no-image.jpg',
                catid: [142],
                children: [
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2006-2015)',
                        catid: [331],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2006-2015)',
                        catid: [332],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2006-2015)',
                        catid: [333],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2006-2015)',
                        catid: [334],
                    },
                ],
            },
            {
                name: 'R55 LCI',
                slug: 'r55-lci',
                image: 'images/no-image.jpg',
                catid: [143],
                children: [
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2006-2015)',
                        catid: [335],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2006-2015)',
                        catid: [336],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2006-2015)',
                        catid: [337],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2006-2015)',
                        catid: [338],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2006-2015)',
                        catid: [339],
                    },
                ],
            },
            {
                name: 'R56 LCI',
                slug: 'r56-lci',
                image: 'images/no-image.jpg',
                catid: [140],
                children: [
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2005-2014)',
                        catid: [318],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2005-2014)',
                        catid: [319],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2005-2014)',
                        catid: [320],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2005-2014)',
                        catid: [321],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2005-2014)',
                        catid: [322],
                    },
                    {
                        name: 'One 55KW',
                        slug: 'one-55kw',
                        year: '(2005-2014)',
                        catid: [323],
                    },
                    {
                        name: 'One-Eco',
                        slug: 'one-eco',
                        year: '(2005-2014)',
                        catid: [324],
                    },
                    {
                        name: 'One Eco 55KW',
                        slug: 'one-eco-55kw',
                        year: '(2005-2014)',
                        catid: [325],
                    },
                ],
            },
            {
                name: 'R57',
                slug: 'r57',
                image: 'images/no-image.jpg',
                catid: [145],
                children: [
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2007-2015)',
                        catid: [345],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2007-2015)',
                        catid: [343],
                    },
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2007-2015)',
                        catid: [344],
                    },
                ],
            },
            {
                name: 'R57 LCI',
                slug: 'r57-lci',
                image: 'images/no-image.jpg',
                catid: [146],
                children: [
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2007-2015)',
                        catid: [347],
                    },
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2007-2015)',
                        catid: [348],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2007-2015)',
                        catid: [346],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2007-2015)',
                        catid: [349],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2007-2015)',
                        catid: [350],
                    },
                ],
            },
            {
                name: 'R58',
                slug: 'r58',
                image: 'images/no-image.jpg',
                catid: [147],
                children: [
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2010-2015)',
                        catid: [352],
                    },
                    {
                        name: 'Cooper S JCW',
                        slug: 'cooper-s-jcw',
                        year: '(2010-2015)',
                        catid: [354],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2010-2015)',
                        catid: [353],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2010-2015)',
                        catid: [351],
                    },
                ],
            },
            {
                name: 'R60 Countryman',
                slug: 'r60-countryman',
                image: 'images/no-image.jpg',
                catid: [149],
                children: [
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2010-2016)',
                        catid: [361],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2010-2016)',
                        catid: [362],
                    },
                    {
                        name: 'Cooper SDX',
                        slug: 'cooper-sdx',
                        year: '(2010-2016)',
                        catid: [363],
                    },
                    {
                        name: 'Cooper SX JCW',
                        slug: 'cooper-sx-jcw',
                        year: '(2010-2016)',
                        catid: [359],
                    },
                    {
                        name: 'Cooper X',
                        slug: 'cooper-x',
                        year: '(2010-2016)',
                        catid: [365],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2010-2016)',
                        catid: [360],
                    },
                    {
                        name: 'Cooper SX',
                        slug: 'cooper-sx',
                        year: '(2010-2016)',
                        catid: [364],
                    },
                    {
                        name: 'One',
                        slug: 'one',
                        year: '(2010-2016)',
                        catid: [366],
                    },
                ],
            },
            {
                name: 'R61',
                slug: 'r61',
                image: 'images/no-image.jpg',
                catid: [150],
                children: [
                    {
                        name: 'Cooper S',
                        slug: 'cooper-s',
                        year: '(2012-2016)',
                        catid: [369],
                    },
                    {
                        name: 'Cooper SD',
                        slug: 'cooper-sd',
                        year: '(2012-2016)',
                        catid: [370],
                    },
                    {
                        name: 'Cooper SDX',
                        slug: 'cooper-sdx',
                        year: '(2012-2016)',
                        catid: [371],
                    },
                    {
                        name: 'Cooper SX JCW',
                        slug: 'cooper-sx-jcw',
                        year: '(2012-2016)',
                        catid: [367],
                    },
                    {
                        name: 'Cooper',
                        slug: 'cooper',
                        year: '(2012-2016)',
                        catid: [368],
                    },
                    {
                        name: 'Cooper SX',
                        slug: 'cooper-sx',
                        year: '(2012-2016)',
                        catid: [372],
                    },
                    {
                        name: 'Cooper X',
                        slug: 'cooper-x',
                        year: '(2012-2016)',
                        catid: [373],
                    },
                ],
            },
        ],
    },
    {
        id: 10,
        name: 'Peugeot',
        slug: 'peugeot',
        image: 'data/brand/Peugeot.png',
        models: [
            {
                name: '206CC',
                slug: '206cc',
                image: 'images/no-image.jpg',
                catid: [192],
                children: [
                    {
                        name: '1.6A',
                        slug: '1-6a',
                        year: '(2000-2008)',
                        catid: [1100],
                    },
                ],
            },
            {
                name: '207CC',
                slug: '207cc',
                image: 'images/no-image.jpg',
                catid: [252],
                children: [
                    {
                        name: '1.6A',
                        slug: '1-6a',
                        year: '(2007-2015)',
                        catid: [1239],
                    },
                ],
            },
            {
                name: '307CC',
                slug: '307cc',
                image: 'images/no-image.jpg',
                catid: [214],
                children: [
                    {
                        name: '2.0AT',
                        slug: '2-0at',
                        year: '(2003-2009)',
                        catid: [1165],
                    },
                ],
            },
            {
                name: '407',
                slug: '407',
                image: 'images/no-image.jpg',
                catid: [193],
                children: [
                    {
                        name: '1.8',
                        slug: '1-8',
                        year: '(2004-2011)',
                        catid: [1101],
                    },
                    {
                        name: '2.0',
                        slug: '2-0',
                        year: '(2004-2011)',
                        catid: [1102],
                    },
                    {
                        name: '2.2',
                        slug: '2-2',
                        year: '(2004-2011)',
                        catid: [1103],
                    },
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(2004-2011)',
                        catid: [1104],
                    },
                ],
            },
            {
                name: '5008',
                slug: '5008',
                image: 'images/no-image.jpg',
                catid: [268],
                children: [
                    {
                        name: '1.6 Turbo',
                        slug: '1-6-turbo',
                        year: '(2009-now)',
                        catid: [1285],
                    },
                ],
            },
            {
                name: '508',
                slug: '508',
                image: 'images/no-image.jpg',
                catid: [254],
                children: [
                    {
                        name: '1.6',
                        slug: '1-6',
                        year: '(2010-now)',
                        catid: [1241],
                    },
                ],
            },
        ],
    },
    {
        id: 9,
        name: 'Porsche',
        slug: 'porsche',
        image: 'data/brand/porsche.png',
        models: [
            {
                name: '911',
                slug: '911',
                image: 'images/no-image.jpg',
                catid: [186],
                children: [
                    {
                        name: '991',
                        slug: '991',
                        year: '(1964-now)',
                        catid: [1176],
                    },
                    {
                        name: '996',
                        slug: '996',
                        year: '(1964-now)',
                        catid: [1149],
                    },
                    {
                        name: '997',
                        slug: '997',
                        year: '(1964-now)',
                        catid: [1148],
                    },
                    {
                        name: 'C4S TIP',
                        slug: 'c4s-tip',
                        year: '(1964-now)',
                        catid: [1258],
                    },
                    {
                        name: 'Cabrio C4S',
                        slug: 'cabrio-c4s',
                        year: '(1964-now)',
                        catid: [1076],
                    },
                    {
                        name: 'Turbo',
                        slug: 'turbo',
                        year: '(1964-now)',
                        catid: [1077],
                    },
                    {
                        name: 'Turbo S',
                        slug: 'turbo-s',
                        year: '(1964-now)',
                        catid: [1078],
                    },
                ],
            },
            {
                name: '987 Boxster',
                slug: '987-boxster',
                image: 'images/no-image.jpg',
                catid: [267],
                children: [
                    {
                        name: 'Tip Tronic',
                        slug: 'tip-tronic',
                        year: '(2004-2013)',
                        catid: [1282],
                    },
                ],
            },
            {
                name: 'Boxster S',
                slug: 'boxster-s',
                image: 'images/no-image.jpg',
                catid: [221],
                children: [
                    {
                        name: '981',
                        slug: '981',
                        year: '(2012-now)',
                        catid: [1178],
                    },
                    {
                        name: '986',
                        slug: '986',
                        year: '(1996-2004)',
                        catid: [1179],
                    },
                    {
                        name: '987',
                        slug: '987',
                        year: '(2004-2013)',
                        catid: [1177],
                    },
                ],
            },
            {
                name: 'Boxster',
                slug: 'boxster',
                image: 'images/no-image.jpg',
                catid: [188],
                children: [
                    {
                        name: '718',
                        slug: 'boxster-718',
                        year: '(2016-now)',
                        catid: [1403],
                    },
                    {
                        name: '981',
                        slug: '981',
                        year: '(2012-now)',
                        catid: [1081],
                    },
                    {
                        name: '986',
                        slug: '986',
                        year: '(1996-2004)',
                        catid: [1079],
                    },
                    {
                        name: '987',
                        slug: '987',
                        year: '(2004-2013)',
                        catid: [1080],
                    },
                ],
            },
            {
                name: 'Carrera',
                slug: 'carrera',
                image: 'images/no-image.jpg',
                catid: [208],
                children: [
                    {
                        name: '911 C2S 3.8A TIP',
                        slug: '911-c2s-3-8a-tip',
                        year: '(2003-2006)',
                        catid: [1154],
                    },
                ],
            },
            {
                name: 'Cayenne',
                slug: 'cayenne',
                image: 'images/no-image.jpg',
                catid: [187],
                children: [
                    {
                        name: '955',
                        slug: '955',
                        year: '(2002-now)',
                        catid: [1082],
                    },
                    {
                        name: '957',
                        slug: '957',
                        year: '(2002-now)',
                        catid: [1083],
                    },
                    {
                        name: 'Cayenne Turbo',
                        slug: 'Cayenne-turbo',
                        year: '(2002-now)',
                        catid: [1275],
                    },
                    {
                        name: 'S Tiptronic',
                        slug: 's-tiptronic',
                        year: '(2002-now)',
                        catid: [1279],
                    },
                    {
                        name: 'Tip tronic',
                        slug: 'tip-tronic',
                        year: '(2002-now)',
                        catid: [1244],
                    },
                    {
                        name: 'Turbo',
                        slug: 'turbo',
                        year: '(2002-now)',
                        catid: [1243],
                    },
                ],
            },
            {
                name: 'Cayman S',
                slug: 'cayman-s',
                image: 'images/no-image.jpg',
                catid: [222],
                children: [
                    {
                        name: '981',
                        slug: '981',
                        year: '(2005-now)',
                        catid: [1180],
                    },
                    {
                        name: '987',
                        slug: '987',
                        year: '(2005-now)',
                        catid: [1181],
                    },
                ],
            },
            {
                name: 'Cayenne S',
                slug: 'cayenne-s',
                image: 'images/no-image.jpg',
                catid: [263],
                children: [
                    {
                        name: '957',
                        slug: '957',
                        year: '(2002-now)',
                        catid: [1264],
                    },
                    {
                        name: '958',
                        slug: '958',
                        year: '(2002-now)',
                        catid: [1400],
                    },
                ],
            },
            {
                name: 'Cayenne Turbo',
                slug: 'cayenne-turbo',
                image: 'images/no-image.jpg',
                catid: [264],
                children: [
                    {
                        name: '957',
                        slug: '957',
                        year: '(2002-now)',
                        catid: [1276],
                    },
                ],
            },
            {
                name: 'Cayman',
                slug: 'cayman',
                image: 'images/no-image.jpg',
                catid: [189],
                children: [
                    {
                        name: '3.4',
                        slug: '3-4',
                        year: '(2005-now)',
                        catid: [1118],
                    },
                    {
                        name: '3.5',
                        slug: '3-5',
                        year: '(2005-now)',
                        catid: [1251],
                    },
                    {
                        name: '981',
                        slug: '981',
                        year: '(2005-now)',
                        catid: [1085],
                    },
                    {
                        name: '987',
                        slug: '987',
                        year: '(2005-now)',
                        catid: [1084],
                    },
                ],
            },
            {
                name: 'Macan',
                slug: 'macan',
                image: 'images/no-image.jpg',
                catid: [303],
                children: [
                    {
                        name: '95B',
                        slug: '95b',
                        year: '(2014-now)',
                        catid: [1364],
                    },
                ],
            },
            {
                name: 'Macan S',
                slug: 'macan-s',
                image: 'images/no-image.jpg',
                catid: [310],
                children: [
                    {
                        name: '3.0',
                        slug: '3-0',
                        year: '(2014-now)',
                        catid: [1380],
                    },
                ],
            },
            {
                name: 'Panamera',
                slug: 'panamera',
                image: 'images/no-image.jpg',
                catid: [251],
                children: [
                    {
                        name: '3.6',
                        slug: '3-6',
                        year: '(2009-now)',
                        catid: [1238],
                    },
                    {
                        name: '970',
                        slug: '970',
                        year: '(2009-now)',
                        catid: [1365],
                    },
                ],
            },
            {
                name: 'Panamera D',
                slug: 'panamera-d',
                image: 'images/no-image.jpg',
                catid: [330],
                children: [
                    {
                        name: '300HP V6',
                        slug: '300hp-v6',
                        year: '(2009-now)',
                        catid: [1420],
                    },
                ],
            },
            {
                name: 'Panamera S',
                slug: 'panamera-s',
                image: 'images/no-image.jpg',
                catid: [309],
                children: [
                    {
                        name: '4.8',
                        slug: '4-8',
                        year: '(2009-now)',
                        catid: [1379],
                    },
                ],
            },
        ],
    },
];

export default brandList;
