// react
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import qs from 'query-string';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';

export default function AccountPageDashboard() {
    const [details, setDetails] = useState([]);
    const data = {
        userId: 1,
        orderId: 2021
    }
    function getDashboardData() {
        fetch(`http://localhost:5000/dashboard.json?${qs.stringify(data)}`, {
            method: "GET",
            mode: 'no-cors',
            headers: { "Content-type": "application/json" },
        }).then((response) => response.json())
            .then((data) => {
                setDetails(data)
            });
    }

    useEffect(() => {
        getDashboardData();
    }, []);

    return (
        <div>
            {details.map((item) => (
                <div className="dashboard">
                    <Helmet>
                        <title>{`My Account — ${theme.name}`}</title>
                    </Helmet>
                    <div className="dashboard__profile card profile-card">
                        <div className="card-body profile-card__body">
                            <div className="profile-card__avatar">
                                <img src="images/avatars/avatar-3.jpg" alt="" />
                            </div>
                            <div className="profile-card__name"></div>
                            <div className="profile-card__email">{item.email}</div>
                            <div className="profile-card__edit">
                                <Link to={{pathname: 'profile', emailVal: `?email=${item.email}`,firstVal:`?first=${item.firstName}`,lastVal:`?last=${item.lastName}`,phoneVal:`?phone=${item.phone}`}} className="btn btn-secondary btn-sm">Edit Profile</Link>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__address card address-card address-card--featured">
                        {item.default && <div className="address-card__badge">Default Address</div>}
                        <div className="address-card__body">
                            <div className="address-card__name">{item.firstName} {item.lastName}</div>
                            <div className="address-card__row">
                                {item.country}
                                <br />
                                {item.postcode}
                                ,
                                {item.city}
                                <br />
                                {item.address}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{item.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{item.email}</div>
                            </div>
                            <div className="address-card__footer">
                            <Link to={{pathname:"/account/addresses/5", emailAddr: `?email=${item.email}`,firstAddr:`?first=${item.firstName}`,lastAddr:`?last=${item.lastName}`                       
                        ,phoneAddr:`?phone=${item.phone}`,cityAddr:`?city=${item.city}`,countryAddr:`?country=${item.country}`,stateAddr:`?state=${item.state}`,postcodeAddr:`?postcode=${item.postcode}`
                        ,companyaddr:`?company=${item.company}`,streetaddr:`?street=${item.street}`,apartmentaddr:`?apartment=${item.apartment}`,address:`?address=${item.address}`}}>Edit</Link>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__orders card">
                        <div className="card-header">
                            <h5>Recent Orders</h5>
                        </div>
                        <div className="card-divider" />
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Order</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {(item.orderDetails).map((order) => (
                                        <tbody>
                                            <td>{order.id}</td>
                                            <td>{order.date}</td>
                                            <td>{order.status}</td>
                                            <td>{order.total}</td>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        // ))        
    );
}
