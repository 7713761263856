/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// react
import React, { useMemo } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import shopApi from '../../api/shop';
import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';

// blocks
// import BlockBanner from '../blocks/BlockBanner';
import BlockBrands from '../blocks/BlockBrands';
// import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
// import BlockPosts from '../blocks/BlockPosts';
// import BlockProductColumns from '../blocks/BlockProductColumns';
// import BlockProducts from '../blocks/BlockProducts';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
// import categories from '../../data/shopBlockCategories';
// import posts from '../../data/blogPosts';
import theme from '../../data/theme';
// import products from '../../data/shopProducts';
// import products from '../../data/shopAllProducts';
import InfiniteScroll from '../blocks/BlockInfiniteScroll';
import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';

function HomePageTwo() {
    /**
     * Featured products.
     */
    const featuredProducts = useProductTabs(
        useMemo(() => [
            { id: 1, name: 'All', categorySlug: undefined },
            { id: 2, name: 'Brakes', categorySlug: 'brakes' },
            { id: 3, name: 'Lights', categorySlug: 'lights' },
            { id: 4, name: 'Engines', categorySlug: 'engines' },
        ], []),
        (tab) => shopApi.getPopularProducts({ limit: 15, category: tab.categorySlug }),
    );

    /**
     * Trending sale.
     */
    const trendingsale = useDeferredData(() => (
        shopApi.getPopularProducts({ limit: 50 })
    ), []);

    /**
     * Latest products.
     */
    const latestProducts = useProductTabs(
        useMemo(() => [
            { id: 1, name: 'All', categorySlug: undefined },
            { id: 2, name: 'Brakes', categorySlug: 'brakes' },
            { id: 3, name: 'Lights', categorySlug: 'lights' },
            { id: 4, name: 'Engines', categorySlug: 'engines' },
        ], []),
        (tab) => shopApi.getLatestProducts({ limit: 25, category: tab.categorySlug }),
    );

    /**
     * Top rated.
     */
    const toprated = useDeferredData(() => (
        shopApi.getPopularProducts({ limit: 20 })
    ), []);

    /**
     * ALL PRODUCTS
     */

    const allProducts = useDeferredData(() => (
        shopApi.getPopularProducts({ limit: 1000 })
    ), []);

    /**
     * Product columns.
     */
    const columns = useProductColumns(
        useMemo(() => [
            {
                title: 'Top Rated Products',
                source: () => shopApi.getTopRatedProducts({ limit: 3 }),
            },
            {
                title: 'Special Offers',
                source: () => shopApi.getDiscountedProducts({ limit: 3 }),
            },
            {
                title: 'Bestsellers',
                source: () => shopApi.getPopularProducts({ limit: 3 }),
            },
        ], []),
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home — ${theme.name}`}</title>
            </Helmet>

            {useMemo(() => <BlockSlideShow />, [])}

            <BlockBrands />

            {useMemo(() => (
                <BlockProductsCarousel
                    title="Deals of the Day"
                    layout="grid-5"
                    rows={1}
                    products={featuredProducts.data}
                    loading={featuredProducts.isLoading}
                    // groups={featuredProducts.tabs}
                    onGroupClick={featuredProducts.handleTabChange}
                />
            ), [featuredProducts])}

            {/* {useMemo(() => <BlockBanner />, [])} */}

            {useMemo(() => (
                <BlockProductsCarousel
                    title="Trending Sale"
                    layout="grid-5"
                    rows={1}
                    products={trendingsale.data.slice(25, 40)}
                    loading={trendingsale.isLoading}
                    // groups={featuredProducts.tabs}
                    onGroupClick={trendingsale.handleTabChange}
                />
            ), [trendingsale.data])}

            {useMemo(() => <BlockFeatures layout="boxed" />, [])}

            {/* <BlockCategories title="Popular Categories" layout="compact" categories={categories} />

            <BlockPosts title="Latest News" layout="grid-nl" posts={posts} /> */}

            {/* <BlockProductColumns columns={columns} /> */}

            {/* must<BlockTabbedProductsCarousel title="Deals of the Day" layout="grid-5" rows={1} products={ products.slice(0, 20)}/>

            <BlockTabbedProductsCarousel title="Trending Sale" layout="grid-5" rows={1} products={ products.slice(21, 40)}/> */}

            {/* must<BlockTabbedProductsCarousel title="Top Rated" layout="grid-5" rows={1} products={ products.slice(40, 60)}/>  */}

            {useMemo(() => (
                <BlockProductsCarousel
                    title="Top Rated"
                    layout="grid-5"
                    rows={1}
                    products={toprated.data.slice(5, 18)}
                    loading={toprated.isLoading}
                    // groups={featuredProducts.tabs}
                    onGroupClick={toprated.handleTabChange}
                />
            ), [toprated.data])}

            {/* <BlockTabbedProductsCarousel title="New Arrivals" layout="grid-5" /> */}
            {useMemo(() => (
                <BlockProductsCarousel
                    title="New Arrivals"
                    layout="grid-5"
                    products={latestProducts.data}
                    loading={latestProducts.isLoading}
                    // groups={latestProducts.tabs}
                    onGroupClick={latestProducts.handleTabChange}
                />
            ), [latestProducts])}

            <InfiniteScroll layout="grid-5" rows={1} products={allProducts.data} />

        </React.Fragment>
    );
}

export default HomePageTwo;
