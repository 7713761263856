/* eslint-disable */
// react
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import config from '../../data/configurations';

export default function AccountNewAddress() {
    const [addvalues, setAddress] = useState('');
    const [txt, addressTxt] = useState('Add');
    const handleAddAddressChange = (e) => {
        const { name, value } = e.target;
        console.log(value)
        console.log(isdisabled);
        useEffect(() => {
            setAddress({
                ...addvalues,
                [name]: value,
            });
        })
    }
    const [lastdata, setLast] = useState('Save');
    const handleAddLastChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setLast({
                ...lastdata,
                [name]: value,
            });
        })
    }
    const [companydata, setCompany] = useState('');
    const handleAddCompanyChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setCompany({
                ...companydata,
                [name]: value,
            });
        })
    }
    const [streetdata, setStreet] = useState('');
    const handleAddStreetChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setStreet({
                ...streetdata,
                [name]: value,
            });
        })
    }
    const [statedata, setState] = useState('');
    const handleAddStateChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setState({
                ...statedata,
                [name]: value,
            });
        })
    }
    const [apartdata, setApart] = useState('');
    const handleAddapartmentChange = (e) => {
        const { name, value } = e.target;
        setApart({
            ...apartdata,
            [name]: value,
        });
    }
    const [values, setValues] = useState('');
    const handleAddCityChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setValues({
                ...values,
                [name]: value,
            });
        })
    }
    const [postdata, setPost] = useState('');
    const handleAddPostChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setPost({
                ...postdata,
                [name]: value,
            });
        })
    }
    const [phonedata, setPhone] = useState('');
    const handleAddPhoneChange = (e) => {
        const { name, value } = e.target;
        useEffect(() => {
            setPhone({
                ...phonedata,
                [name]: value,
            });
        })
    }
    const [datas, setData] = useState('');
    const handleAddEmailChange = (e) => {
        const { name, value } = e.target
        useEffect(() => {
            setData({
                ...datas,
                [name]: value,
            });
        })
    }

    const addAddress = (e) => {
        const data = {
            userId: 1,
            email: datas.email,
            firstName: addvalues.first,
            lastName: lastdata.last,
            phone: phonedata.phone,
            city: values.city,
            // country: values.country,
            state: statedata.state,
            postcode: postdata.postcode,
            company: companydata.company,
            street: streetdata.street,
            apartment: apartdata.apartment,
            // address: values.addre
        }
        addressTxt("Added..");
        console.log(data);
        toast.success("New Address are added Successful");
        window.location.replace('/account/addresses');
        fetch(config.production + '/addAddress', {
            method: 'POST',
            mode: 'no-cors',
            header: {
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(data)

        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // addressTxt("Added..")
                    // toast.success("New Address are added Successful");
                    console.log('success results', result);
                })
            .catch((err) => {
                //  toast.success(err);
            })
    }
    return (
        <div className="card">
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
            <Helmet>
                <title>{`Edit Address — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>New Address</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-first-name"
                                    placeholder="First Name"
                                    defaultValue={addvalues.first}
                                    name="first"
                                    onChange={handleAddAddressChange}
                                    required
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-last-name"
                                    placeholder="Last Name"
                                    defaultValue={lastdata.last}
                                    name="last"
                                    onChange={handleAddLastChange}
                                    required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="checkout-company-name">
                                Company Name
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-company-name"
                                placeholder="Company Name"
                                defaultValue={companydata.company}
                                name="company"
                                onChange={handleAddCompanyChange}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-country">Country</label>
                            <select id="checkout-country" className="form-control form-control-select2">
                                <option>Select a country...</option>
                                <option>United States</option>
                                <option>Russia</option>
                                <option>Italy</option>
                                <option>France</option>
                                <option>Ukraine</option>
                                <option>Germany</option>
                                <option>Australia</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Street Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Street Address"
                                defaultValue={streetdata.street}
                                name="street"
                                onChange={handleAddStreetChange}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-address">
                                Apartment, suite, unit etc.
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-address"
                                defaultValue={apartdata.apartment}
                                name="apartment"
                                onChange={handleAddapartmentChange}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-city">Town / City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-city"
                                defaultValue={values.city}
                                name="city"
                                onChange={handleAddCityChange}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-state">State / County</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-state"
                                defaultValue={statedata.state}
                                name="state"
                                onChange={handleAddStateChange}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-postcode"
                                defaultValue={postdata.postcode}
                                name="postcode"
                                onChange={handleAddPostChange}
                                required />
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-email">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="checkout-email"
                                    placeholder="Email address"
                                    defaultValue={datas.email}
                                    onChange={handleAddEmailChange}
                                    name="email"
                                    required />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-phone">Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-phone"
                                    placeholder="Phone"
                                    // defaultValue={phone}
                                    onChange={handleAddPhoneChange}
                                    name="phone"
                                    required />
                            </div>
                        </div>

                        <div className="form-group mt-3 mb-0">
                            <button className="btn btn-primary" type="button" onClick={addAddress}>{txt}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
