import { makeIdGenerator } from '../utils';

const getId = makeIdGenerator();

const categoriesDef = [
    {
        name: 'Body Kits',
        slug: 'bodykits',
        image: 'images/categories/parts/2/2-2.jpg',
        items: 25,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Exterior parts',
        slug: 'exterior',
        image: 'images/categories/parts/2/2-2.jpg',
        items: 2,
        children: [
            {
                name: 'Mirrors',
                slug: 'mirrors',
                image: 'images/categories/parts/18/18-18.jpg',
                items: 0,
            },
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Interior Parts',
        slug: 'interior',
        image: 'images/categories/parts/14/14-14.jpg',
        items: 41,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Electrical parts',
        slug: 'electrical',
        image: 'images/categories/parts/6/6-6.jpg',
        items: 6,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Audio',
        slug: 'audio',
        image: 'images/categories/parts/6/6-6.jpg',
        items: 1,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Lights',
        slug: 'lights',
        image: 'images/categories/parts/6/6-6.jpg',
        items: 3,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Wheels',
        slug: 'wheels',
        image: 'images/categories/parts/24/24-24.jpg',
        items: 2,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Brakes',
        slug: 'brakes',
        image: 'images/categories/parts/3/3-3.jpg',
        items: 1,
        children: [
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Engines',
        slug: 'engines',
        image: 'images/categories/parts/7/7-7.jpg',
        items: 1,
        children: [
            {
                name: 'Gearbox',
                slug: 'gearbox',
                image: 'images/categories/parts/12/12-12.jpg',
                items: 0,
            },
            {
                name: 'Pistons',
                slug: 'pistons',
                image: 'images/categories/parts/main/a.jpg',
                items: 0,
            },
            {
                name: 'others',
                slug: 'others',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
        ],
    },
    {
        name: 'Other parts',
        slug: 'others',
        image: 'images/categories/parts/8/8-8.jpg',
        items: 20,
        children: [
            {
                name: 'Modules',
                slug: 'modules',
                image: 'images/categories/parts/main/a.jpg',
                items: 3,
            },
            {
                name: 'Radiator',
                slug: 'radiator',
                image: 'images/categories/parts/main/a.jpg',
                items: 0,
            },

        ],
    },

];

function walkTree(defs, parent = null) {
    let list = [];
    const tree = defs.map((def) => {
        const category = {
            id: getId(),
            name: def.name,
            slug: def.slug,
            image: def.image || null,
            items: def.items || 0,
            customFields: {},
            parent,
            children: [],
        };

        const [childrenTree, childrenList] = walkTree(def.children || [], category);

        category.children = childrenTree;
        list = [...list, category, ...childrenList];

        return category;
    });

    return [tree, list];
}

export function prepareCategory(category, depth) {
    let children;
    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }

    return JSON.parse(JSON.stringify({
        ...category,
        parent: category.parent ? prepareCategory(category.parent) : null,
        children,
    }));
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);
