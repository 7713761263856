// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

// application
import classNames from 'classnames';
import CartIndicator from './IndicatorCart';
// import Departments from './Departments';
import Indicator from './Indicator';
import IndicatorSearch from './IndicatorSearch';
import { Heart20Svg, HomeSvg, ArrowRoundedDown9x6Svg } from '../../svg'; // , LogoSmallSvg, HomeSvg

// third-party

// application
import AppLink from '../shared/AppLink';
// import languages from '../../i18n';
import Megamenu from './Megamenu';
import Menu from './Menu';

// data stubs
import navLinks from '../../data/headerCategories';

function TopMenu(props) {
    const { layout, wishlist } = props;

    const logo = null;
    // let departments = null;
    let searchIndicator;

    if (layout === 'compact') {
        searchIndicator = <IndicatorSearch />;
    }

    if (layout === 'default') {
        // departments = (
        //     <div className="nav-panel__departments">
        //         <Departments />
        //     </div>
        // );

    }

    //---------------------

    // function NavLinks(props) {
    const handleMouseEnter = (event) => {
        // const { locale } = props;
        const { direction } = 'rtl';// languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === 'rtl') {
                const itemPosition = containerWidth - (
                    itemOffsetLeft + item.getBoundingClientRect().width
                );

                const megamenuPosition = Math.round(
                    Math.min(itemPosition, containerWidth - megamenuWidth),
                );

                megamenu.style.left = '';
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(
                    Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
                );

                megamenu.style.right = '';
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };

    const linksList = navLinks.map((item, index) => {
        let arrow;
        let submenu;

        if (item.submenu) {
            arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
        }

        if (item.submenu && item.submenu.type === 'menu') {
            submenu = (
                <div className="nav-links__menu">
                    <Menu items={item.submenu.menu} />
                </div>
            );
        }

        if (item.submenu && item.submenu.type === 'megamenu') {
            submenu = (
                <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
                    <Megamenu menu={item.submenu.menu} />
                </div>
            );
        }

        const classes = classNames('nav-links__item', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (
            <li key={index + 1} className={classes} onMouseEnter={handleMouseEnter}>
                <AppLink to={item.url} {...item.props}>
                    <span>
                        {item.title}
                        {arrow}
                    </span>
                </AppLink>
                {submenu}
            </li>
        );
    });

    // return (
    //     <ul className="nav-links__list">
    //         {linksList}
    //     </ul>
    // );
    // }

    // NavLinks.propTypes = {
    //     /** current locale */
    //     locale: PropTypes.string,
    // };

    // const mapStateToProps = (state) => ({
    //     locale: state.locale,
    // });

    //---------------------

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container mx-auto">
                <div className="nav-panel__row">
                    {logo}
                    {/* {departments} */}
                    <Indicator url="/" icon={<HomeSvg />} />
                    <div className="nav-panel__nav-links nav-links">
                        <ul className="nav-links__list">
                            {linksList}
                        </ul>
                    </div>

                    <div className="nav-panel__indicators">
                        {searchIndicator}

                        <Indicator url="/shop/wishlist" value={wishlist.length} icon={<Heart20Svg />} />

                        <CartIndicator />
                    </div>
                </div>
            </div>
            {/* <TopMenu /> */}
        </div>

    );
}

TopMenu.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

TopMenu.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopMenu);

//---------------------
