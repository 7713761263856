/* eslint-disable */
// react
import React, {useState } from 'react';
import { useForm } from 'react-hook-form';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import queryString from 'query-string';
import PageHeader from '../shared/PageHeader';
import config from '../../data/configurations';

// data stubs
import theme from '../../data/theme';

function ShopPageTrackOrder() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Track Order', url: '' },
    ];
    const queryData = queryString.parse(window.location.search);
   // console.log(queryData);
   // if (queryData.status === 'completed') {
      const canceledData = (
          <div className="container">
              {/* {Object.keys(queryData).forEach((key) => {
                 //   console.log(key, queryData[key]);
                     alert(`${key} : ${queryData[key]}`);
                        <span className="block">
                            Response
                            {key}
                            {' '}
                            :
                            {' '}
                            {queryData[key]}
                        </span>;
                  })} */}
                  
              Your Order # {queryData['reference']} has been {queryData['status']}.
          </div>
        );
//     }else{
//     const canceledData = (
//         <div className="container">
//             Your Order #{queryData['reference']} has been {queryData['status']}. Please verify your payment.
//         </div>
//       );
//    }
   const [trackdata, settrackData] = useState('');
   const handleTrackChange= (e) => {
    const { name, value } = e.target;
    console.log(trackdata);
    settrackData({
            ...trackdata,
            [name]: value,
        });
   }
function trackorderFormValidation(){
    const {handleSubmit, formState: { errors } } = useForm();
   const  onSubmit = (data) => {
        const data1 = {
            userId: 1,
            orderId: trackdata.orderId,
            email: trackdata.email
        };
        window.location.replace('/shop/user-tracking/');
        // fetch(config.production + '/track-order', {
        //     method: 'POST',
        //     mode: 'cors',
        //     body: JSON.stringify(data1)
    
        // })
        //     .then((res) => res.json())
        //     .then(
        //         (result) => {
        //             console.log('success results', result);
        //         })
        //     .catch((err) => {
        //         console.log(err);
        //     })
    };
    return(
        <div className="block">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-6 col-md-8">
                    {canceledData}
                    <div className="card flex-grow-1 mb-0 mt-lg-4 mt-md-3 mt-2">
                        <div className="card-body">
                            <div className="card-title text-center">
                                <h1 className="pt-lg-0 pt-2">
                                    Track Order
                                </h1>

                            </div>
                            <p className="mb-4 pt-2">
                            Enter your order information below to track your order.
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="track-order-id">Order ID</label>
                                    <input id="track-order-id" type="text" value={trackdata.orderId} name="orderId" className="form-control" placeholder="Order ID" onChange={handleTrackChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="track-email">Email address</label>
                                    <input id="track-email" type="email" value={trackdata.email} name="email" className="form-control" placeholder="Email address" onChange={handleTrackChange} required/>
                                </div>
                                <div className="pt-3">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block">Track</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Track Order — ${theme.name}`}</title>
            </Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            {trackorderFormValidation()}
        </React.Fragment>
    );
}

export default ShopPageTrackOrder;
