/* eslint-disable */
// react
import React, { Component } from 'react'
import qs from 'query-string';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
import Pagination from '../shared/Pagination';

// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';

export default class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: dataOrders,
            page: 1,
            orderHistoryData: []
        };
    }

    componentDidMount = () => {
        this.getOrderHistoryData();
    };

    getOrderHistoryData() {
        const data = {
            userId: 1
        }
        fetch(`http://localhost:5000/orderHistory.json?${qs.stringify(data)}`, {
            method: "GET",
            // mode: 'no-cors',
            headers: { "Content-type": "application/json" },
        }).then((response) => response.json())
            .then((data) => {
                // orderHistoryData.push(data);
                this.setState({orderHistoryData: data})
            });
    }

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
    };
    render() {
        const { page, orders, orderHistoryData } = this.state;
        console.log(orderHistoryData);
        return (
            <div>
                 {orderHistoryData.map((item) => (
            <div className="card">
                <Helmet>
                    <title>{`Order History — ${theme.name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Order History</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            {(item.orderDetails).map((order) => (
                            <tbody>                               
                                    <td><Link to="/shop/user-tracking">{`#${order.id}`}</Link></td>
                                    <td>{order.date}</td>
                                    <td>{order.status}</td>
                                    <td>{order.total}</td>
                            </tbody>
                              ))}
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    <Pagination current={page} total={3} onPageChange={this.handlePageChange} />
                </div>
            </div>
                 ))}
            </div>
        );
    }
}
