/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// data stubs
// import specification from '../../data/shopProductSpec';

function ProductTabSpecification(props) {
    const { product } = props;
    const productFeatures = product.attributes.map((feature, index) => (
        <div key={index} className="spec__row">
            <div className="spec__name">{feature.name}</div>
            <div className="spec__value">{feature.value}</div>
        </div>
    ));
    const fetchSplFeatures = () => {
        const list = product.customfields;
        if (!list) {
            return null;
        }
        if (!Object.keys(list).length) {
            return '';
        }
        return (
            Object.values(list).map((key, index) => (
                <React.Fragment>
                    <div key={index} className="spec__row">
                        <div className="spec__name">{key.name}</div>
                        <div className="spec__value"><b>{key.text}</b></div>
                    </div>
                </React.Fragment>
            ))
        );
    };

    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
            {productFeatures}
            {fetchSplFeatures()}
            <div className="spec__disclaimer">
                {/* Nunc sollicitudin, nunc id accumsan semper, libero nunc aliquet nulla, nec pretium
               ipsum risus ac neque. Morbi eu facilisis purus. Quisque mi tortor, cursus in nulla
                ut, laoreet commodo quam. Pellentesque et ornare sapien. In ac est tempus urna
                tincidunt finibus. Integer erat */}
            </div>
        </div>
    );
}

export default ProductTabSpecification;
