/* eslint-disable no-useless-constructor */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
    Card, CardDeck, CardGroup, CardColumns,
} from 'react-bootstrap';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import config from '../../data/configurations';
import brandList from '../../fake-server/database/brandList';

export default class ShopCategoryBlock extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {
            brand,
            model,
            categories,
            categories1,
        } = this.props;

        // console.log('brandName', this.props.match.params.brandName);
        // const desc = brands.find((x) => x.Description.toLowerCase() === this.props.match.params.brandName);
        // console.log('value', this.props.match.params.brandName.toUpperCase().split('-').join(' '));
        // const { brandName } = this.props.match.params;
        // const defaultIndex = brands.findIndex((x) => x.Description.toLowerCase().split(' ').join('-') === this.props.match.params.brandName);
        // console.log('defaultIndex', defaultIndex);
        // console.log('brandList', brandList);
        //  console.log('categories1', categories1);
        //  console.log('categories', categories);
        const isbrand = brandList.find((x) => x.slug === brand);
        // console.log('isbrand', isbrand);
        const ismodel = isbrand.models.find((x) => x.slug === model);
        // console.log('ismodel', isbrand.models.filter());
        return (
            <div className="category-card">
                <CardColumns>
                    {categories1.map((c, i) => (
                        <Card border="dark" key={c.id ? c.id.toString() : i.toString()} className="col-md-5 col-sm-4 col-lg-2 p-0">
                            <Card.Header>
                                <Card.Title>
                                    <Link
                                        className=""
                                        to={`${model}/${c.alias}?filter_brand=${isbrand.id}&filter_model=${model}&filter_category=${c.id}`}
                                    >
                                        {c.descriptions[0].title.toUpperCase()}
                                    </Link>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Img variant="top" className="col-xs-4" src={config.production.mediaUrl + c.image} />
                                <Card.Text className="col-xs-8">
                                    <ul className="nostyle">
                                        {c.childs.slice(0, 5).map((child, index) => (

                                            <Link
                                                className=""
                                                to={`/brands/${brand}/${model}/${child.alias}?filter_brand=${isbrand.id}&filter_model=${model}&filter_category=${child.id}`}
                                            >
                                                <li key={child.id.toString()}>
                                                    <i className="fa fa-cog">&nbsp;</i>{child.alias.toUpperCase().replace(/-/g, ' ')}
                                                </li>
                                            </Link>

                                        ))}
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                <Link
                                    className=""
                                    to={`/brands/${brand}/${model}/${c.alias}?filter_brand=${isbrand.id}&filter_model=${model}`}
                                >
                                    <i className="fa fa-search-plus" />
                                    <small className="text-muted"> Find more</small>
                                </Link>
                            </Card.Footer>
                        </Card>
                    ))}
                </CardColumns>
                {/* {categories.map((c, i) => (
                    <Card border="dark" key={c.id.toString()}>
                        <Card.Header>
                            <Card.Title>
                                <Link
                                    className=""
                                    to={`/parts/${c.slug}?filter_brand=${brand}&filter_model=${model}`}
                                >
                                    {c.name.toUpperCase()}
                                </Link>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Img variant="top" className="col-xs-4" src={c.image} />
                            <Card.Text className="col-xs-8">
                                <ul className="nostyle">
                                    {c.children.map((child, index) => (

                                        <Link
                                            className=""
                                            to={`/parts/${child.slug}?filter_brand=${brand}&filter_model=${model}`}
                                        >
                                            <li key={child.name.toString()}>
                                                <i className="fa fa-cog">&nbsp;</i>{child.name.toUpperCase()}
                                            </li>
                                        </Link>

                                    ))}
                                </ul>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-center">
                            <Link
                                className=""
                                to={`/parts/${c.slug}?filter_brand=${brand}&filter_model=${model}`}
                            >
                                <i className="fa fa-search-plus" />
                                <small className="text-muted"> Show more</small>
                            </Link>
                        </Card.Footer>
                    </Card>
                ))} */}

            </div>

        );
    }
}

ShopCategoryBlock.propTypes = {
    brand: PropTypes.object.isRequired,
    model: PropTypes.object,
};

ShopCategoryBlock.defaultProps = {

};
