/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// react
import React from 'react';
// import React, { Component } from 'react';

// application
// import departmentsAria from '../../services/departmentsArea';
// import languages from '../../i18n';
// import StroykaSlick from '../shared/StroykaSlick';

// third-party
// import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import Slider, { Settings } from 'react-slick';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// application
import {
    WheelSvg,
    BreakSvg,
    ACSvg,
    EngineSvg,
    GearshiftSvg,
    LightsSvg,
    DashboardSvg,
    RadiatorSvg,
    Break1Svg,
    // GarageSvg,
    DamperSvg,
    TireSvg,
} from '../../svg';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <i className="fas fa-arrow-right d-lg-block d-none" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <i className="fas fa-arrow-left d-lg-block d-none" />
        </div>
    );
}

export default function BlockFeatures(props) {
    const { layout } = props;
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        autoplay: true,
        // speed: 1000,
        rows: 1,
        autoplaySpeed: 6000,
        cssEase: 'linear',
        slidesToShow: 9,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 4,
                    infinite: true,
                    // dots: true
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                // dots: true
                },
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
        ],

    };

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container m-auto px-0">
                <div>
                    <Slider {...settings}>
                        <Link to="/parts/engines">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <EngineSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Engines</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/gearbox">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <GearshiftSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Gearbox</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/breaks">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <BreakSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Brakes</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/wheels">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <WheelSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Wheels</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/light">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <LightsSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Lights</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/module">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <ACSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Modules</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/interior">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <DashboardSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Dashboard</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/radiator">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <RadiatorSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Radiator</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/wheels">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <TireSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Tire</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/mirrors">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <WheelSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Rims</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/brakes">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <Break1Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Brakes</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/parts/others">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <DamperSvg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Others</div>
                                </div>
                            </div>
                        </Link>
                    </Slider>
                </div>

            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
