/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
// import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Quickview16Svg, Wishlist16Svg } from '../../svg'; // Compare16Svg
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';
import config from '../../data/configurations';

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        // compareAddItem,
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;
    if (product.badges) {
        if (product.badges.includes('sale')) {
            badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
        }
        if (product.badges.includes('hot')) {
            badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
        }
        if (product.badges.includes('new')) {
            badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
        }

        badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;
    }

    if ((product.images && product.images.length > 0) || product.image) {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src={config.production.mediaUrl + product.image} alt="" />
                </Link>
            </div>
        );
    }

    if (product.promotion_price) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={product.promotion_price} /></span>
                {' '}
                <span className="product-card__old-price"><Currency value={product.price} /></span>
            </div>
        );
    } else if (product.price) {
        price = (
            <div className="product-card__prices">
                <Currency value={product.price} />
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <i className="fa fa-mobile" aria-hidden="true" />
                &nbsp; Ask Price
            </div>
        );
    }

    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {/* {product.attributes.map((feature, index) => (
                    <li key={index}>{`${feature.name}: ${feature.value}`}</li>
                ))} */}
                {product.attributes.filter((x) => x.featured).map((attribute, index) => (
                    <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
                ))}
            </ul>
        );
    }
const productID = product.id ? product.id : product.alias;
    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(productID)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        {/* <Quickview16Svg /> */}
                        <i className="fa fa-eye" aria-hidden="true" />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link to={url.product(product)}>{`${(product.descriptions[0] && product.descriptions[0].name.length > 45) ? `${product.descriptions[0].name.slice(0, 42)}...` : ''}`}</Link>
                </div>
                {/* <div className="product-card__rating">
                    <Rating value={product.rating} />
                    <div className=" product-card__rating-legend">{`${product.reviews} Reviews`}</div>
                </div> */}
                {features}
            </div>
            <div className="product-card__actions">
                {/* <div className="product-card__availability">
                    Availability:
                    <span className="text-success">In Stock</span>
                </div> */}
                {price}
                <div className={product.stock <= 0 || product.weight === 0 || product.weight === '0' ? 'd-none ' : 'product-card__buttons'}>
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            run();
                                            document.getElementById('cartIndicator').click();
                                        }}
                                        className={classNames('btn btn-primary product-card__addtocart', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <i className="fa fa-shopping-cart" aria-hidden="true" />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={classNames('btn btn-secondary', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        BUY
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        run();
                                        document.getElementById('cartIndicator').click();
                                    }}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                                    &nbsp;BUY
                                </button>
                            </React.Fragment>
                        )}
                    />
                    <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    {/* <AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    /> */}
                </div>
                <div className={product.stock <= 0 || product.weight === 0 || product.weight === '0' ? 'product-card__buttons ' : 'd-none'}>
                    <button type="button" className="btn btn-sm btn-danger">
                        <a className="text-white" external="true" href={`https://wa.me/6585198833/?text=SKU: ${product.sku} | I'm Interested in ${window.location.href}`}>
                            CONTACT US
                        </a>
                    </button>
                    <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />

                </div>
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
