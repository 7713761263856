/* eslint-disable */
// react
import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { Box, xcss } from '@atlaskit/primitives';

import  { ProgressTracker, Stages } from '@atlaskit/progress-tracker';

// third-party
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";

// application
import { Link } from 'react-router-dom';
import config from '../../data/configurations';

export default function shopPageTracking() {
  const [TrackDetails, setTrackDetails] = useState([]);
  const data = {
    userId: 1,
    orderId: 2021
  }
  function getTrackDetails() {
    //const url = `${config.production.apiUrl}getPayment?${qs.stringify(params)}`;
    const url = `http://localhost:5000/orderTrack.json?${qs.stringify(data)}`;
    fetch(url, {
      method: "GET",
      // mode: 'no-cors',
      headers: { "Content-type": "application/json" },
    }).then((response) => response.json())
      .then((data) => {
        setTrackDetails(data)
      });
  }

  useEffect(() => {
    getTrackDetails();
  }, []);
  const [trackId, settrackId] = useState('');
  const trackIdChange = (e) => {
    // const { name, value } = e.target;
    // settrackId({
    //   ...trackId,
    //   [name]: value,
    // });
  }
  const [passId, settable] = useState('');
  function trackFilter(id) {
    // alert(id);
    settable(id);
  }
  const containerStyles = xcss({
    maxWidth: '80%',
    margin: 'auto',
  });
  const items = [
    {
      id: '1',
      label: 'Your Order Placed',
      percentageComplete: 100,
      status: 'disabled',
      href: '#',
    },
    {
      id: '2',
      label: 'Order Dispatched',
      percentageComplete: 100,
      status: 'Current',
      href: '#',
    },
    {
      id: '3',
      label: 'Out For Delivery',
      percentageComplete: 0,
      status: 'current',
      href: '#',
    },
    {
      id: '4',
      label: 'Delivered',
      percentageComplete: 0,
      status: 'unvisited',
      href: '#',
    }
  ];
  return (
    <div className='container'>
      {TrackDetails.map((track) => (
      <div>
        <style>
      {"\
      .css-mq9gqg-ProgressBar{\
        background:#b40101;top:5px;\
      }\
      "}
      {"\
      .css-qx19ch-ProgressMarker{\
        background:#b40101;height:20px;width:20px;border-radius:50%\
      }\
      "}
      {"\
      .css-xrd6pn-Text,.css-u5qgk4-Text,.css-zzdw7z-Text,.css-3xo77k-Text {\
        color: green;\
    }\
      "}
      </style>
      <div style={{ height: '50px', background: 'local', display: 'flex' }}>
        <div style={{ width: '10%', margin: '0 0 0 15px' }}><p style={{ color: 'green', padding: '10px 0 0px 10px', fontSize: '14px', fontWeight: '600', marginBottom: '0' }}>Order No</p>
          <p style={{ fontSize: '12px', fontWeight: '600', marginLeft: '10px' }}>47859457</p>
        </div>
        <div style={{ width: '5%', fontSize: '30px' }}>|</div>
        <div >
          <p style={{ color: 'green', padding: '10px 0 0px 10px', fontSize: '14px', fontWeight: '600', marginBottom: '0' }}  >Status</p>
          <p style={{ fontSize: '12px', fontWeight: '600', marginLeft: '10px' }}>Delivered</p>
        </div>
      </div>
      <div style={{ height: '40px', background: 'lightgray', margin: '0 0 0 15px' }}>
        <p style={{ color: 'green', padding: '10px 0 0px 10px', fontSize: '14px', fontWeight: '600' }}>Date of Delivery 10 Aug 2023 
        {/* <Link style={{ float: 'right', padding: '0 10px 0px 0', color: 'blue', textDecoration: 'underline' }}>More Details</Link> */}
        </p>
      </div>
      <Box xcss={containerStyles}>
    <ProgressTracker items={items} spacing="compact" />
  </Box><br></br>
      {/* <StepProgressBar
        startingStep={0}
        // onSubmit={onFormSubmit}
        steps={[
          {
            label: "Shipped",
            name: "step 1",
            //   content: step1Content
          },
          {
            label: "In Transit",
            name: "step 2",
            //   content: step2Content,
            //   validator: step2Validator
          },
          {
            label: "Delivered",
            name: "step 3",
            //   content: step3Content,
            // validator: step3Validator
          }
        ]}
      /> */}
      <div className="row justify-content-center">
        <div className="col-xl-5 col-lg-6 col-md-8">
          <form>
            <div className="form-group">
              <label htmlFor="track-id" style={{fontWeight:'600'}}>Track ID</label>
              <input id="track-id" type="text" value={passId} name="trackId" className="form-control" placeholder="Track ID"  onChange={trackIdChange} required />
            </div>
          </form>
        </div>
      </div>
      <div>
        <div style={{ height: '35px', background: '#b40101' }}>
          <p style={{ textAlign: 'center', fontWeight: '600', paddingTop: '5px',color:'#FFCC00' }}>Shipment Details</p>
        </div>
        <div>
            <div className="card-table">
              <div className="table-responsive-sm table-bordered table-hover">
                <table>
                  <thead style={{ background: 'lightgray' }}>
                    <tr>
                      <th style={{ color: 'black', fontWeight: '600' }}>TrackId</th>
                      <th style={{ color: 'black', fontWeight: '600' }}>Location</th>
                      <th style={{ color: 'black', fontWeight: '600' }}>Details</th>
                      <th style={{ color: 'black', fontWeight: '600' }}>Date</th>
                      <th style={{ color: 'black', fontWeight: '600' }}>Time</th>
                    </tr>
                  </thead>

                  {(track.trackDetails).map((order) => (
                    <tbody>
                      <td onClick={() => trackFilter(order.trackId)}><Link style={{color:'blueviolet'}}>{order.trackId}</Link></td>
                      <td>{order.location}</td>
                      <td>{order.status}</td>
                      <td>{order.date}</td>
                      <td>{order.time}</td>
                    </tbody>
                  ))}

                </table>
              </div>
            </div>
        </div>
      </div>
</div>
      ))}
    </div>
  );

}
