export default {
    name: 'Propel Autoparts',
    fullName: 'Propel Auto Parts - Reuse with Quality',
    url: 'https://propel-auto.com/',
    author: {
        name: 'Propel IT',
        profile_url: 'https://propel-auto.com/',
    },
    contacts: {
        address: '5 Woodlands Walk, Singapore 738364',
        email: 'sales@propel-auto.com',
        phone: '+(65) 6368 1686',
        malaysia: {
            address: 'PTD 103234, (HSD 58397), Jalan Lapangan Terbang, Mukim Senai,Daerah Kulaijaya, Johor Darul Tazim,West Malaysia',
            email: 'ng@propel-auto.com',
            phone: '+(6016) 7717444, +(607) 5581886',
        },
    },
    social: {
        facebook: 'https://www.facebook.com/Propelauto/',
        twitter: 'https://twitter.com/propelauto',
        youtube: 'https://www.youtube.com/channel/UClyNKQJ07QCF1OJrn67pAiA',
        instagram: 'https://www.instagram.com/propelauto/',
    },
};
