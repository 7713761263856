/* eslint-disable */
// react
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import config from '../../data/configurations';

export default function AccountPageEditAddress() {
    const { emailAddr, firstAddr, lastAddr, phoneAddr, cityAddr, countryAddr,stateAddr, postcodeAddr, companyaddr,streetaddr,apartmentaddr,address } = useLocation();
    const searchParams = new URLSearchParams(emailAddr);
    const searchParams1 = new URLSearchParams(firstAddr);
    const searchParams2 = new URLSearchParams(lastAddr);
    const searchParams3 = new URLSearchParams(phoneAddr);
    const searchParams4 = new URLSearchParams(cityAddr);
    const searchParams5 = new URLSearchParams(stateAddr);
    const searchParams6 = new URLSearchParams(countryAddr);
    const searchParams7 = new URLSearchParams(postcodeAddr);
    const searchParams8 = new URLSearchParams(companyaddr);
    const searchParams9 = new URLSearchParams(streetaddr);
    const searchParams10 = new URLSearchParams(apartmentaddr);
    const searchParams11 = new URLSearchParams(address);


    const email = searchParams.get("email");
    const first = searchParams1.get("first");
    const last = searchParams2.get("last");
    const phone = searchParams3.get("phone");
    const city = searchParams4.get("city");
    const state = searchParams5.get("state");
    const country = searchParams6.get("country");
    const postcode = searchParams7.get("postcode");
    const company = searchParams8.get("company");
    const street = searchParams9.get("street");
    const apartment = searchParams10.get("apartment");
    const addre = searchParams11.get("address");
    
    const [isdisabled, setIsDisabled] = useState(true)
    const [addvalues, setAddress] = useState('');
    const [txt, addressTxt] = useState('Save');
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        console.log(value)
        setIsDisabled(false)
        console.log(isdisabled);
        useEffect(() => {
            setAddress({
            ...addvalues,
            [name]: value,
        });
    })
    }
    const [lastdata, setLast] = useState('Save');
    const handleLastChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setLast({
            ...lastdata,
            [name]: value,
        });
    })
    }
    const [companydata, setCompany] = useState('');
    const handleCompanyChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setCompany({
            ...companydata,
            [name]: value,
        });
    })
    }
    const [streetdata, setStreet] = useState('');
    const handleStreetChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setStreet({
            ...streetdata,
            [name]: value,
        });
    })
    }
    const [statedata, setState] = useState('');
    const handleStateChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setState({
            ...statedata,
            [name]: value,
        });
    })
    }
    const [apartdata, setApart] = useState('');
    const handleapartmentChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        setApart({
            ...apartdata,
            [name]: value,
        });
    }
    const [values, setValues] = useState('');
    const handleCityChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setValues({
            ...values,
            [name]: value,
        });
    })
    }
    const [postdata, setPost] = useState('');
    const handlePostChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setPost({
            ...postdata,
            [name]: value,
        });
    })
    }
    const [phonedata, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        setIsDisabled(false)
        useEffect(() => {
            setPhone({
            ...phonedata,
            [name]: value,
        });
    })
    }
    const [datas, setData] = useState('');
    const handleEmailChange = (e) => {
        const { name, value } = e.target
        setIsDisabled(false)
        useEffect(() => {
        setData({
            ...datas,
            [name]: value,
        });
    })
    }
    const editAddress=(e)=>{
        const data = {
            userId: 1,
            email: datas.email,
            firstName: addvalues.first,
            lastName: lastdata.last,
            phone: phonedata.phone,
            city: values.city,
            // country: values.country,
            state: statedata.state,
            postcode: postdata.postcode,
            company: companydata.company,
            street: streetdata.street,
            apartment: apartdata.apartment,
            // address: values.addre
        }
        addressTxt("Updated..")
        console.log(data);
                 toast.success("Address data are changed Successful");
        window.location.replace('/account/dashboard');
        fetch(config.production + '/editAddress', {
            method: 'POST',
            mode:  'no-cors',
            header: {
                'Access-Control-Allow-Origin':'*',
              },
            body: JSON.stringify(data)

        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // addressTxt("Updated..")
                 // toast.success("Profile data are changed Successful");
                    console.log('success results', result);
                })
            .catch((err) => {
                //  toast.success(err);
            })
    }
    return (
        <div className="card">
            <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
            <Helmet>
                <title>{`Edit Address — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Address</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-first-name"
                                    placeholder="First Name"
                                    defaultValue={first}
                                    name="first"
                                    onChange={handleAddressChange}
                                    required
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-last-name"
                                    placeholder="Last Name"
                                    defaultValue={last}
                                    name="last"
                                    onChange={handleLastChange}
                                    required  />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="checkout-company-name">
                                Company Name
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-company-name"
                                placeholder="Company Name"
                                defaultValue={company}
                                name="company"
                                onChange={handleCompanyChange}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-country">Country</label>
                            <select id="checkout-country" className="form-control form-control-select2">
                                <option>Select a country...</option>
                                <option>United States</option>
                                <option>Russia</option>
                                <option>Italy</option>
                                <option>France</option>
                                <option>Ukraine</option>
                                <option>Germany</option>
                                <option>Australia</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Street Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Street Address"
                                defaultValue={street}
                                name="street"
                                onChange={handleStreetChange}
                                required  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-address">
                                Apartment, suite, unit etc.
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-address"
                                defaultValue={apartment}
                                name="apartment"
                                onChange={handleapartmentChange} 
                                required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-city">Town / City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-city"
                                defaultValue={city}
                                name="city"
                                onChange={handleCityChange} 
                                required  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-state">State / County</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-state"
                                defaultValue={state}
                                name="state"
                                onChange={handleStateChange} 
                                required  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-postcode"
                                defaultValue={postcode}
                                name="postcode"
                                onChange={handlePostChange} 
                                required />
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-email">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="checkout-email"
                                    placeholder="Email address"
                                    defaultValue={email}
                                    onChange={handleEmailChange}   
                                    name="email"
                                    required />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-phone">Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-phone"
                                    placeholder="Phone"
                                    defaultValue={phone}
                                    onChange={handlePhoneChange}   
                                    name="phone"
                                    required />
                            </div>
                        </div>

                        <div className="form-group mt-3 mb-0">
                            <button className="btn btn-primary" type="button" disabled={isdisabled} onClick={editAddress}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
